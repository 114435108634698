/* eslint-disable react/jsx-no-useless-fragment */
import { MdUserRole } from '~anyx/common/graphql';

import { Permission, PermissionType, Restriction } from '../../models';

import { usePermissionCtx } from './PermissionContext';

type RestrictedProps = {
  // Only show if user as this permissions
  permissions?: Permission[][];
  // only show if user do not have this restrictions
  restrictions?: Restriction[][];
  // only show if user is of this role
  roles?: MdUserRole[];
  fallback?: React.ReactNode;
  loadingComponent?: React.ReactNode;
  mixConditions?: PermissionType[];
};

// https://adasiaholdings.atlassian.net/wiki/spaces/ANYX/pages/2963767323/Permission+Handler
export const Restricted = ({
  permissions,
  restrictions,
  roles,
  fallback,
  children,
  mixConditions,
}: React.PropsWithChildren<RestrictedProps>) => {
  const { allowed } = usePermissionCtx({
    permissions,
    restrictions,
    roles,
    mixConditions,
  });

  if (allowed) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};
