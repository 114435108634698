import { useEffect, useState } from 'react';

import { DatesProvider as AnyUIDatesProvider, DayOfWeek } from '@any-ui-react/dates';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { useEnvironmentStore } from '../../states';

import('dayjs/locale/ja');
import('dayjs/locale/ko');
import('dayjs/locale/zh-cn');

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

export const DatesProvider = ({ children }: { children: React.ReactNode }) => {
  const locale = useEnvironmentStore((state) => state.currentLocale);
  const timezone = useEnvironmentStore((state) => state.currentTimezone);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState<DayOfWeek>();

  useEffect(() => {
    dayjs.locale(locale);
    const localeData = dayjs().localeData();
    setFirstDayOfWeek(localeData.firstDayOfWeek() as DayOfWeek);
  }, [locale]);

  return (
    <AnyUIDatesProvider
      settings={{
        locale,
        timezone,
        firstDayOfWeek,
      }}
    >
      {children}
    </AnyUIDatesProvider>
  );
};
