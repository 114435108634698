import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type YahooSalesReportSummaryQueryVariables = Types.Exact<{
  input: Types.YahooSalesReportSummaryInput;
}>;


export type YahooSalesReportSummaryQuery = { readonly yahooSalesReportSummary: { readonly __typename?: 'YahooSalesReportSummaryPayload', readonly currency: Types.ReportSupportedCurrency, readonly avgSpendPerCustomer: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly orderCount: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly totalSales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly unitSoldCount: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string } } };


export const YahooSalesReportSummaryDocument = gql`
    query YahooSalesReportSummary($input: YahooSalesReportSummaryInput!) {
  yahooSalesReportSummary(input: $input) {
    avgSpendPerCustomer {
      diff
      diffPercentage
      value
    }
    currency
    orderCount {
      diff
      diffPercentage
      value
    }
    totalSales {
      diff
      diffPercentage
      value
    }
    unitSoldCount {
      diff
      diffPercentage
      value
    }
  }
}
    `;

/**
 * __useYahooSalesReportSummaryQuery__
 *
 * To run a query within a React component, call `useYahooSalesReportSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useYahooSalesReportSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYahooSalesReportSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useYahooSalesReportSummaryQuery(baseOptions: Apollo.QueryHookOptions<YahooSalesReportSummaryQuery, YahooSalesReportSummaryQueryVariables> & ({ variables: YahooSalesReportSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YahooSalesReportSummaryQuery, YahooSalesReportSummaryQueryVariables>(YahooSalesReportSummaryDocument, options);
      }
export function useYahooSalesReportSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YahooSalesReportSummaryQuery, YahooSalesReportSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YahooSalesReportSummaryQuery, YahooSalesReportSummaryQueryVariables>(YahooSalesReportSummaryDocument, options);
        }
export function useYahooSalesReportSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<YahooSalesReportSummaryQuery, YahooSalesReportSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<YahooSalesReportSummaryQuery, YahooSalesReportSummaryQueryVariables>(YahooSalesReportSummaryDocument, options);
        }
export type YahooSalesReportSummaryQueryHookResult = ReturnType<typeof useYahooSalesReportSummaryQuery>;
export type YahooSalesReportSummaryLazyQueryHookResult = ReturnType<typeof useYahooSalesReportSummaryLazyQuery>;
export type YahooSalesReportSummarySuspenseQueryHookResult = ReturnType<typeof useYahooSalesReportSummarySuspenseQuery>;
export type YahooSalesReportSummaryQueryResult = Apollo.QueryResult<YahooSalesReportSummaryQuery, YahooSalesReportSummaryQueryVariables>;
export function refetchYahooSalesReportSummaryQuery(variables: YahooSalesReportSummaryQueryVariables) {
      return { query: YahooSalesReportSummaryDocument, variables: variables }
    }