import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MdStoresChannelsQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.MdStoreFilterInput>;
  sortBy?: Types.InputMaybe<Types.MdStoreSortByInput>;
}>;


export type MdStoresChannelsQuery = { MdStores: { __typename?: 'MdStores', items: Array<{ __typename?: 'MdStore', id: string, channels: Array<{ __typename?: 'SaleChannel', id: string, type: Types.MarketplaceSaleChannelType, isManualChannel: boolean, data: { __typename?: 'AmazonSaleChannelData', amazonMarketplace: Types.AmazonSellerCentralMarketplace } | { __typename?: 'EmptySaleChannelData' } | { __typename?: 'LazadaSaleChannelData', lazadaMarketplace: Types.LazadaMarketplace } | { __typename?: 'Qoo10SaleChannelData', qoo10Marketplace: Types.Qoo10Marketplace } | { __typename?: 'RakutenSaleChannelData' } | { __typename?: 'ShopeeSaleChannelData', showReconnectButton: boolean, expiresAt: string, shopeeMarketplace: Types.ShopeeMarketplace } | { __typename?: 'ShopifySaleChannelData' } | { __typename?: 'TikTokSaleChannelData', tiktokMarketplace: Types.TikTokRegion } | { __typename?: 'TokopediaSaleChannelData' } | { __typename?: 'YahooSaleChannelData' } }> }> } };


export const MdStoresChannelsDocument = gql`
    query MdStoresChannels($pageNumber: Int!, $pageSize: Int!, $filter: MdStoreFilterInput, $sortBy: MdStoreSortByInput) {
  MdStores(
    pageNumber: $pageNumber
    pageSize: $pageSize
    filter: $filter
    sortBy: $sortBy
  ) {
    items {
      id
      channels {
        id
        type
        isManualChannel
        data {
          ... on AmazonSaleChannelData {
            amazonMarketplace: marketplace
          }
          ... on LazadaSaleChannelData {
            lazadaMarketplace: marketplace
          }
          ... on Qoo10SaleChannelData {
            qoo10Marketplace: marketplace
          }
          ... on ShopeeSaleChannelData {
            showReconnectButton
            expiresAt
            shopeeMarketplace: marketplace
          }
          ... on TikTokSaleChannelData {
            tiktokMarketplace: marketplace
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMdStoresChannelsQuery__
 *
 * To run a query within a React component, call `useMdStoresChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMdStoresChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMdStoresChannelsQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMdStoresChannelsQuery(baseOptions: Apollo.QueryHookOptions<MdStoresChannelsQuery, MdStoresChannelsQueryVariables> & ({ variables: MdStoresChannelsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>(MdStoresChannelsDocument, options);
      }
export function useMdStoresChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>(MdStoresChannelsDocument, options);
        }
export function useMdStoresChannelsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>(MdStoresChannelsDocument, options);
        }
export type MdStoresChannelsQueryHookResult = ReturnType<typeof useMdStoresChannelsQuery>;
export type MdStoresChannelsLazyQueryHookResult = ReturnType<typeof useMdStoresChannelsLazyQuery>;
export type MdStoresChannelsSuspenseQueryHookResult = ReturnType<typeof useMdStoresChannelsSuspenseQuery>;
export type MdStoresChannelsQueryResult = Apollo.QueryResult<MdStoresChannelsQuery, MdStoresChannelsQueryVariables>;
export function refetchMdStoresChannelsQuery(variables: MdStoresChannelsQueryVariables) {
      return { query: MdStoresChannelsDocument, variables: variables }
    }