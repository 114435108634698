import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import { routes as AuditLogRoutes } from './modules/audit-log/routing';
import { routes as WorkflowRoutes } from './modules/core/routing';

export const WorkflowAppRoot = React.lazy(() =>
  import('./WorkflowAppRoot').then((module) => ({
    default: module.WorkflowAppRoot,
  }))
);

export const routes: RouteObject[] = [
  {
    path: 'workflow',
    element: <WorkflowAppRoot />,
    children: [
      {
        index: true,
        element: <Navigate to="list" relative="path" />,
      },
      ...WorkflowRoutes,
      ...AuditLogRoutes,
    ],
  },
];
