import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RangeDatePicker as AnyUIRangeDatePicker,
  DatesRangeUtils,
  RangeDatePickerProps as AnyUIRangeDatePickerProps,
  DatesRangeKey,
} from '@any-ui-react/dates';

import { TimezoneUtils, useScreenBreakpoint } from '~anyx/shared/utils';

export type RangeDatePickerProps = AnyUIRangeDatePickerProps & {
  withDatesRangeSelect?: boolean;
  onlyDatesRange?: DatesRangeKey[];
};

const useDatesRangeOptions = ({ onlyDatesRange }: { onlyDatesRange: DatesRangeKey[] }) => {
  const { t } = useTranslation('shared');

  const options = useMemo(() => {
    const datesRange = DatesRangeUtils(TimezoneUtils.getCurrentTimezone());
    const generateOption = (key: DatesRangeKey) => {
      return {
        key,
        label: t('shared.date.range', { range: key }),
        ...datesRange[key],
      };
    };

    return [
      DatesRangeKey.TODAY,
      DatesRangeKey.YESTERDAY,
      DatesRangeKey.LAST_7_DAYS,
      DatesRangeKey.LAST_30_DAYS,
      DatesRangeKey.LAST_90_DAYS,
      DatesRangeKey.LAST_WEEK,
      DatesRangeKey.LAST_MONTH,
      DatesRangeKey.LAST_YEAR,
      DatesRangeKey.WEEK_TO_DATE,
      DatesRangeKey.MONTH_TO_DATE,
      DatesRangeKey.QUARTER_TO_DATE,
      DatesRangeKey.YEAR_TO_DATE,
      DatesRangeKey.QUARTER_1,
      DatesRangeKey.QUARTER_2,
      DatesRangeKey.QUARTER_3,
      DatesRangeKey.QUARTER_4,
    ]
      .map(generateOption)
      .filter((option) => (onlyDatesRange.length > 0 ? onlyDatesRange.includes(option.key) : true))
      .map(({ key: _key, ...option }) => ({ ...option }));
  }, [onlyDatesRange, t]);

  return options;
};

export const RangeDatePicker = forwardRef<HTMLButtonElement, RangeDatePickerProps>(
  ({ withDatesRangeSelect = true, onlyDatesRange = [], ...props }, ref) => {
    const { t } = useTranslation('shared');
    const isLargeScreen = useScreenBreakpoint('lg');
    const dateRangeOptions = useDatesRangeOptions({ onlyDatesRange });

    return (
      <AnyUIRangeDatePicker
        ref={ref}
        mode={isLargeScreen ? 'default' : 'compact'}
        placeholder={t('shared.field.selectDate.placeholder')}
        datesRangeSelectProps={
          withDatesRangeSelect
            ? {
                label: t('shared.date.title'),
                placeholder: t('shared.date.rangePlaceholder'),
                ranges: dateRangeOptions,
              }
            : undefined
        }
        {...props}
      />
    );
  }
);
