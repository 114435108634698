import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/common/graphql';

const ShopeeAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.ShopeeAddPage }))
);

const ShopeeEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.ShopeeEditPage }))
);

const ShopeeTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ShopeeTemplate }))
);

export const shopeeRouting = [
  {
    path: MarketplaceSaleChannelType.Shopee,
    element: <ShopeeTemplate />,
    children: [
      {
        index: true,
        element: <ShopeeAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            index: true,
            element: <ShopeeEditPage />,
          },
        ],
      },
    ],
  },
];
