import { RouteObject } from 'react-router';

import { CrmRoutePathKey } from '~anyx/common/routing';

import { routes as ChatRoutes } from '../chat/routing';

import { routes as BroadcastRoutes } from './broadcast/routing';
import { routes as OptInRoutes } from './opt-in/routing';

const PATH_KEY = CrmRoutePathKey.enum.messenger;

export const routes: RouteObject[] = [
  {
    path: 'messenger',
    children: [...ChatRoutes(PATH_KEY), ...OptInRoutes, ...BroadcastRoutes],
  },
];
