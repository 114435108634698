import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TikTokSalesReportChartQueryVariables = Types.Exact<{
  input: Types.TikTokSalesReportChartInput;
}>;


export type TikTokSalesReportChartQuery = { readonly tikTokSalesReportChart: { readonly __typename?: 'TikTokSalesReportChartPayload', readonly currency: Types.ReportSupportedCurrency, readonly itemsOrdered: ReadonlyArray<Record<string, any>>, readonly sales: ReadonlyArray<Record<string, any>>, readonly unitsOrdered: ReadonlyArray<Record<string, any>> } };


export const TikTokSalesReportChartDocument = gql`
    query TikTokSalesReportChart($input: TikTokSalesReportChartInput!) {
  tikTokSalesReportChart(input: $input) {
    currency
    itemsOrdered
    sales
    unitsOrdered
  }
}
    `;

/**
 * __useTikTokSalesReportChartQuery__
 *
 * To run a query within a React component, call `useTikTokSalesReportChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useTikTokSalesReportChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTikTokSalesReportChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTikTokSalesReportChartQuery(baseOptions: Apollo.QueryHookOptions<TikTokSalesReportChartQuery, TikTokSalesReportChartQueryVariables> & ({ variables: TikTokSalesReportChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TikTokSalesReportChartQuery, TikTokSalesReportChartQueryVariables>(TikTokSalesReportChartDocument, options);
      }
export function useTikTokSalesReportChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TikTokSalesReportChartQuery, TikTokSalesReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TikTokSalesReportChartQuery, TikTokSalesReportChartQueryVariables>(TikTokSalesReportChartDocument, options);
        }
export function useTikTokSalesReportChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TikTokSalesReportChartQuery, TikTokSalesReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TikTokSalesReportChartQuery, TikTokSalesReportChartQueryVariables>(TikTokSalesReportChartDocument, options);
        }
export type TikTokSalesReportChartQueryHookResult = ReturnType<typeof useTikTokSalesReportChartQuery>;
export type TikTokSalesReportChartLazyQueryHookResult = ReturnType<typeof useTikTokSalesReportChartLazyQuery>;
export type TikTokSalesReportChartSuspenseQueryHookResult = ReturnType<typeof useTikTokSalesReportChartSuspenseQuery>;
export type TikTokSalesReportChartQueryResult = Apollo.QueryResult<TikTokSalesReportChartQuery, TikTokSalesReportChartQueryVariables>;
export function refetchTikTokSalesReportChartQuery(variables: TikTokSalesReportChartQueryVariables) {
      return { query: TikTokSalesReportChartDocument, variables: variables }
    }