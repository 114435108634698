import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AmazonSalesChartQueryVariables = Types.Exact<{
  input: Types.AmazonSalesChartInput;
}>;


export type AmazonSalesChartQuery = { readonly amazonSalesChart: { readonly __typename?: 'AmazonSalesChartPayload', readonly averageNumberOfUnitsPerOrderItem: ReadonlyArray<Record<string, any>>, readonly currency: Types.ReportSupportedCurrency, readonly itemAverageCost: ReadonlyArray<Record<string, any>>, readonly itemsOrdered: ReadonlyArray<Record<string, any>>, readonly sales: ReadonlyArray<Record<string, any>>, readonly unitAverageCost: ReadonlyArray<Record<string, any>>, readonly unitsOrdered: ReadonlyArray<Record<string, any>> } };


export const AmazonSalesChartDocument = gql`
    query AmazonSalesChart($input: AmazonSalesChartInput!) {
  amazonSalesChart(input: $input) {
    averageNumberOfUnitsPerOrderItem
    currency
    itemAverageCost
    itemsOrdered
    sales
    unitAverageCost
    unitsOrdered
  }
}
    `;

/**
 * __useAmazonSalesChartQuery__
 *
 * To run a query within a React component, call `useAmazonSalesChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmazonSalesChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmazonSalesChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAmazonSalesChartQuery(baseOptions: Apollo.QueryHookOptions<AmazonSalesChartQuery, AmazonSalesChartQueryVariables> & ({ variables: AmazonSalesChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AmazonSalesChartQuery, AmazonSalesChartQueryVariables>(AmazonSalesChartDocument, options);
      }
export function useAmazonSalesChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AmazonSalesChartQuery, AmazonSalesChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AmazonSalesChartQuery, AmazonSalesChartQueryVariables>(AmazonSalesChartDocument, options);
        }
export function useAmazonSalesChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AmazonSalesChartQuery, AmazonSalesChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AmazonSalesChartQuery, AmazonSalesChartQueryVariables>(AmazonSalesChartDocument, options);
        }
export type AmazonSalesChartQueryHookResult = ReturnType<typeof useAmazonSalesChartQuery>;
export type AmazonSalesChartLazyQueryHookResult = ReturnType<typeof useAmazonSalesChartLazyQuery>;
export type AmazonSalesChartSuspenseQueryHookResult = ReturnType<typeof useAmazonSalesChartSuspenseQuery>;
export type AmazonSalesChartQueryResult = Apollo.QueryResult<AmazonSalesChartQuery, AmazonSalesChartQueryVariables>;
export function refetchAmazonSalesChartQuery(variables: AmazonSalesChartQueryVariables) {
      return { query: AmazonSalesChartDocument, variables: variables }
    }