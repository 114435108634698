import { useEffect } from 'react';

import { MdAccountProduct, MdUserRole } from '~anyx/common/graphql';
import { useSelfCtx } from '~anyx/common/self';
import { useAccountDetailCtx } from '~anyx/feature/account';
import {
  ANYCHAT_DOMAIN_NAME,
  ANYCHAT_PROD_APP_DOMAIN,
  ANYCHAT_STG_APP_DOMAIN,
  ANYLOGI_DOMAIN_NAME,
  ANYLOGI_PROD_APP_DOMAIN,
  ANYLOGI_STG_APP_DOMAIN,
  ANYX_DOMAIN_NAME,
  ANYX_PROD_APP_DOMAIN,
  ANYX_STG_APP_DOMAIN,
} from '~anyx/shared/domain-product';
import { Environment, EnvironmentUtils } from '~anyx/shared/utils';

const getDomainName = (product: MdAccountProduct) => {
  switch (product) {
    case MdAccountProduct.ANYCHAT:
      return ANYCHAT_DOMAIN_NAME;
    case MdAccountProduct.ANYLOGI:
      return ANYLOGI_DOMAIN_NAME;
    case MdAccountProduct.ANYX:
    default:
      return ANYX_DOMAIN_NAME;
  }
};

const getProductOrigin = (product: MdAccountProduct) => {
  switch (product) {
    case MdAccountProduct.ANYCHAT:
      return ANYCHAT_PROD_APP_DOMAIN;
    case MdAccountProduct.ANYLOGI:
      return ANYLOGI_PROD_APP_DOMAIN;
    case MdAccountProduct.ANYX:
    default:
      return ANYX_PROD_APP_DOMAIN;
  }
};

const getStgProductOrigin = (product: MdAccountProduct) => {
  switch (product) {
    case MdAccountProduct.ANYCHAT:
      return ANYCHAT_STG_APP_DOMAIN;
    case MdAccountProduct.ANYLOGI:
      return ANYLOGI_STG_APP_DOMAIN;
    case MdAccountProduct.ANYX:
    default:
      return ANYX_STG_APP_DOMAIN;
  }
};

export const DomainSwitcher = () => {
  const { account, loading } = useAccountDetailCtx();
  const {
    isSet,
    self: { role },
  } = useSelfCtx();
  const environment = EnvironmentUtils.getCurrentEnvironment();

  useEffect(() => {
    if (!isSet || loading || !account) {
      return;
    }
    const { product } = account;
    // Because they want to do thing weirdly all the time
    // If a MERCHANT user access a domain that is not the one on his account
    // FE is responsible for redirecting to the proper domain
    if (
      environment === Environment.PRODUCTION &&
      role === MdUserRole.MERCHANT &&
      !window.location.origin.includes(getDomainName(product))
    ) {
      const redirectOrigin = getProductOrigin(product);

      // eslint-disable-next-line no-console
      console.log(
        `User Merchant with product ${getDomainName(product)} on ${
          window.location.origin
        } will be redirected to ${redirectOrigin}`
      );

      window.location.href = `${redirectOrigin}${window.location.pathname}${window.location.search}`;
    }

    // STG Checks
    if (
      environment === Environment.STAGING &&
      role === MdUserRole.MERCHANT &&
      window.location.host.startsWith('stg.') &&
      !window.location.origin.includes(getDomainName(product))
    ) {
      const redirectOrigin = getStgProductOrigin(product);

      // eslint-disable-next-line no-console
      console.log(
        `User Merchant with product ${getDomainName(product)} on ${
          window.location.origin
        } will be redirected to ${redirectOrigin}`
      );

      window.location.href = `${redirectOrigin}${window.location.pathname}${window.location.search}`;
    }
  }, [environment, isSet, account, role, loading]);

  return null;
};
