import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { StoreRoutePath } from '~anyx/common/routing';
import { RestrictedWorkspaceMode, WorkspaceMode } from '~anyx/common/workspace';

import { integrationRoutings } from './integration.routing';

const StoreListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.StoreListPage }))
);
const StoreEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.StoreEditPage }))
);

const StoreTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.StoreTemplate }))
);

const RedirectToDefaultStoreRoutes = () => {
  return (
    <RestrictedWorkspaceMode
      restrictedTo={[WorkspaceMode.ON]}
      fallback={<Navigate to={StoreRoutePath().list().path} replace={true} />}
    >
      <Navigate to={StoreRoutePath().edit().path} replace={true} />
    </RestrictedWorkspaceMode>
  );
};

export const routes: RouteObject[] = [
  {
    path: ':workspaceMasterStoreDataId?',
    children: [
      {
        path: 'store',
        element: (
          <Auth0Guard>
            <StoreTemplate />
          </Auth0Guard>
        ),
        children: [
          ...integrationRoutings,
          {
            path: 'list',
            element: <StoreListPage />,
          },
          {
            path: '',
            children: [
              {
                index: true,
                element: <StoreEditPage />,
              },
            ],
          },
          {
            path: ':masterDataStoreId',
            children: [
              ...integrationRoutings,
              {
                index: true,
                element: <StoreEditPage />,
              },
            ],
          },

          {
            index: true,
            element: <RedirectToDefaultStoreRoutes />,
          },
        ],
      },
    ],
  },
];
