import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { Drawer, rem, Badge, useMantineTheme } from '@any-ui-react/core';
import classNames from 'classnames';

import { HomeRoutePath } from '~anyx/common/routing';
import { RestrictedWorkspaceMode, useWorkspaceCtx, WorkspaceMode } from '~anyx/common/workspace';
import { MenuIcon } from '~anyx/shared/icons';
import { RouteLinks } from '~anyx/shared/utils';

import { WorkspaceSelect } from '../workspace-select';

import { NavigationLinks } from './nav-links';
import styles from './sidenav.module.css';
import { UserMenu } from './UserMenu';
export interface TopnavProps {
  links: Array<RouteLinks>;
}

export const Topnav = ({ links }: TopnavProps) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const { mode } = useWorkspaceCtx();
  const { other } = useMantineTheme();

  return (
    <div
      className="shadow-card sticky top-0 z-10 flex items-center justify-between bg-white"
      aria-label="Topnav"
    >
      <Drawer
        opened={isOpen}
        onClose={() => setOpen(false)}
        size="80vw"
        closeButtonProps={{
          size: rem(14),
        }}
        classNames={{ body: 'py-0 px-2' }}
      >
        <div className={classNames(styles['content-wrapper'], 'overflow-auto')}>
          <RestrictedWorkspaceMode restrictedTo={[WorkspaceMode.OFF, WorkspaceMode.ACCOUNT_ONLY]}>
            <div className="mb-3 flex justify-center">
              <Badge className="bg-gray-2">
                <span className="text-gray-7 text-3xs truncate font-semibold">
                  {t('core.workspace.mode', { ns: 'core', mode })}
                </span>
              </Badge>
            </div>
          </RestrictedWorkspaceMode>
          <RestrictedWorkspaceMode restrictedTo={[WorkspaceMode.ON]}>
            <div className="mb-3">
              <WorkspaceSelect />
            </div>
          </RestrictedWorkspaceMode>
          <NavigationLinks links={links} onLinkClick={() => setOpen(false)} />
        </div>
      </Drawer>
      <button className="px-4 py-2" onClick={() => setOpen(true)}>
        <MenuIcon className="stroke-gray-7 h-5.5 w-5.5" />
      </button>

      <div className="p-2">
        <Link to={HomeRoutePath().root().path}>
          {other['logo']?.({ className: 'fill-gray-7', height: '2rem', width: '5.25rem' })}
        </Link>
      </div>
      <div>
        <UserMenu />
      </div>
    </div>
  );
};
