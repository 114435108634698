import { createContext, useContext } from 'react';
import { NavigationType, Location } from 'react-router';

type DefaultLocation = {
  previous?: Location;
  current?: Location;
  historyAction?: NavigationType;
};
export const defaultLocation: DefaultLocation = {};

export const LocationContext = createContext(defaultLocation);

export const useLocationCtx = () => {
  const { ...location } = useContext(LocationContext);
  return location;
};
