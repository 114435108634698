import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { ProductDetail, ReportSupportedCurrency } from '~anyx/common/graphql';
import { Image, Card, NumberWithCurrency, LoadWrapper } from '~anyx/shared/ui';
import { NumberUtils } from '~anyx/shared/utils';

import { RatingStar } from '../product-review';

import { ProductOverviewCardSkeleton } from './ProductOverviewCardSkeleton';

interface ProductOverviewCardProps {
  channelSelector?: React.ReactNode;
  product?: Pick<ProductDetail, 'id' | 'name' | 'media' | 'description'>;
  averageRating?: number;
  totalRatings?: number;
  productLoading?: boolean;
  className?: string;
  category?: readonly string[] | null;
  currency?: ReportSupportedCurrency | null;
  price?: string | null;
  productUrl?: string | null;
}

export const ProductOverviewCard = ({
  channelSelector,
  product,
  averageRating,
  totalRatings,
  productLoading,
  category,
  currency,
  price,
  productUrl,
  className,
}: ProductOverviewCardProps) => {
  const { t } = useTranslation('shared');

  return (
    <Card withPadding={false} className={classNames('py-2 pl-2 pr-4', className)}>
      <LoadWrapper loading={productLoading} loadingTemplate={<ProductOverviewCardSkeleton />}>
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-4 flex items-center md:col-span-3">
            <Image
              alt={t('shared.action.image', {
                ns: 'shared',
                entity: t('shared.entity.product', { ns: 'shared', count: 1 }),
              })}
              className="mr-2 h-14 w-14"
              noImageClassNameWrapper="mr-2 h-14 w-14"
              src={product?.media[0] || ''}
            />
            <div className="flex min-w-0 flex-col">
              <a
                title={product?.name || ''}
                href={productUrl || ''}
                rel="noreferrer"
                target="_blank"
                className="group-hover:text-link hover:text-link mb-2 truncate text-sm font-semibold leading-7 hover:underline"
              >
                {product?.name}
              </a>
              <div className="mb-1 flex gap-2">
                {price && NumberUtils.toNumber(price) > 0 ? (
                  <>
                    <div className="leading-5">
                      <NumberWithCurrency
                        amount={price}
                        currency={currency}
                        amountClassName="text-xs"
                        currencyClassName="text-xs"
                      />
                    </div>
                    <div className="bg-gray-2 w-px" />
                  </>
                ) : null}
                <div className="flex h-3 items-center gap-2">
                  {totalRatings && totalRatings > 0 ? (
                    <>
                      <RatingStar averageRating={averageRating} size="sm" />
                      <p className="text-xs leading-5">
                        {`${t('report.page.product.outOfFive', {
                          ns: 'report',
                          amount: averageRating,
                          compact: true,
                        })} (${totalRatings})`}
                      </p>
                    </>
                  ) : null}
                </div>
              </div>
              <p className="h-3 text-xs leading-5">{category?.join(' > ')}</p>
            </div>
          </div>

          {channelSelector && (
            <div className="col-span-4 flex items-center gap-2 md:col-span-1 md:justify-end">
              {t('report.page.product.for', { ns: 'report' })}
              {channelSelector}
            </div>
          )}
        </div>
      </LoadWrapper>
    </Card>
  );
};
