import { useSessionStorage } from '@mantine/hooks';

import { MdAccountSortByFieldEnum } from '~anyx/common/graphql';
import { useSelfCtx } from '~anyx/common/self';
import { WorkspaceMode, useWorkspaceCtx } from '~anyx/common/workspace';
import { DefaultAccountSelection } from '~anyx/feature/report';
import {
  Environment,
  EnvironmentUtils,
  REPORT_DEFAULT_ACCOUNT_STORAGE_KEY,
  REPORT_DEFAULT_ACCOUNTS_STORAGE_KEY,
} from '~anyx/shared/utils';

import { useDefaultAccountQuery } from '../graphql';

export const useDefaultAccount = (
  type: 'single' | 'multiple',
  accountId?: string,
  skip?: boolean
) => {
  const { self } = useSelfCtx();
  const { mode } = useWorkspaceCtx();
  const environment = EnvironmentUtils.getCurrentEnvironment();

  const { data: anymindAccounts, loading: accountLoadingFirst } = useDefaultAccountQuery({
    variables: {
      sortBy: {
        descending: false,
        field: MdAccountSortByFieldEnum.COMPANY_NAME,
      },
      filter: { keyword: 'AnyMind' },
      pageNumber: 1,
      pageSize: 200,
    },
    skip: skip || mode === WorkspaceMode.ON,
  });

  const { data, loading: accountLoadingSecond } = useDefaultAccountQuery({
    variables: {
      sortBy: {
        descending: false,
        field: MdAccountSortByFieldEnum.COMPANY_NAME,
      },
      pageNumber: 1,
      pageSize: 30,
    },
    skip: skip || mode === WorkspaceMode.ON,
  });

  const [savedAccount] = useSessionStorage<Record<string, string>>({
    key: REPORT_DEFAULT_ACCOUNT_STORAGE_KEY,
    getInitialValueInEffect: false,
    defaultValue: {},
  });
  const [savedAccounts] = useSessionStorage<Record<string, string[]>>({
    key: REPORT_DEFAULT_ACCOUNTS_STORAGE_KEY,
    getInitialValueInEffect: false,
    defaultValue: {},
  });

  const savedAccountId = savedAccount[self.id];
  const savedAccountIds = savedAccounts[self.id];

  const getPreselectedAccountId = () => {
    if (type !== 'single') return;

    const foundAccountId = anymindAccounts?.MdAccounts.items.find(
      (item) => DefaultAccountSelection['AnyMind Japan'] === item.id
    )?.id;
    const firstAccountId = data?.MdAccounts.items[0]?.id;

    const preSelectedAccountId =
      environment === Environment.PRODUCTION ? foundAccountId : savedAccountId;

    return preSelectedAccountId || firstAccountId;
  };

  const getPreselectedAccountIds = () => {
    if (type !== 'multiple') return [];

    const filteredDefaultAccountIds = anymindAccounts?.MdAccounts.items
      .filter((item) => Object.values(DefaultAccountSelection).includes(item.id))
      .map((item) => item.id);

    const firstThirtyAccountIds = data?.MdAccounts.items.map((item) => item.id) || [];

    const preSelectedAccountIds =
      environment === Environment.PRODUCTION ? filteredDefaultAccountIds : savedAccountIds;

    return preSelectedAccountIds?.length ? preSelectedAccountIds : firstThirtyAccountIds;
  };

  return {
    defaultAccountId: accountId || savedAccountId || getPreselectedAccountId(),
    defaultAccountIds: accountId ? [accountId] : savedAccountIds || getPreselectedAccountIds(),
    loading: accountLoadingFirst || accountLoadingSecond,
  };
};
