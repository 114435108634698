import { useCallback, useMemo } from 'react';
import { createSearchParams, NavigateOptions, Path, useNavigate } from 'react-router';

import { useLocationCtx } from '../context';

type Pathname = Omit<Partial<Path>, 'pathname'> & { pathname: string | number };
interface UserGoBackProps {
  to?: Pathname;
  fallback?: Pathname;
  options?: NavigateOptions;
}

export const useGoBack = ({ to, fallback, options }: UserGoBackProps = {}) => {
  const navigate = useNavigate();
  const { previous, current } = useLocationCtx();

  const isSameRoute = useMemo(
    () => current?.pathname === previous?.pathname && current?.search === previous?.search,
    [current, previous]
  );

  const getPath = useCallback(() => {
    const getPathName = (path: Pathname) => {
      if (typeof path.pathname === 'number') {
        return path.pathname;
      }
      return {
        pathname: path.pathname,
        search: createSearchParams(path.search).toString(),
      };
    };

    if (to) {
      return getPathName(to);
    }

    if (!previous || isSameRoute) {
      return fallback ? getPathName(fallback) : null;
    }

    return {
      pathname: previous.pathname,
      search: previous.search,
    };
  }, [to, fallback, previous, isSameRoute]);

  const goBack = useCallback(() => {
    const path = getPath();
    if (path) {
      if (typeof path === 'number') {
        navigate(path);
      } else {
        navigate(path, { replace: false, ...options });
      }
    }
  }, [getPath, navigate, options]);

  return goBack;
};
