import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DefaultAccountQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.MdAccountFilterInput>;
  sortBy?: Types.InputMaybe<Types.MdAccountSortByInput>;
}>;


export type DefaultAccountQuery = { MdAccounts: { __typename?: 'MdAccounts', items: Array<{ __typename?: 'MdAccount', id: string }> } };


export const DefaultAccountDocument = gql`
    query DefaultAccount($pageNumber: Int!, $pageSize: Int!, $filter: MdAccountFilterInput, $sortBy: MdAccountSortByInput) {
  MdAccounts(
    pageNumber: $pageNumber
    pageSize: $pageSize
    filter: $filter
    sortBy: $sortBy
  ) {
    items {
      id
    }
  }
}
    `;

/**
 * __useDefaultAccountQuery__
 *
 * To run a query within a React component, call `useDefaultAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultAccountQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDefaultAccountQuery(baseOptions: Apollo.QueryHookOptions<DefaultAccountQuery, DefaultAccountQueryVariables> & ({ variables: DefaultAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultAccountQuery, DefaultAccountQueryVariables>(DefaultAccountDocument, options);
      }
export function useDefaultAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultAccountQuery, DefaultAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultAccountQuery, DefaultAccountQueryVariables>(DefaultAccountDocument, options);
        }
export function useDefaultAccountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DefaultAccountQuery, DefaultAccountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DefaultAccountQuery, DefaultAccountQueryVariables>(DefaultAccountDocument, options);
        }
export type DefaultAccountQueryHookResult = ReturnType<typeof useDefaultAccountQuery>;
export type DefaultAccountLazyQueryHookResult = ReturnType<typeof useDefaultAccountLazyQuery>;
export type DefaultAccountSuspenseQueryHookResult = ReturnType<typeof useDefaultAccountSuspenseQuery>;
export type DefaultAccountQueryResult = Apollo.QueryResult<DefaultAccountQuery, DefaultAccountQueryVariables>;
export function refetchDefaultAccountQuery(variables: DefaultAccountQueryVariables) {
      return { query: DefaultAccountDocument, variables: variables }
    }