import React from 'react';
import { RouteObject } from 'react-router';

const AutomationListPage = React.lazy(() => import('./pages/list'));
const AutomationAddPage = React.lazy(() => import('./pages/add'));
const AutomationEditPage = React.lazy(() => import('./pages/edit'));
const AutomationConnectInstagram = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.AutomationConnectInstagram }))
);

export const routes: RouteObject[] = [
  {
    path: 'automation',
    element: <AutomationConnectInstagram />,
    children: [
      {
        index: true,
        element: <AutomationListPage />,
      },
      {
        path: 'add',
        element: <AutomationAddPage />,
      },
      {
        path: ':automationId',
        element: <AutomationEditPage />,
      },
    ],
  },
];
