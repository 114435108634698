import { ReactNode } from 'react';

import classNames from 'classnames';

import { SkeletonLoader } from '~anyx/shared/ui';

import { ReportPercentageArrow } from '../report-percentage-arrow';

interface IconReportCardContainerProps {
  cards: {
    title: string;
    value: string | JSX.Element;
    percentage: string;
    icon: ReactNode;
  }[];
  wrapperClassName?: string;
  loading?: boolean;
}
export const IconReportCardContainer = ({
  cards,
  wrapperClassName,
  loading,
  ...rest
}: IconReportCardContainerProps) => {
  return (
    <div className={classNames(wrapperClassName, 'grid grid-cols-2 gap-4')} {...rest}>
      {cards.map((card, i) => {
        return (
          <div key={i} className="card">
            <div className="p-4">
              <div className="mb-2">
                <SkeletonLoader loading={loading} className="h-6 w-6">
                  {card.icon}
                </SkeletonLoader>
              </div>
              <div className="text-2xs text-gray-7 mb-2 leading-none">
                <SkeletonLoader loading={loading} className="h-3 w-1/2">
                  {card.title}
                </SkeletonLoader>
              </div>
              <div className="mb-2 text-xl font-bold leading-none">
                <SkeletonLoader loading={loading} className="h-5 w-3/4">
                  {card.value}
                </SkeletonLoader>
              </div>
              <div className="leading-none">
                <SkeletonLoader loading={loading} className="h-4 w-4/5">
                  <ReportPercentageArrow value={card?.percentage} />
                </SkeletonLoader>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
