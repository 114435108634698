import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/common/graphql';

const Qoo10Template = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.Qoo10Template }))
);

const Qoo10AddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.Qoo10AddPage }))
);

const Qoo10EditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.Qoo10EditPage }))
);

export const qoo10Routing = [
  {
    path: MarketplaceSaleChannelType.Qoo10,
    element: <Qoo10Template />,
    children: [
      {
        index: true,
        element: <Qoo10AddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            index: true,
            element: <Qoo10EditPage />,
          },
        ],
      },
    ],
  },
];
