import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnboardingCreateStoreMutationVariables = Types.Exact<{
  input: Types.MdCreateStoreInput;
}>;


export type OnboardingCreateStoreMutation = { MdCreateStore: { __typename?: 'MdStore', id: string, name: string } };


export const OnboardingCreateStoreDocument = gql`
    mutation OnboardingCreateStore($input: MdCreateStoreInput!) {
  MdCreateStore(input: $input) {
    id
    name
  }
}
    `;
export type OnboardingCreateStoreMutationFn = Apollo.MutationFunction<OnboardingCreateStoreMutation, OnboardingCreateStoreMutationVariables>;

/**
 * __useOnboardingCreateStoreMutation__
 *
 * To run a mutation, you first call `useOnboardingCreateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCreateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingCreateStoreMutation, { data, loading, error }] = useOnboardingCreateStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingCreateStoreMutation(baseOptions?: Apollo.MutationHookOptions<OnboardingCreateStoreMutation, OnboardingCreateStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardingCreateStoreMutation, OnboardingCreateStoreMutationVariables>(OnboardingCreateStoreDocument, options);
      }
export type OnboardingCreateStoreMutationHookResult = ReturnType<typeof useOnboardingCreateStoreMutation>;
export type OnboardingCreateStoreMutationResult = Apollo.MutationResult<OnboardingCreateStoreMutation>;
export type OnboardingCreateStoreMutationOptions = Apollo.BaseMutationOptions<OnboardingCreateStoreMutation, OnboardingCreateStoreMutationVariables>;