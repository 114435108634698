import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductSalesChannelsQueryVariables = Types.Exact<{
  input: Types.ProductSaleChannelsInput;
}>;


export type ProductSalesChannelsQuery = { productSaleChannels: { __typename?: 'ProductSaleChannelsPayload', saleChannelTypes: Array<Types.SaleChannelType> } };


export const ProductSalesChannelsDocument = gql`
    query ProductSalesChannels($input: ProductSaleChannelsInput!) {
  productSaleChannels(input: $input) {
    saleChannelTypes
  }
}
    `;

/**
 * __useProductSalesChannelsQuery__
 *
 * To run a query within a React component, call `useProductSalesChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSalesChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSalesChannelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductSalesChannelsQuery(baseOptions: Apollo.QueryHookOptions<ProductSalesChannelsQuery, ProductSalesChannelsQueryVariables> & ({ variables: ProductSalesChannelsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSalesChannelsQuery, ProductSalesChannelsQueryVariables>(ProductSalesChannelsDocument, options);
      }
export function useProductSalesChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSalesChannelsQuery, ProductSalesChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSalesChannelsQuery, ProductSalesChannelsQueryVariables>(ProductSalesChannelsDocument, options);
        }
export function useProductSalesChannelsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProductSalesChannelsQuery, ProductSalesChannelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSalesChannelsQuery, ProductSalesChannelsQueryVariables>(ProductSalesChannelsDocument, options);
        }
export type ProductSalesChannelsQueryHookResult = ReturnType<typeof useProductSalesChannelsQuery>;
export type ProductSalesChannelsLazyQueryHookResult = ReturnType<typeof useProductSalesChannelsLazyQuery>;
export type ProductSalesChannelsSuspenseQueryHookResult = ReturnType<typeof useProductSalesChannelsSuspenseQuery>;
export type ProductSalesChannelsQueryResult = Apollo.QueryResult<ProductSalesChannelsQuery, ProductSalesChannelsQueryVariables>;
export function refetchProductSalesChannelsQuery(variables: ProductSalesChannelsQueryVariables) {
      return { query: ProductSalesChannelsDocument, variables: variables }
    }