import { useMemo, useCallback } from 'react';

import { MdUserRole } from '~anyx/common/graphql';
import { usePermissionCtx } from '~anyx/common/permission';
import { useWorkspaceCtx, WorkspaceMode } from '~anyx/common/workspace';

import { useSwitchWorkspace } from './useSwitchWorkspace';

export const useWorkspaceModeSwitcher = () => {
  const isWorkspaceSet = useWorkspaceCtx((state) => state.mode === WorkspaceMode.ON);
  const { switchWorkspace } = useSwitchWorkspace();
  const { allowed: isAdminOrStaff } = usePermissionCtx({
    roles: [MdUserRole.ADMIN, MdUserRole.STAFF],
  });

  const nextMode = useMemo(() => {
    if (isWorkspaceSet) return isAdminOrStaff ? WorkspaceMode.OFF : WorkspaceMode.ACCOUNT_ONLY;

    return WorkspaceMode.ON;
  }, [isAdminOrStaff, isWorkspaceSet]);

  const toggleMode = useCallback(() => {
    switchWorkspace({
      mode: nextMode,
    });
  }, [nextMode, switchWorkspace]);

  return {
    toggleMode,
    nextMode,
  };
};
