import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MdStoreSelectQueryVariables = Types.Exact<{
  mdStoreId: Types.Scalars['Long']['input'];
}>;


export type MdStoreSelectQuery = { MdStore: { __typename?: 'MdStore', id: string, name: string } };


export const MdStoreSelectDocument = gql`
    query MdStoreSelect($mdStoreId: Long!) {
  MdStore(id: $mdStoreId) {
    id
    name
  }
}
    `;

/**
 * __useMdStoreSelectQuery__
 *
 * To run a query within a React component, call `useMdStoreSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMdStoreSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMdStoreSelectQuery({
 *   variables: {
 *      mdStoreId: // value for 'mdStoreId'
 *   },
 * });
 */
export function useMdStoreSelectQuery(baseOptions: Apollo.QueryHookOptions<MdStoreSelectQuery, MdStoreSelectQueryVariables> & ({ variables: MdStoreSelectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MdStoreSelectQuery, MdStoreSelectQueryVariables>(MdStoreSelectDocument, options);
      }
export function useMdStoreSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MdStoreSelectQuery, MdStoreSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MdStoreSelectQuery, MdStoreSelectQueryVariables>(MdStoreSelectDocument, options);
        }
export function useMdStoreSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MdStoreSelectQuery, MdStoreSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MdStoreSelectQuery, MdStoreSelectQueryVariables>(MdStoreSelectDocument, options);
        }
export type MdStoreSelectQueryHookResult = ReturnType<typeof useMdStoreSelectQuery>;
export type MdStoreSelectLazyQueryHookResult = ReturnType<typeof useMdStoreSelectLazyQuery>;
export type MdStoreSelectSuspenseQueryHookResult = ReturnType<typeof useMdStoreSelectSuspenseQuery>;
export type MdStoreSelectQueryResult = Apollo.QueryResult<MdStoreSelectQuery, MdStoreSelectQueryVariables>;
export function refetchMdStoreSelectQuery(variables: MdStoreSelectQueryVariables) {
      return { query: MdStoreSelectDocument, variables: variables }
    }