import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router';

import { Menu, NavLink } from '@any-ui-react/core';

import { RouteLink, useScreenBreakpoint } from '~anyx/shared/utils';

import { navItemStyles } from './styles';

interface MenuLinkProps {
  name: string;
  aside?: React.ReactNode;
  links?: RouteLink[];
  activePath?: string;
  path?: string;
  onClick?: () => void;
  menuActive?: boolean;
}

export function MenuLink({
  name,
  aside,
  links,
  activePath,
  path,
  onClick,
  menuActive,
}: MenuLinkProps) {
  const isLargeScreen = useScreenBreakpoint('lg');
  const navigate = useNavigate();

  const active = useMemo(() => {
    return menuActive || links?.some((link) => link.activePath === activePath);
  }, [activePath, links, menuActive]);
  const classes = navItemStyles({ active, light: true });

  return (
    <Menu
      shadow="md"
      width={isLargeScreen ? 200 : 'target'}
      position={isLargeScreen ? 'right-start' : 'bottom'}
      withinPortal
      trigger="hover"
      openDelay={200}
      closeDelay={150}
    >
      <Menu.Target>
        <NavLink
          classNames={{
            root: classes.root(),
            label: classes.title({ className: 'ml-8' }),
          }}
          label={name}
          active={active}
          rightSection={aside}
          onClick={() => {
            onClick?.();

            if (path) {
              navigate(path);
            }
          }}
        />
      </Menu.Target>

      {links && (
        <Menu.Dropdown>
          {links.map((link, i) => (
            <MenuRouteLink
              key={`${link.name.key}-${i}`}
              link={link}
              activePath={activePath}
              onClick={onClick}
            />
          ))}
        </Menu.Dropdown>
      )}
    </Menu>
  );
}

interface MenuRouteLinkProps {
  link: RouteLink;
  activePath?: string;
  onClick?: () => void;
}

export function MenuRouteLink({ link, activePath, onClick }: MenuRouteLinkProps) {
  const { t } = useTranslation();
  const classes = navItemStyles({ active: link.activePath === activePath, light: true });

  return (
    <Menu.Item
      key={link.name.key}
      component={Link}
      className={classes.root()}
      to={link.path || ''}
      onClick={onClick}
      leftSection={link.icon?.({
        className: classes.itemIcon(),
      })}
    >
      <span className={classes.title()}>{t(link.name.key, link.name.options || {})}</span>
    </Menu.Item>
  );
}
