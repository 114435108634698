import { MantineColor } from '@any-ui-react/core';

import { MdAccountStatus } from '~anyx/common/graphql';

const AccountStatusColor: Record<MdAccountStatus, MantineColor> = {
  [MdAccountStatus.ACTIVE]: 'green',
  [MdAccountStatus.DEACTIVATED]: 'gray',
};

export const getAccountStatusColor = (status: MdAccountStatus) => {
  return AccountStatusColor[status] || 'gray';
};
