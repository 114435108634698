import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DatasourcesListQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.DatasourceFilterInput>;
  sortBy?: Types.InputMaybe<Types.DatasourceSortByInput>;
}>;


export type DatasourcesListQuery = { readonly allDatasources: { readonly __typename?: 'DatasourcePayloadList', readonly items: ReadonlyArray<{ readonly __typename?: 'DatasourcePayload', readonly masterDataStoreId: string, readonly createdAt: string, readonly id: string, readonly isActive: boolean, readonly setting?: Record<string, any> | null, readonly status: Types.DatasourceStatus, readonly statusDetail?: Record<string, any> | null, readonly tokenId?: string | null, readonly type: Types.DatasourceType, readonly updatedAt: string }>, readonly meta: { readonly __typename?: 'ReportListMeta', readonly total: number } } };


export const DatasourcesListDocument = gql`
    query DatasourcesList($pageNumber: Int!, $pageSize: Int!, $filter: DatasourceFilterInput, $sortBy: DatasourceSortByInput) {
  allDatasources(
    pageNumber: $pageNumber
    pageSize: $pageSize
    filter: $filter
    sortBy: $sortBy
  ) {
    items {
      masterDataStoreId
      createdAt
      id
      isActive
      setting
      status
      statusDetail
      tokenId
      type
      updatedAt
    }
    meta {
      total
    }
  }
}
    `;

/**
 * __useDatasourcesListQuery__
 *
 * To run a query within a React component, call `useDatasourcesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasourcesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasourcesListQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDatasourcesListQuery(baseOptions: Apollo.QueryHookOptions<DatasourcesListQuery, DatasourcesListQueryVariables> & ({ variables: DatasourcesListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatasourcesListQuery, DatasourcesListQueryVariables>(DatasourcesListDocument, options);
      }
export function useDatasourcesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatasourcesListQuery, DatasourcesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatasourcesListQuery, DatasourcesListQueryVariables>(DatasourcesListDocument, options);
        }
export function useDatasourcesListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DatasourcesListQuery, DatasourcesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DatasourcesListQuery, DatasourcesListQueryVariables>(DatasourcesListDocument, options);
        }
export type DatasourcesListQueryHookResult = ReturnType<typeof useDatasourcesListQuery>;
export type DatasourcesListLazyQueryHookResult = ReturnType<typeof useDatasourcesListLazyQuery>;
export type DatasourcesListSuspenseQueryHookResult = ReturnType<typeof useDatasourcesListSuspenseQuery>;
export type DatasourcesListQueryResult = Apollo.QueryResult<DatasourcesListQuery, DatasourcesListQueryVariables>;
export function refetchDatasourcesListQuery(variables: DatasourcesListQueryVariables) {
      return { query: DatasourcesListDocument, variables: variables }
    }