import { FilesUtils } from './files.utils';

export const VideoUtils = {
  imageFromFrame$: (
    videoFile: File,
    options: { frameTimeInSeconds: number; filename?: string; fileType?: string } = {
      frameTimeInSeconds: 0.1,
    }
  ) =>
    new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');
      const source = document.createElement('source');
      const context = canvas.getContext('2d');

      const urlRef = URL.createObjectURL(videoFile);
      const fileType = options.fileType || 'image/png';
      const fileName = options.filename || FilesUtils.getName(videoFile.name);

      video.style.display = 'none';
      canvas.style.display = 'none';

      source.setAttribute('src', urlRef);
      video.setAttribute('crossorigin', 'anonymous');

      video.appendChild(source);
      document.body.appendChild(canvas);
      document.body.appendChild(video);

      if (!context) {
        reject(new Error(`Couldn't retrieve context 2d`));
        return;
      }

      video.addEventListener('loadedmetadata', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
      });

      video.addEventListener('loadeddata', () => {
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error(`Couldn't convert canvas to blob`));
            return;
          }
          resolve(new File([blob], fileName, { type: fileType }));

          video.remove();
          canvas.remove();
          URL.revokeObjectURL(urlRef);
        }, fileType);
      });

      video.currentTime = options.frameTimeInSeconds;
      video.load();
    }),
};
