import React from 'react';
import { RouteObject, Navigate } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted } from '~anyx/common/permission';

export const AiChatPage = React.lazy(() =>
  import('./pages/chat').then((module) => ({ default: module.AiChatPage }))
);

export const AiTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.AiTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'ai',
    element: (
      <Auth0Guard>
        <Restricted fallback={<Navigate to={'/'} />}>
          <AiTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'chat',
        element: <AiChatPage />,
      },
      // TODO: Add AI Chat Home
      { element: <Navigate to="/ai/chat" replace={true} /> },
    ],
  },
];
