import { useCallback } from 'react';
import { To, useNavigate } from 'react-router';

import { MutationHookOptions, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import {
  FileType,
  GenerateAnylogiUploadUrlMutation,
  GenerateAnylogiUploadUrlMutationVariables,
  gql,
  Resource,
} from '~anyx/common/graphql';
import { FilesUtils } from '~anyx/shared/utils';

const GENERATE_ANYLOGI_UPLOAD_URL = gql(/* GraphQL */ `
  mutation GenerateAnylogiUploadUrl(
    $filename: String!
    $resource: Resource!
    $filetype: FileType!
    $storeId: Long
  ) {
    generateUploadUrl(
      filename: $filename
      resource: $resource
      filetype: $filetype
      storeId: $storeId
    ) {
      url
    }
  }
`);

export const useGenerateAnylogiUploadUrl = ({
  redirectTo,
  options,
  onSuccess,
  onFailure,
}: {
  redirectTo?: To;
  options?: MutationHookOptions<
    GenerateAnylogiUploadUrlMutation,
    GenerateAnylogiUploadUrlMutationVariables
  >;
  onSuccess?: () => void;
  onFailure?: (error?: unknown) => void;
} = {}) => {
  const navigate = useNavigate();
  const [generateGcpUrl, { loading: generating }] = useMutation(GENERATE_ANYLOGI_UPLOAD_URL);

  const handleGenerateUploadUrl = useCallback(
    async ({
      file,
      filetype,
      resource,
    }: {
      file?: File;
      filetype: FileType;
      resource: Resource;
    }) => {
      try {
        if (!file) {
          return;
        }

        const response = await generateGcpUrl({
          ...options,
          variables: {
            filename: file?.name,
            filetype,
            resource,
          },
        });

        if (!response.data?.generateUploadUrl?.url) {
          Sentry.captureException(new Error("Couldn't generate AnyLogi Uploadable Url"));
          onFailure?.();
          return;
        }

        const fileUrl = await FilesUtils.uploadFile(file, response.data.generateUploadUrl.url);

        onSuccess?.();

        if (redirectTo) {
          navigate(redirectTo);
        }

        return fileUrl;
      } catch (e: unknown) {
        Sentry.captureException(e);
        onFailure?.();
        return;
      }
    },
    [generateGcpUrl, options, onSuccess, redirectTo, onFailure, navigate]
  );

  return { handleGenerateUploadUrl, loading: generating };
};
