import { useStoreHasLogisticChannel } from '../../hooks';

export const LogisticPermissionControl = ({
  children,
  storeId,
  fallbackNode,
  loadingNode,
  renderChildrenAsFunction = false,
}: {
  storeId?: string | null;
  fallbackNode?: React.ReactNode;
  loadingNode?: React.ReactNode;
  renderChildrenAsFunction?: boolean;
  children?:
    | React.ReactNode
    | ((flag: ReturnType<typeof useStoreHasLogisticChannel>) => React.ReactNode);
}) => {
  const permissions = useStoreHasLogisticChannel({
    storeId: storeId || null,
  });

  if (renderChildrenAsFunction && typeof children === 'function') {
    return <>{children(permissions)}</>;
  }

  if (permissions.loading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{loadingNode}</>;
  }

  if (permissions.hasLogisticsChannel) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{fallbackNode}</>;
};
