import { Navigate, RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';

import { OnboardingPage } from './pages';
import { OnboardingTemplate } from './templates';

export const routes: RouteObject[] = [
  {
    path: 'onboarding',
    element: (
      <Auth0Guard>
        <OnboardingTemplate />
      </Auth0Guard>
    ),
    children: [
      {
        index: true,
        element: <OnboardingPage />,
      },
      { element: <Navigate to="/error/notfound" replace={true} /> },
    ],
  },
];
