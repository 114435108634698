import { ApolloClientHelpers } from '~anyx/common/graphql';
import { AccountFunctionApolloFieldPolicies } from '~anyx/function/account';
import {
  ChatFunctionApolloQueryPolicies,
  ChatFunctionApolloFieldPolicies,
} from '~anyx/function-chat/apollo';

export const typePolicies: ApolloClientHelpers.StrictTypedTypePolicies = {
  InboundProductDetailed: {
    keyFields: ['gtin'],
  },
  ...AccountFunctionApolloFieldPolicies,
  ...ChatFunctionApolloFieldPolicies,
  Query: {
    fields: {
      ...ChatFunctionApolloQueryPolicies,
    },
  },
};
