import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChannelSyncsQueryVariables = Types.Exact<{
  after: Types.Scalars['OffsetDateTime']['input'];
  masterDataStoreId: Types.Scalars['Long']['input'];
}>;


export type ChannelSyncsQuery = { saleChannelSyncs: { __typename?: 'SaleChannelSyncSummaryList', saleChannelSyncs: Array<{ __typename?: 'SaleChannelSyncSummary', action: Types.Action, saleChannelTypes: Array<Types.MarketplaceSaleChannelType>, status: Types.SaleChannelSyncStatus, errors?: Array<Record<string, any>> | null }> } };


export const ChannelSyncsDocument = gql`
    query ChannelSyncs($after: OffsetDateTime!, $masterDataStoreId: Long!) {
  saleChannelSyncs(after: $after, masterDataStoreId: $masterDataStoreId) {
    saleChannelSyncs {
      action
      saleChannelTypes
      status
      errors
    }
  }
}
    `;

/**
 * __useChannelSyncsQuery__
 *
 * To run a query within a React component, call `useChannelSyncsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelSyncsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelSyncsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      masterDataStoreId: // value for 'masterDataStoreId'
 *   },
 * });
 */
export function useChannelSyncsQuery(baseOptions: Apollo.QueryHookOptions<ChannelSyncsQuery, ChannelSyncsQueryVariables> & ({ variables: ChannelSyncsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelSyncsQuery, ChannelSyncsQueryVariables>(ChannelSyncsDocument, options);
      }
export function useChannelSyncsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelSyncsQuery, ChannelSyncsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelSyncsQuery, ChannelSyncsQueryVariables>(ChannelSyncsDocument, options);
        }
export function useChannelSyncsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ChannelSyncsQuery, ChannelSyncsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChannelSyncsQuery, ChannelSyncsQueryVariables>(ChannelSyncsDocument, options);
        }
export type ChannelSyncsQueryHookResult = ReturnType<typeof useChannelSyncsQuery>;
export type ChannelSyncsLazyQueryHookResult = ReturnType<typeof useChannelSyncsLazyQuery>;
export type ChannelSyncsSuspenseQueryHookResult = ReturnType<typeof useChannelSyncsSuspenseQuery>;
export type ChannelSyncsQueryResult = Apollo.QueryResult<ChannelSyncsQuery, ChannelSyncsQueryVariables>;
export function refetchChannelSyncsQuery(variables: ChannelSyncsQueryVariables) {
      return { query: ChannelSyncsDocument, variables: variables }
    }