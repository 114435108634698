import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DdiHomeReportProductTableQueryVariables = Types.Exact<{
  input: Types.DdiHomeReportInput;
}>;


export type DdiHomeReportProductTableQuery = { ddiHomeReportTopProducts: { __typename?: 'DdiHomeReportTopProductsPayload', currency: Types.ReportSupportedCurrency, products: Array<{ __typename?: 'DdiHomeReportTopProductPayload', grossSales: string, netSales: string, orders: string, productImageUrl?: string | null, productId?: string | null, productName?: string | null }> } };


export const DdiHomeReportProductTableDocument = gql`
    query DDIHomeReportProductTable($input: DdiHomeReportInput!) {
  ddiHomeReportTopProducts(input: $input) {
    currency
    products {
      grossSales
      netSales
      orders
      productImageUrl
      productId
      productName
    }
  }
}
    `;

/**
 * __useDdiHomeReportProductTableQuery__
 *
 * To run a query within a React component, call `useDdiHomeReportProductTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useDdiHomeReportProductTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDdiHomeReportProductTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDdiHomeReportProductTableQuery(baseOptions: Apollo.QueryHookOptions<DdiHomeReportProductTableQuery, DdiHomeReportProductTableQueryVariables> & ({ variables: DdiHomeReportProductTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DdiHomeReportProductTableQuery, DdiHomeReportProductTableQueryVariables>(DdiHomeReportProductTableDocument, options);
      }
export function useDdiHomeReportProductTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DdiHomeReportProductTableQuery, DdiHomeReportProductTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DdiHomeReportProductTableQuery, DdiHomeReportProductTableQueryVariables>(DdiHomeReportProductTableDocument, options);
        }
export function useDdiHomeReportProductTableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DdiHomeReportProductTableQuery, DdiHomeReportProductTableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DdiHomeReportProductTableQuery, DdiHomeReportProductTableQueryVariables>(DdiHomeReportProductTableDocument, options);
        }
export type DdiHomeReportProductTableQueryHookResult = ReturnType<typeof useDdiHomeReportProductTableQuery>;
export type DdiHomeReportProductTableLazyQueryHookResult = ReturnType<typeof useDdiHomeReportProductTableLazyQuery>;
export type DdiHomeReportProductTableSuspenseQueryHookResult = ReturnType<typeof useDdiHomeReportProductTableSuspenseQuery>;
export type DdiHomeReportProductTableQueryResult = Apollo.QueryResult<DdiHomeReportProductTableQuery, DdiHomeReportProductTableQueryVariables>;
export function refetchDdiHomeReportProductTableQuery(variables: DdiHomeReportProductTableQueryVariables) {
      return { query: DdiHomeReportProductTableDocument, variables: variables }
    }