import React from 'react';
import { RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { CrossBorderCourierProvider } from '~anyx/common/graphql';

const DhlEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.DhlEditPage }))
);

const DhlAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.DhlAddPage }))
);

const DhlTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.DhlTemplate }))
);

export const dhlRoutes: RouteObject[] = [
  {
    path: CrossBorderCourierProvider.DHL,
    element: (
      <Auth0Guard>
        <DhlTemplate />
      </Auth0Guard>
    ),
    children: [
      {
        index: true,
        element: <DhlAddPage />,
      },
      {
        path: ':courierId',
        element: <DhlEditPage />,
      },
    ],
  },
];
