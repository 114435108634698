import { MarketplaceSaleChannelType, Temperature } from '~anyx/common/graphql';

export const singleMarketplaceChannel = (channel?: MarketplaceSaleChannelType) => {
  return [
    MarketplaceSaleChannelType.Shopify,
    MarketplaceSaleChannelType.Rakuten,
    MarketplaceSaleChannelType.Yahoo,
    MarketplaceSaleChannelType.Zozotown,
    MarketplaceSaleChannelType.Tokopedia,
    MarketplaceSaleChannelType.Manual,
    MarketplaceSaleChannelType.Magaseek,
    MarketplaceSaleChannelType.MakeshopByGmo,
    MarketplaceSaleChannelType.RakutenFashion,
    MarketplaceSaleChannelType.Shoplist,
    MarketplaceSaleChannelType.SuperDelivery,
    MarketplaceSaleChannelType.Momo,
    MarketplaceSaleChannelType.AuPayMarket,
    MarketplaceSaleChannelType.FacebookShop,
  ].some((value) => value === channel);
};

export const getSortedTemperature = () => {
  return [Temperature.NORMAL, Temperature.KEEP_REFRIGERATED, Temperature.KEEP_FROZEN];
};
