import { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { ReportSupportedCurrency } from '~anyx/common/graphql';
import {
  NumberFormat,
  NumberWithCurrency,
  NumberWithFormat,
  NumberWithPercentage,
} from '~anyx/shared/ui';

import { ReportCard } from './ReportCard';

interface ReportCardContainerProps {
  data: {
    title: string;
    numberFormat: NumberFormat;
    value: {
      current?: string | null;
      percentage?: string | null;
    };
    reverseColor?: boolean;
  }[];
  currency?: ReportSupportedCurrency;
  displayMode?: 'card' | 'grid';
  theme?: 'light' | 'dark';
  wrapperClassName?: string;
  loading?: boolean;
}
export const ReportCardContainer = ({
  data,
  currency = ReportSupportedCurrency.USD,
  displayMode = 'card',
  theme = 'light',
  wrapperClassName,
  loading,
  ...rest
}: ReportCardContainerProps) => {
  const formatNumber = useCallback(
    ({ numberFormat, value }: { numberFormat: string; value: number }) => {
      switch (numberFormat) {
        case NumberFormat.PRICE:
          return (
            <NumberWithCurrency
              amount={value}
              currency={currency}
              currencyClassName="text-4xs lg:text-2xs"
            />
          );
        case NumberFormat.PERCENTAGE:
          return <NumberWithPercentage amount={value} />;
        default:
          return value ? <NumberWithFormat amount={+value} /> : 0;
      }
    },
    [currency]
  );

  const cards = useMemo(
    () =>
      data.map(({ title, value, numberFormat, reverseColor }) => ({
        title,
        value: formatNumber({ numberFormat, value: value?.current ? +value.current : 0 }),
        percentage: value.percentage,
        reverseColor,
      })),
    [data, formatNumber]
  );

  return (
    <div
      className={classNames(wrapperClassName, {
        'grid grid-cols-2 gap-2 p-4 pt-0 sm:grid-cols-3 lg:gap-4 lg:px-0': displayMode === 'card',
        'text-gray-9 ': theme === 'light',
        'text-white': theme === 'dark',
      })}
      {...rest}
    >
      {cards.map((card, i) => {
        return (
          <ReportCard
            key={i}
            item={card}
            loading={loading}
            theme={theme}
            displayMode={displayMode}
          />
        );
      })}
    </div>
  );
};
