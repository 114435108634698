import { useQuery } from '@apollo/client';

import { CrmMetaTokenStatusFragment } from '~anyx/common/graphql';

import { CRM_STORE_CONNECTION_STATUS } from '../graphql';

export function useCrmConnectionErrorCount({ storeId }: { storeId?: string }): number {
  const { data } = useQuery(CRM_STORE_CONNECTION_STATUS, {
    skip: !storeId,
    variables: {
      input: {
        id: storeId as string,
      },
    },
  });

  return (data?.AnyChatStore?.chats || []).reduce((acc, curr) => {
    switch (curr.__typename) {
      case 'InstagramStoreChat':
        return isInvalidMetaToken(curr.tokenStatusV2) ? ++acc : acc;
      case 'MessengerStoreChat':
        return isInvalidMetaToken(curr.tokenStatusV2) ? ++acc : acc;
      default:
        return acc;
    }
  }, 0);
}

function isInvalidMetaToken(token: CrmMetaTokenStatusFragment) {
  return token.__typename !== 'MTS_WORKING';
}
