import React from 'react';
import { RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { CrossBorderCourierProvider } from '~anyx/common/graphql';

const JapanPostEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.JapanPostEditPage }))
);

const JapanPostAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.JapanPostAddPage }))
);

const JapanPostTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.JapanPostTemplate }))
);

export const japanPostRoutes: RouteObject[] = [
  {
    path: CrossBorderCourierProvider.JAPAN_POST,
    element: (
      <Auth0Guard>
        <JapanPostTemplate />
      </Auth0Guard>
    ),
    children: [
      {
        index: true,
        element: <JapanPostAddPage />,
      },
      {
        path: ':courierId',
        element: <JapanPostEditPage />,
      },
    ],
  },
];
