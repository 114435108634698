import React from 'react';

import { DatasourceType } from '~anyx/common/graphql';

const GoogleGa4EditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({
    default: module.GoogleGa4EditPage,
  }))
);

const GoogleGa4AddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({
    default: module.GoogleGa4AddPage,
  }))
);

const GoogleGA4Template = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.GoogleGa4Template }))
);

export const googleGa4Routing = [
  {
    path: DatasourceType.GoogleAnalytics4,
    element: <GoogleGA4Template />,
    children: [
      {
        index: true,
        element: <GoogleGa4AddPage />,
      },
      {
        path: ':datasourceId',
        element: <GoogleGa4EditPage />,
      },
    ],
  },
];
