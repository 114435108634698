import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { Anchor } from '@any-ui-react/core';
import classNames from 'classnames';

import { BoxTitle } from '~anyx/shared/ui';
import { AnyXLanguage, DocUtils } from '~anyx/shared/utils';

import { SettingGuideToggle } from './setting-guide';

export const BoxHeader = ({
  title,
  linkTitle,
  url = '',
}: {
  title: string;
  linkTitle: string;
  url?: string;
}) => {
  return (
    <div className="border-gray-2 flex w-full items-center justify-between border-b p-4">
      <p className="text-base font-semibold">{title}</p>
      <Anchor component={Link} target="_blank" rel="noreferrer" to={url}>
        {linkTitle}
      </Anchor>
    </div>
  );
};

interface TipsProps {
  showSettingGuide: () => void;
  isHiddenSettingGuide?: boolean;
  className?: string;
}

export const Tips = ({ showSettingGuide, isHiddenSettingGuide, className = '' }: TipsProps) => {
  const { t } = useTranslation();
  const TipOptions = [
    {
      items: [
        {
          link: DocUtils.getGitbookLink('/getting-started/announcement', {
            [AnyXLanguage.JA_JP]: '/intro/important',
          }),
          title: t('core.page.home.tips.gettingStarted.tips01', { ns: 'core' }),
        },
        {
          link: DocUtils.getGitbookLink('/getting-started/first-time-setup-guide', {
            [AnyXLanguage.JA_JP]: '/intro/guide',
          }),
          title: t('core.page.home.tips.gettingStarted.tips02', { ns: 'core' }),
        },
      ],
      title: t('core.page.home.tips.gettingStarted.title', { ns: 'core' }),
    },
    {
      items: [
        {
          link: DocUtils.getGitbookLink('/home/home-dashboard', {
            [AnyXLanguage.JA_JP]: '/marketplace/home',
          }),
          title: t('core.page.home.tips.marketplace.tips01', { ns: 'core' }),
        },
        {
          link: DocUtils.getGitbookLink('/Orders/orders', {
            [AnyXLanguage.JA_JP]: '/marketplace/orders',
          }),
          title: t('core.page.home.tips.marketplace.tips02', { ns: 'core' }),
        },
        {
          link: DocUtils.getGitbookLink('/products/products', {
            [AnyXLanguage.JA_JP]: '/marketplace/products',
          }),
          title: t('core.page.home.tips.marketplace.tips03', { ns: 'core' }),
        },
        {
          link: DocUtils.getGitbookLink('/admin/create-brand', {
            [AnyXLanguage.JA_JP]: '/account/store',
          }),
          title: t('core.page.home.tips.marketplace.tips05', { ns: 'core' }),
        },
        {
          link: DocUtils.getGitbookLink('/crm-customer-management', {
            [AnyXLanguage.JA_JP]: '/crm/chats',
          }),
          title: t('core.page.home.tips.marketplace.tips06', { ns: 'core' }),
        },
      ],
      title: t('core.page.home.tips.marketplace.title', { ns: 'core' }),
    },
    {
      items: [
        {
          link: DocUtils.getGitbookLink('/admin/accounts', {
            [AnyXLanguage.JA_JP]: '/account/accounts',
          }),
          title: t('core.page.home.tips.admin.tips01', { ns: 'core' }),
        },
      ],
      title: t('core.page.home.tips.admin.title', { ns: 'core' }),
    },
  ];

  return (
    <div className={className}>
      <BoxTitle
        title={t('core.page.home.tips.title', { ns: 'core' })}
        aside={
          <a
            className="link-basic text-2xs"
            rel="noreferrer"
            target="_blank"
            href={DocUtils.getGitbookLink('/')}
          >
            {t('core.page.home.tips.helpCenter', { ns: 'core' })}
          </a>
        }
      />

      <div className="p-4">
        {TipOptions.map((option, optionKey) => {
          const isLastOption = optionKey === TipOptions.length - 1;

          return (
            <div
              className={classNames('text-sm leading-9', {
                'mb-6': !isLastOption,
              })}
              key={optionKey}
            >
              <p className="mb-4 font-semibold">{option.title}</p>
              <blockquote className="border-l-gray-2 border-l-2 pl-4">
                {option.items.map((item, itemKey) => (
                  <a
                    className="mb-2 block hover:underline"
                    target="_blank"
                    href={item.link}
                    key={itemKey}
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                ))}
              </blockquote>
            </div>
          );
        })}
      </div>
      {isHiddenSettingGuide && (
        <SettingGuideToggle
          wrapperClassName="hidden lg:block"
          showSettingGuide={showSettingGuide}
        />
      )}
    </div>
  );
};
