import { forwardRef, ReactElement, Ref, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionMeta,
  MultiValue,
  SingleValue,
  AsyncPaginateSelectProps,
  GroupBase,
  OnChangeValue,
  PropsValue,
  SelectForwardRef,
  useAsyncPaginateMultiSelect,
} from '@any-ui-react/select';
import { useAsyncEffect } from 'use-async-effect';

import { VariantValuesInput } from '~anyx/common/graphql';
import { LazySelectV2 } from '~anyx/shared/ui';
import { BasicOption } from '~anyx/shared/utils';

import { useProductVariantValues } from './operation';
import { LoadOptionAdditional, LoadOptionDefaultOption } from './useLoadVariantOptions';

interface ProductVariantSelectProps<T = string, IsMulti extends boolean = boolean>
  extends Omit<
    AsyncPaginateSelectProps<
      LoadOptionDefaultOption<T>,
      GroupBase<LoadOptionDefaultOption<T>>,
      LoadOptionAdditional,
      IsMulti
    >,
    'loadOptions' | 'components' | 'value' | 'wrapper'
  > {
  filters?: VariantValuesInput;
  isMulti?: IsMulti;
  value?: PropsValue<LoadOptionDefaultOption<T> | T>;
  onChange?: (
    newValue: OnChangeValue<LoadOptionDefaultOption<T>, IsMulti>,
    actionMeta: ActionMeta<LoadOptionDefaultOption<T> | T>
  ) => void;
}
const ProductVariantSelectGn = <T = string, IsMulti extends boolean = false>(
  {
    value: variants,
    filters,
    isMulti,
    isClearable = true,
    ...rest
  }: ProductVariantSelectProps<T, IsMulti>,
  ref: SelectForwardRef<LoadOptionDefaultOption<T>, IsMulti, GroupBase<LoadOptionDefaultOption<T>>>
) => {
  const arrayValue = useMemo(() => {
    const value = variants ? variants : [];
    return Array.isArray(value) ? value : [value];
  }, [variants]);

  const [values, setValues] = useState<BasicOption<T>[]>([]);

  const { t } = useTranslation();
  const paginateMultiSelectProps = useAsyncPaginateMultiSelect<
    LoadOptionDefaultOption<T>,
    GroupBase<LoadOptionDefaultOption<T>>
  >({
    getSelectedLabel: (selectedCount) =>
      t('shared.select.optionSelected', { ns: 'shared', amount: selectedCount }),
  });

  const { loadOptions } = useProductVariantValues<T>({ filters });

  useAsyncEffect(
    async (isMounted) => {
      if (!arrayValue || arrayValue.length === 0) {
        setValues([]);
        return;
      }

      try {
        if (!isMounted()) return;

        const selectedOption = arrayValue.map((value) => ({
          label: value,
          value,
        }));

        setValues(selectedOption);
      } catch (e) {
        if (!isMounted()) return;
      }
    },
    [arrayValue]
  );

  const multiProps = isMulti ? paginateMultiSelectProps : {};

  /**
   * Only use inner state if the selector is multi or is clearable
   * if not, leave the value to the parent component
   * to avoid setting the state when it's not necessary
   */

  const setNewValues = (
    value: MultiValue<LoadOptionDefaultOption<T>> | SingleValue<LoadOptionDefaultOption<T>>
  ) => {
    if (!isMulti && !isClearable) return;
    let valueToSet: BasicOption<T>[] = [];

    if (value !== null) {
      valueToSet = Array.isArray(value) ? value : [value];
    }

    setValues(valueToSet);
  };

  return (
    <LazySelectV2
      {...multiProps}
      className="[&_.any-select\_\_value-container]:flex-nowrap [&_.option-label]:truncate [&_label]:truncate"
      // TODO this line should not be needed
      // It is currently used to redraw the component so it can trigger the query again
      // This whole Brand/Supplier/Account/Variant selector need to be cleaned up
      isClearable={isClearable}
      isSearchable
      {...rest}
      onChange={(value, meta) => {
        rest?.onChange?.(value, meta);
        setNewValues(value);
      }}
      value={values}
      ref={ref}
      loadOptions={loadOptions}
    />
  );
};

export const ProductVariantSelect = forwardRef(ProductVariantSelectGn) as <
  T = string,
  IsMulti extends boolean = boolean
>(
  p: ProductVariantSelectProps<T, IsMulti> & {
    ref?: Ref<
      SelectForwardRef<LoadOptionDefaultOption<T>, IsMulti, GroupBase<LoadOptionDefaultOption<T>>>
    >;
  }
) => ReactElement;
