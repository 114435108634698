import React, { useContext } from 'react';

import { ApolloQueryResult } from '@apollo/client';

import { MdAccount, MdAccountProduct } from '~anyx/common/graphql';

import { MdAccountQuery, MdAccountQueryVariables } from './graphql';

type AccountDetailContextType = {
  loading: boolean;
  error: boolean;
  account: MdAccount | undefined;
  refetch?: (variables?: MdAccountQueryVariables) => Promise<ApolloQueryResult<MdAccountQuery>>;
};

export const AccountDetailContext = React.createContext<AccountDetailContextType>({
  loading: false,
  error: false,
  account: undefined,
});

export const useAccountDetailCtx = ({
  restrictedTo,
}: { restrictedTo?: MdAccountProduct[] } = {}) => {
  const { account, ...context } = useContext(AccountDetailContext);

  return {
    ...context,
    account,
    isAllowed: (account?.product && restrictedTo?.includes(account?.product)) ?? true,
  };
};

export const useIsAnyxUser = () => {
  const { isAllowed: isAnyxUser } = useAccountDetailCtx({ restrictedTo: [MdAccountProduct.ANYX] });
  return isAnyxUser;
};

export const useIsAnylogiUser = () => {
  const { isAllowed: isAnylogiUser } = useAccountDetailCtx({
    restrictedTo: [MdAccountProduct.ANYX],
  });
  return isAnylogiUser;
};

export const useIsAnyChatUser = () => {
  const { isAllowed: isAnyChatUser } = useAccountDetailCtx({
    restrictedTo: [MdAccountProduct.ANYCHAT],
  });
  return isAnyChatUser;
};
