import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


import { Card, LoadWrapper, Spinner } from '~anyx/shared/ui';

import { useAuthCtx } from '../context';

export const Auth0Guard = ({ children }: { children: React.ReactNode }) => {
  const { logout, login, authenticating, error, authenticated } = useAuthCtx();
  const { t } = useTranslation();

  // Logout user in case auth0 return some kind of error meaning our current state is problably bad.
  // we exlucde oauthProgress because oauth process generally include a `state` in the URL and it collide with
  // auth0 state checking.
  useEffect(() => {
    if (error) {
      logout();
    }
  }, [error, logout]);

  useEffect(() => {
    if (!authenticating && !authenticated) login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, authenticating]);

  return (
    <LoadWrapper
      error={!authenticated && !authenticating}
      errorNode={
        <div className="flex w-full items-center justify-center ">
          <Card withPadding={false} className="shadow-tile flex flex-col items-center px-12 py-32">
            <Spinner />
            <div className="mt-4">{t('core.page.guard.moveToLogin', { ns: 'core' })}</div>
          </Card>
        </div>
      }
      loaderId="auth0-guard"
      className="min-w-screen absolute right-0 top-0 z-20 flex min-h-screen min-w-full justify-center overflow-hidden bg-white"
    >
      {children}
    </LoadWrapper>
  );
};
