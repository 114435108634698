import { useMemo } from 'react';
import { useParams } from 'react-router';

import { z } from 'zod';

export const useSanitizedParams = <T extends Record<string, unknown>>(
  sanitizer: z.ZodType<T, z.ZodTypeDef, unknown>
) => {
  const params = useParams();
  const sanitizedParams = useMemo(() => sanitizer.parse(params), [params, sanitizer]);

  return sanitizedParams;
};
