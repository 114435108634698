import { VariantValuesInput } from '~anyx/common/graphql';

import {
  ProductVariantValuesSelectQuery,
  ProductVariantValuesSelectQueryVariables,
  useProductVariantValuesSelectLazyQuery,
} from '../graphql';
import { useLoadVariantOptions } from '../useLoadVariantOptions';

export const useProductVariantValues = <T = string>({
  filters: input,
}: {
  filters?: VariantValuesInput;
}) => {
  const [queryVariants] = useProductVariantValuesSelectLazyQuery();

  const { loadOptions } = useLoadVariantOptions<
    ProductVariantValuesSelectQuery['variantValues']['items'][0]['name'],
    T,
    ProductVariantValuesSelectQuery,
    ProductVariantValuesSelectQueryVariables
  >({
    labelFormatter: (item) => item,
    valueFormatter: (item) => item as unknown as T,
    listQuery: {
      variables: {
        input: {
          ...input,
        },
      },
      lazyQuery: queryVariants,
    },
  });
  return {
    loadOptions,
  };
};
