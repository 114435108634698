import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { Button } from '@any-ui-react/core';

import { ErrorCard } from '../../../molecules';

export const NotFoundMessage = ({ to = '/' }: { to?: string }) => {
  const { t } = useTranslation('shared');

  return (
    <ErrorCard
      title={t('shared.notFound.title', { ns: 'shared' })}
      guideline={t('shared.notFound.guideline', { ns: 'shared' })}
      content={
        <Link to={to}>
          <Button>{t('shared.button.home', { ns: 'shared' })}</Button>
        </Link>
      }
    />
  );
};
