import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LazadaSalesSummaryQueryVariables = Types.Exact<{
  input: Types.LazadaSalesSummaryInput;
}>;


export type LazadaSalesSummaryQuery = { readonly lazadaSalesSummary: { readonly __typename?: 'LazadaSalesSummaryPayload', readonly currency: Types.ReportSupportedCurrency, readonly conversionRate: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly orders: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly sales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly unitsOrdered: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly visitors: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string } } };


export const LazadaSalesSummaryDocument = gql`
    query LazadaSalesSummary($input: LazadaSalesSummaryInput!) {
  lazadaSalesSummary(input: $input) {
    conversionRate {
      diff
      diffPercentage
      value
    }
    currency
    orders {
      diff
      diffPercentage
      value
    }
    sales {
      diff
      diffPercentage
      value
    }
    unitsOrdered {
      diff
      diffPercentage
      value
    }
    visitors {
      diff
      diffPercentage
      value
    }
  }
}
    `;

/**
 * __useLazadaSalesSummaryQuery__
 *
 * To run a query within a React component, call `useLazadaSalesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLazadaSalesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLazadaSalesSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLazadaSalesSummaryQuery(baseOptions: Apollo.QueryHookOptions<LazadaSalesSummaryQuery, LazadaSalesSummaryQueryVariables> & ({ variables: LazadaSalesSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LazadaSalesSummaryQuery, LazadaSalesSummaryQueryVariables>(LazadaSalesSummaryDocument, options);
      }
export function useLazadaSalesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LazadaSalesSummaryQuery, LazadaSalesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LazadaSalesSummaryQuery, LazadaSalesSummaryQueryVariables>(LazadaSalesSummaryDocument, options);
        }
export function useLazadaSalesSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LazadaSalesSummaryQuery, LazadaSalesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LazadaSalesSummaryQuery, LazadaSalesSummaryQueryVariables>(LazadaSalesSummaryDocument, options);
        }
export type LazadaSalesSummaryQueryHookResult = ReturnType<typeof useLazadaSalesSummaryQuery>;
export type LazadaSalesSummaryLazyQueryHookResult = ReturnType<typeof useLazadaSalesSummaryLazyQuery>;
export type LazadaSalesSummarySuspenseQueryHookResult = ReturnType<typeof useLazadaSalesSummarySuspenseQuery>;
export type LazadaSalesSummaryQueryResult = Apollo.QueryResult<LazadaSalesSummaryQuery, LazadaSalesSummaryQueryVariables>;
export function refetchLazadaSalesSummaryQuery(variables: LazadaSalesSummaryQueryVariables) {
      return { query: LazadaSalesSummaryDocument, variables: variables }
    }