import { useCallback, useMemo } from 'react';

import { ReportSupportedCurrency } from '~anyx/common/graphql';
import { NumberFormat } from '~anyx/feature/report';
import { NumberWithCurrency, NumberWithFormat, NumberWithPercentage } from '~anyx/shared/ui';

import { useMetricCardData } from '../../hooks';
import { HomeReportSummaryComparison } from '../../type';

import { GroupedReportCardContainer, IconReportCardContainer } from './components';

export const SummaryCard = ({
  loading,
  data,
  currency = ReportSupportedCurrency.USD,
}: {
  loading: boolean;
  data?: HomeReportSummaryComparison;
  currency?: ReportSupportedCurrency;
}) => {
  const { groupedMetricCardData, iconMetricCardData } = useMetricCardData(data);
  const formatNumber = useCallback(
    ({ numberFormat, value }: { numberFormat: string; value: number }) => {
      switch (numberFormat) {
        case NumberFormat.PRICE:
          return (
            <NumberWithCurrency
              amount={value}
              currency={currency}
              currencyClassName="text-4xs lg:text-2xs font-semibold"
            />
          );
        case NumberFormat.PERCENTAGE:
          return <NumberWithPercentage amount={value} symbolClassName="text-2xs" />;
        default:
          return value ? <NumberWithFormat amount={+value} /> : '0';
      }
    },
    [currency]
  );

  const groupedReportCards = useMemo(
    () =>
      groupedMetricCardData.map(({ title, value, numberFormat }) => ({
        title,
        value: formatNumber({ numberFormat, value: value?.current ? +value.current : 0 }),
        percentage: value.percentage,
      })),
    [groupedMetricCardData, formatNumber]
  );

  const iconReportCards = useMemo(
    () =>
      iconMetricCardData.map(({ title, value, numberFormat, icon }) => ({
        title,
        value: formatNumber({ numberFormat, value: value?.current ? +value.current : 0 }),
        percentage: value.percentage,
        icon,
      })),
    [iconMetricCardData, formatNumber]
  );

  return (
    <div className="my-4 grid gap-4 lg:grid-cols-3 xl:grid-cols-7">
      <GroupedReportCardContainer
        cards={groupedReportCards}
        loading={loading}
        wrapperClassName="lg:col-span-2 xl:col-span-4"
      />
      <IconReportCardContainer
        cards={iconReportCards}
        loading={loading}
        wrapperClassName="lg:col-span-1 xl:col-span-3"
      />
    </div>
  );
};
