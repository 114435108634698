import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { Badge, useMantineTheme } from '@any-ui-react/core';

import { HomeRoutePath } from '~anyx/common/routing';
import { RestrictedWorkspaceMode, useWorkspaceCtx, WorkspaceMode } from '~anyx/common/workspace';

export const Header = () => {
  const { t } = useTranslation();
  const { mode } = useWorkspaceCtx();
  const { other } = useMantineTheme();

  return (
    <div className="ml-2 flex flex-shrink-0 items-center justify-between gap-2 overflow-hidden p-2 pt-4">
      <Link to={HomeRoutePath().root().path}>
        {other['logo']?.({ className: 'fill-gray-7', height: '2rem', width: '5.25rem' })}
      </Link>
      <RestrictedWorkspaceMode restrictedTo={[WorkspaceMode.OFF, WorkspaceMode.ACCOUNT_ONLY]}>
        <Badge className="bg-gray-2">
          <span className="text-gray-7 text-3xs truncate font-semibold">
            {t('core.workspace.mode', { ns: 'core', mode })}
          </span>
        </Badge>
      </RestrictedWorkspaceMode>
    </div>
  );
};
