import { useTranslation } from 'react-i18next';

import { Badge, MantineColor } from '@any-ui-react/core';

import { ProductVariantStatus } from '~anyx/common/graphql';
import { EMPTY_DEFAULT } from '~anyx/shared/utils';

export const ProductVariantStatusBadge = ({
  status = ProductVariantStatus.ACTIVE,
}: {
  status?: ProductVariantStatus;
}) => {
  const { t } = useTranslation();

  const StatusColor: Record<ProductVariantStatus, MantineColor> = {
    [ProductVariantStatus.ACTIVE]: 'green',
    [ProductVariantStatus.ARCHIVED]: 'gray',
  };

  return status ? (
    <Badge color={StatusColor[status]}>
      {t('marketplace.page.product.edit.card.productStatus.status', {
        ns: 'marketplace',
        status,
      })}
    </Badge>
  ) : (
    EMPTY_DEFAULT
  );
};
