import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted, Auth0Permissions } from '~anyx/common/permission';
import { ShipperRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const ShipperListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.ShipperListPage }))
);

const ShipperEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.ShipperEditPage }))
);

const ShipperAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.ShipperAddPage }))
);

const ShipperTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ShipperTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'shipper',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_SHIPPING]]}
          fallback={<UnauthorizedMessage />}
        >
          <ShipperTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <ShipperListPage />,
      },
      {
        path: ':shipperId',
        element: <ShipperEditPage />,
      },
      {
        path: 'add',
        element: <ShipperAddPage />,
      },
      {
        index: true,
        element: (
          <Navigate to={ShipperRoutePath({ workspaceStoreId: null }).list().path} replace={true} />
        ),
      },
    ],
  },
];
