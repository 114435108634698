import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TokopediaSalesDetailedReportQueryVariables = Types.Exact<{
  input: Types.TokopediaSalesDetailedReportInput;
}>;


export type TokopediaSalesDetailedReportQuery = { readonly tokopediaSalesDetailedReport: { readonly __typename?: 'TokopediaSalesDetailedReportPayloadList', readonly items: ReadonlyArray<{ readonly __typename?: 'TokopediaSalesDetailedReportPayload', readonly currency: Types.ReportSupportedCurrency, readonly date: any, readonly orders: string, readonly sales: string }>, readonly meta: { readonly __typename?: 'ReportListMeta', readonly length: number, readonly limit: number, readonly start: number, readonly total: number }, readonly total: { readonly __typename?: 'TokopediaSalesDetailedReportTotalPayload', readonly currency: Types.ReportSupportedCurrency, readonly orders: string, readonly sales: string } } };


export const TokopediaSalesDetailedReportDocument = gql`
    query TokopediaSalesDetailedReport($input: TokopediaSalesDetailedReportInput!) {
  tokopediaSalesDetailedReport(input: $input) {
    items {
      currency
      date
      orders
      sales
    }
    meta {
      length
      limit
      start
      total
    }
    total {
      currency
      orders
      sales
    }
  }
}
    `;

/**
 * __useTokopediaSalesDetailedReportQuery__
 *
 * To run a query within a React component, call `useTokopediaSalesDetailedReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokopediaSalesDetailedReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokopediaSalesDetailedReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTokopediaSalesDetailedReportQuery(baseOptions: Apollo.QueryHookOptions<TokopediaSalesDetailedReportQuery, TokopediaSalesDetailedReportQueryVariables> & ({ variables: TokopediaSalesDetailedReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokopediaSalesDetailedReportQuery, TokopediaSalesDetailedReportQueryVariables>(TokopediaSalesDetailedReportDocument, options);
      }
export function useTokopediaSalesDetailedReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokopediaSalesDetailedReportQuery, TokopediaSalesDetailedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokopediaSalesDetailedReportQuery, TokopediaSalesDetailedReportQueryVariables>(TokopediaSalesDetailedReportDocument, options);
        }
export function useTokopediaSalesDetailedReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokopediaSalesDetailedReportQuery, TokopediaSalesDetailedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokopediaSalesDetailedReportQuery, TokopediaSalesDetailedReportQueryVariables>(TokopediaSalesDetailedReportDocument, options);
        }
export type TokopediaSalesDetailedReportQueryHookResult = ReturnType<typeof useTokopediaSalesDetailedReportQuery>;
export type TokopediaSalesDetailedReportLazyQueryHookResult = ReturnType<typeof useTokopediaSalesDetailedReportLazyQuery>;
export type TokopediaSalesDetailedReportSuspenseQueryHookResult = ReturnType<typeof useTokopediaSalesDetailedReportSuspenseQuery>;
export type TokopediaSalesDetailedReportQueryResult = Apollo.QueryResult<TokopediaSalesDetailedReportQuery, TokopediaSalesDetailedReportQueryVariables>;
export function refetchTokopediaSalesDetailedReportQuery(variables: TokopediaSalesDetailedReportQueryVariables) {
      return { query: TokopediaSalesDetailedReportDocument, variables: variables }
    }