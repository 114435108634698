import { RouteObject } from 'react-router';

import { CrmRoutePathKey } from '~anyx/common/routing';

import { routes as ChatRoutes } from '../chat/routing';

const PATH_KEY = CrmRoutePathKey.enum.shopee;

export const routes: RouteObject[] = [
  {
    path: 'shopee',
    children: [...ChatRoutes(PATH_KEY)],
  },
];
