import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AmazonSalesSummaryQueryVariables = Types.Exact<{
  input: Types.AmazonSalesSummaryInput;
}>;


export type AmazonSalesSummaryQuery = { readonly amazonSalesSummary: { readonly __typename?: 'AmazonSalesSummaryPayload', readonly currency: Types.ReportSupportedCurrency, readonly averageNumberOfUnitsPerOrderItem: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly itemAverageCost: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly itemsOrdered: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly sales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly unitAverageCost: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly unitsOrdered: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string } } };


export const AmazonSalesSummaryDocument = gql`
    query AmazonSalesSummary($input: AmazonSalesSummaryInput!) {
  amazonSalesSummary(input: $input) {
    averageNumberOfUnitsPerOrderItem {
      diff
      diffPercentage
      value
    }
    currency
    itemAverageCost {
      diff
      diffPercentage
      value
    }
    itemsOrdered {
      diff
      diffPercentage
      value
    }
    sales {
      diff
      diffPercentage
      value
    }
    unitAverageCost {
      diff
      diffPercentage
      value
    }
    unitsOrdered {
      diff
      diffPercentage
      value
    }
  }
}
    `;

/**
 * __useAmazonSalesSummaryQuery__
 *
 * To run a query within a React component, call `useAmazonSalesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmazonSalesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmazonSalesSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAmazonSalesSummaryQuery(baseOptions: Apollo.QueryHookOptions<AmazonSalesSummaryQuery, AmazonSalesSummaryQueryVariables> & ({ variables: AmazonSalesSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AmazonSalesSummaryQuery, AmazonSalesSummaryQueryVariables>(AmazonSalesSummaryDocument, options);
      }
export function useAmazonSalesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AmazonSalesSummaryQuery, AmazonSalesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AmazonSalesSummaryQuery, AmazonSalesSummaryQueryVariables>(AmazonSalesSummaryDocument, options);
        }
export function useAmazonSalesSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AmazonSalesSummaryQuery, AmazonSalesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AmazonSalesSummaryQuery, AmazonSalesSummaryQueryVariables>(AmazonSalesSummaryDocument, options);
        }
export type AmazonSalesSummaryQueryHookResult = ReturnType<typeof useAmazonSalesSummaryQuery>;
export type AmazonSalesSummaryLazyQueryHookResult = ReturnType<typeof useAmazonSalesSummaryLazyQuery>;
export type AmazonSalesSummarySuspenseQueryHookResult = ReturnType<typeof useAmazonSalesSummarySuspenseQuery>;
export type AmazonSalesSummaryQueryResult = Apollo.QueryResult<AmazonSalesSummaryQuery, AmazonSalesSummaryQueryVariables>;
export function refetchAmazonSalesSummaryQuery(variables: AmazonSalesSummaryQueryVariables) {
      return { query: AmazonSalesSummaryDocument, variables: variables }
    }