import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted, Auth0Permissions } from '~anyx/common/permission';
import { PickupRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const PickupTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.PickupsTemplate }))
);

const PickupsListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.PickupsListPage }))
);

const PickupEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.PickupEditPage }))
);

const PickupAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.PickupAddPage }))
);

export const routes: RouteObject[] = [
  {
    path: 'pickups',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_SHIPPING]]}
          fallback={<UnauthorizedMessage />}
        >
          <PickupTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <PickupsListPage />,
      },
      {
        path: ':pickupId',
        element: <PickupEditPage />,
      },
      {
        path: 'add',
        element: <PickupAddPage />,
      },
      {
        index: true,
        element: (
          <Navigate to={PickupRoutePath({ workspaceStoreId: null }).list().path} replace={true} />
        ),
      },
    ],
  },
];
