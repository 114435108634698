import React from 'react';
import { RouteObject } from 'react-router';

const AuditLogsListPage = React.lazy(() => import('./pages/list'));

export const routes: RouteObject[] = [
  {
    path: ':workflowId/audit-log',
    element: <AuditLogsListPage />,
  },
];
