import React from 'react';

import { DatasourceType } from '~anyx/common/graphql';

const YahooDisplayEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({
    default: module.YahooDisplayEditPage,
  }))
);

const YahooDisplayAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({
    default: module.YahooDisplayAddPage,
  }))
);

const YahooDisplayCampaignAdSetListPage = React.lazy(() =>
  import('./pages/campaign/campaign-adset-list').then((module) => ({
    default: module.YahooDisplayCampaignAdSetListPage,
  }))
);

const YahooDisplayCampaignAdSetEditPage = React.lazy(() =>
  import('./pages/campaign/campaign-adset-edit').then((module) => ({
    default: module.YahooDisplayCampaignAdSetEditPage,
  }))
);

const YahooDisplayTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.YahooDisplayTemplate }))
);

export const yahooDisplayRouting = [
  {
    path: DatasourceType.YahooDisplayAds,
    element: <YahooDisplayTemplate />,
    children: [
      {
        index: true,
        element: <YahooDisplayAddPage />,
      },
      {
        path: ':datasourceId',
        children: [
          {
            index: true,
            element: <YahooDisplayEditPage />,
          },
          {
            path: 'campaign/:campaignId/adSet/:adSetMappingCampaignId',
            children: [
              {
                index: true,
                element: <YahooDisplayCampaignAdSetListPage />,
              },
              {
                path: 'set',
                element: <YahooDisplayCampaignAdSetEditPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];
