import { cloneElement, ReactNode, SVGProps } from 'react';
import { Translation } from 'react-i18next';

import { MantineColor } from '@any-ui-react/core';

import {
  AmazonSellerCentralMarketplace,
  CourierStatus,
  DatasourceStatus,
  LazadaMarketplace,
  LogisticChannelStatus,
  MarketplaceSaleChannelType,
  Qoo10Marketplace,
  SaleChannelType,
  ShopeeMarketplace,
  TikTokRegion,
} from '~anyx/common/graphql';
import {
  AmazonSellerCentralFaIcon,
  LazadaFaIcon,
  LogoLazadaIcon,
  LogoRakutenIcon,
  LogoShopeeIcon,
  LogoShopifyIcon,
  LogoYahooIcon,
  LogoTikTokShopIcon,
  RakutenFaIcon,
  ShopeeFaIcon,
  ShopifyFaIcon,
  YahooFaIcon,
  TikTokFaIcon,
  PuzzleIcon,
  ZozotownFaIcon,
  LogoZozotownIcon,
  LogoTokopediaIcon,
  LogoAmazonSellerCentralIcon,
  TokopediaFaIcon,
  MagaseekFaIcon,
  LogoMagaseekIcon,
  RakutenFashionFaIcon,
  LogoRakutenFashionIcon,
  ShoplistFaIcon,
  LogoShoplistIcon,
  SuperdeleveryFaIcon,
  WarningIcon,
  LogoSuperdeleveryIcon,
  Qoo10FaIcon,
  LogoQoo10Icon,
  CheckIcon,
  CrossIcon,
  RefreshIcon,
  MomoFaIcon,
  LogoMomoIcon,
  AuPayFaIcon,
  LogoAuPayIcon,
  MakeshopFaIcon,
  LogoMakeshopIcon,
  StoresFaIcon,
  LogoStoresIcon,
  FacebookShopFaIcon,
  LogoFacebookShopIcon,
} from '~anyx/shared/icons';
import { FileRestriction, FilesUtils, ObjectUtils } from '~anyx/shared/utils';

const CHANNEL_ICON: Record<
  MarketplaceSaleChannelType,
  { fa: React.ReactElement; logo: React.ReactElement }
> = {
  [MarketplaceSaleChannelType.AmazonSellerCentral]: {
    fa: <AmazonSellerCentralFaIcon />,
    logo: <LogoAmazonSellerCentralIcon />,
  },
  [MarketplaceSaleChannelType.Shopify]: {
    fa: <ShopifyFaIcon />,
    logo: <LogoShopifyIcon />,
  },
  [MarketplaceSaleChannelType.Lazada]: {
    fa: <LazadaFaIcon />,
    logo: <LogoLazadaIcon />,
  },
  [MarketplaceSaleChannelType.Shopee]: {
    fa: <ShopeeFaIcon />,
    logo: <LogoShopeeIcon />,
  },
  [MarketplaceSaleChannelType.Rakuten]: {
    fa: <RakutenFaIcon />,
    logo: <LogoRakutenIcon />,
  },
  [MarketplaceSaleChannelType.TikTok]: {
    fa: <TikTokFaIcon />,
    logo: <LogoTikTokShopIcon />,
  },
  [MarketplaceSaleChannelType.Tokopedia]: {
    fa: <TokopediaFaIcon />,
    logo: <LogoTokopediaIcon />,
  },
  [MarketplaceSaleChannelType.Yahoo]: {
    fa: <YahooFaIcon />,
    logo: <LogoYahooIcon />,
  },
  [MarketplaceSaleChannelType.Zozotown]: {
    fa: <ZozotownFaIcon />,
    logo: <LogoZozotownIcon />,
  },
  [MarketplaceSaleChannelType.Manual]: {
    fa: <PuzzleIcon />,
    logo: <PuzzleIcon />,
  },
  [MarketplaceSaleChannelType.AuPayMarket]: {
    fa: <AuPayFaIcon />,
    logo: <LogoAuPayIcon />,
  },
  [MarketplaceSaleChannelType.Magaseek]: {
    fa: <MagaseekFaIcon />,
    logo: <LogoMagaseekIcon />,
  },
  [MarketplaceSaleChannelType.RakutenFashion]: {
    fa: <RakutenFashionFaIcon />,
    logo: <LogoRakutenFashionIcon />,
  },
  [MarketplaceSaleChannelType.Shoplist]: {
    fa: <ShoplistFaIcon />,
    logo: <LogoShoplistIcon />,
  },
  [MarketplaceSaleChannelType.SuperDelivery]: {
    fa: <SuperdeleveryFaIcon />,
    logo: <LogoSuperdeleveryIcon />,
  },
  [MarketplaceSaleChannelType.Qoo10]: {
    fa: <Qoo10FaIcon />,
    logo: <LogoQoo10Icon />,
  },
  [MarketplaceSaleChannelType.Momo]: {
    fa: <MomoFaIcon />,
    logo: <LogoMomoIcon />,
  },
  [MarketplaceSaleChannelType.MakeshopByGmo]: {
    fa: <MakeshopFaIcon />,
    logo: <LogoMakeshopIcon />,
  },
  [MarketplaceSaleChannelType.Stores]: {
    fa: <StoresFaIcon />,
    logo: <LogoStoresIcon />,
  },
  [MarketplaceSaleChannelType.FacebookShop]: {
    fa: <FacebookShopFaIcon />,
    logo: <LogoFacebookShopIcon />,
  },
};

export const CHANNEL_MEDIA_RESTRICTIONS: Record<MarketplaceSaleChannelType, FileRestriction> = {
  [MarketplaceSaleChannelType.AmazonSellerCentral]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/tiff': ['.tiff'],
    },
    dimensions: {
      maxHeight: 10000,
      maxWidth: 10000,
      minHeight: 500,
      minWidth: 500,
    },
  },
  [MarketplaceSaleChannelType.Lazada]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE,
  },
  [MarketplaceSaleChannelType.Rakuten]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/bmp': ['.bmp'],
      'image/gif': ['.gif'],
      'image/png': ['.png'],
      'image/tiff': ['.tiff'],
    },
    dimensions: {
      maxHeight: 3840,
      maxWidth: 3840,
      minHeight: 0,
      minWidth: 0,
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.Shopee]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.Shopify]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/bmp': ['.bmp'],
      'image/gif': ['.gif'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
      'image/tiff': ['.tiff'],
    },
    size: FilesUtils.MEGABYTE * 20,
  },
  [MarketplaceSaleChannelType.TikTok]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 5,
  },
  [MarketplaceSaleChannelType.Tokopedia]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 5,
  },
  [MarketplaceSaleChannelType.Yahoo]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.Zozotown]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.Manual]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.AuPayMarket]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.Magaseek]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.Qoo10]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.RakutenFashion]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.Shoplist]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.SuperDelivery]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.Momo]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.MakeshopByGmo]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.Stores]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
  [MarketplaceSaleChannelType.FacebookShop]: {
    types: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    size: FilesUtils.MEGABYTE * 2,
  },
};

export const getChannelIcon = (
  marketplace: MarketplaceSaleChannelType | undefined,
  type: 'fa' | 'logo',
  props?: SVGProps<SVGElement>
) => {
  return marketplace && CHANNEL_ICON[marketplace]
    ? cloneElement(CHANNEL_ICON[marketplace][type], props)
    : null;
};

export const getChannelOptionLabel = (
  channel: MarketplaceSaleChannelType,
  country?: string | null | undefined
) => {
  return (
    <div className="flex items-center">
      <span className="mr-2">{getChannelIcon(channel, 'fa')}</span>
      <span>
        <Translation ns="gql">{(t) => <>{t('gql.enum.channel', { channel })}</>}</Translation>
      </span>
      {channel === MarketplaceSaleChannelType.Shopee && country && <span>&nbsp;{country}</span>}
    </div>
  );
};

export const ManualSalesChannels = {
  AuPayMarket: MarketplaceSaleChannelType.AuPayMarket,
  Magaseek: MarketplaceSaleChannelType.Magaseek,
  RakutenFashion: MarketplaceSaleChannelType.RakutenFashion,
  Shoplist: MarketplaceSaleChannelType.Shoplist,
  SuperDelivery: MarketplaceSaleChannelType.SuperDelivery,
  Momo: MarketplaceSaleChannelType.Momo,
  MakeshopByGmo: MarketplaceSaleChannelType.MakeshopByGmo,
  Stores: MarketplaceSaleChannelType.Stores,
  FacebookShop: MarketplaceSaleChannelType.FacebookShop,
} as const;
export const manualChannels: MarketplaceSaleChannelType[] =
  ObjectUtils.arrayFromEnum(ManualSalesChannels);

export const ReportSalesChannelType = {
  AmazonSellerCentral: SaleChannelType.AmazonSellerCentral,
  Lazada: SaleChannelType.Lazada,
  Rakuten: SaleChannelType.Rakuten,
  Shopee: SaleChannelType.Shopee,
  Shopify: SaleChannelType.Shopify,
  TikTok: SaleChannelType.TikTok,
  Tokopedia: SaleChannelType.Tokopedia,
  Yahoo: SaleChannelType.Yahoo,
} as const;

export type ReportSalesChannelType =
  (typeof ReportSalesChannelType)[keyof typeof ReportSalesChannelType];

export const ReviewAnalyticsChannelType = {
  Lazada: SaleChannelType.Lazada,
  Shopee: SaleChannelType.Shopee,
  // TODO: Add Amazon, TikTok, Tokopedia when backend is ready
} as const;

export type ReviewAnalyticsChannelType =
  (typeof ReviewAnalyticsChannelType)[keyof typeof ReviewAnalyticsChannelType];

export type SortChannelProps<T> = {
  channels?: readonly T[];
  byField?: keyof T;
  bySecondField?: keyof T;
  disabled?: MarketplaceSaleChannelType[];
};

export type AliasedSaleChannelData =
  | { __typename?: 'AmazonSaleChannelData'; amazonMarketplace: AmazonSellerCentralMarketplace }
  | { __typename?: 'LazadaSaleChannelData'; lazadaMarketplace: LazadaMarketplace }
  | { __typename?: 'Qoo10SaleChannelData'; qoo10Marketplace: Qoo10Marketplace }
  | { __typename?: 'ShopeeSaleChannelData'; shopeeMarketplace: ShopeeMarketplace }
  | { __typename?: 'TikTokSaleChannelData'; tiktokMarketplace: TikTokRegion }
  | { __typename?: 'EmptySaleChannelData' }
  | { __typename?: 'TokopediaSaleChannelData' }
  | { __typename?: 'RakutenSaleChannelData' }
  | { __typename?: 'ShopifySaleChannelData' }
  | { __typename?: 'YahooSaleChannelData' };

export class ChannelUtils {
  static isReportSaleChannelType = (type = ''): type is SaleChannelType => type in SaleChannelType;

  static asReportSaleChannelType = (type?: string) =>
    this.isReportSaleChannelType(type) ? type : undefined;

  static asReportSaleChannels = (channels: string[]) =>
    channels.reduce<SaleChannelType[]>((result, channel) => {
      if (ChannelUtils.isReportSaleChannelType(channel)) {
        return [...result, channel];
      }
      return result;
    }, []);

  static isMarketplaceSaleChannelType = (type = ''): type is MarketplaceSaleChannelType =>
    type in MarketplaceSaleChannelType;

  static asMarketplaceSaleChannelType = (type?: string) =>
    this.isMarketplaceSaleChannelType(type) ? type : undefined;

  static isReportSalesChannelType = (type = ''): type is ReportSalesChannelType =>
    type in ReportSalesChannelType;

  static asReportSalesChannelType = (type?: string) =>
    this.isReportSalesChannelType(type) ? type : undefined;

  static asReportSalesChannelTypes = (channels: string[]) =>
    channels.reduce<ReportSalesChannelType[]>((result, channel) => {
      if (ChannelUtils.isReportSalesChannelType(channel)) {
        return [...result, channel];
      }
      return result;
    }, []);

  static isManualChannel = (type = ''): type is keyof typeof ManualSalesChannels =>
    type in ManualSalesChannels;
  static asManualChannel = (type?: string) => (this.isManualChannel(type) ? type : undefined);

  static filterChannels<T>({ channels = [], byField, disabled }: SortChannelProps<T>) {
    return channels.filter((channel) => {
      const type = byField ? channel?.[byField] : channel;

      if (typeof type !== 'string' || !ChannelUtils.isMarketplaceSaleChannelType(type)) {
        return false;
      }

      return !disabled?.includes(type);
    });
  }

  static sortChannels<T>({ channels = [], byField, bySecondField, disabled }: SortChannelProps<T>) {
    let multipleFieldChannels: readonly T[] = [];

    if (byField && bySecondField) {
      multipleFieldChannels = [...channels].sort((a, b) => {
        const fieldA = `${a[byField]}-${a[bySecondField]}`;
        const fieldB = `${b[byField]}-${b[bySecondField]}`;
        return fieldA.localeCompare(fieldB);
      });
    }

    return this.filterChannels({
      channels: multipleFieldChannels.length
        ? multipleFieldChannels
        : ObjectUtils.alphabeticalOrder(channels, byField),
      byField,
      disabled,
    });
  }

  static getCountry(data?: AliasedSaleChannelData) {
    switch (data?.__typename) {
      case 'AmazonSaleChannelData':
        return data.amazonMarketplace;
      case 'LazadaSaleChannelData':
        return data.lazadaMarketplace;
      case 'Qoo10SaleChannelData':
        return data.qoo10Marketplace;
      case 'ShopeeSaleChannelData':
        return data.shopeeMarketplace;
      case 'TikTokSaleChannelData':
        return data.tiktokMarketplace;
      default:
        return null;
    }
  }
}

export type ChannelConnectionStatus = 'CONNECTED' | 'DISCONNECTED' | 'PENDING';
export const CHANNEL_CONNECTION_STATUS: Record<
  ChannelConnectionStatus,
  {
    color: MantineColor;
    i18nKey: string;
    icon: ReactNode;
  }
> = {
  CONNECTED: {
    color: 'green',
    i18nKey: 'shared.button.connected',
    icon: <CheckIcon className="text-success mr-1 h-4 w-4" />,
  },
  DISCONNECTED: {
    color: 'red',
    i18nKey: 'shared.button.disconnected',
    icon: <CrossIcon className="text-failure mr-1 h-4 w-4" />,
  },
  PENDING: {
    color: 'yellow',
    i18nKey: 'shared.button.pending',
    icon: <WarningIcon className="text-warning mr-1 h-4 w-4" />,
  },
};

export const CHANNEL_ACTIVATION_STATUS: Record<
  LogisticChannelStatus | DatasourceStatus | CourierStatus,
  {
    color: MantineColor;
    i18nKey: string;
    icon: ReactNode;
  }
> = {
  ACTIVE: {
    color: 'green',
    i18nKey: 'shared.button.connected',
    icon: <CheckIcon className="text-success mr-1 w-3" />,
  },
  INACTIVE: {
    color: 'red',
    i18nKey: 'shared.button.disconnected',
    icon: <CrossIcon className="text-failure mr-1 w-3" />,
  },
  PENDING: {
    color: 'yellow',
    i18nKey: 'shared.button.pending',
    icon: <WarningIcon className="text-warning mr-1 w-3" />,
  },
  FAILED: {
    color: 'red',
    i18nKey: 'shared.button.disconnected',
    icon: <CrossIcon className="text-failure mr-1 w-3" />,
  },
  INITIALIZING: {
    color: 'blue',
    i18nKey: 'shared.button.syncing',
    icon: <RefreshIcon className="text-info mr-1 w-3" />,
  },
};

export enum ChannelConnectionType {
  CONNECT = 'CONNECT',
  RECONNECT = 'RECONNECT',
}
