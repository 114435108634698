import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, createSearchParams, useNavigate, useSearchParams } from 'react-router';

import classNames from 'classnames';

import { ProductRoutePath, ReportProductRoutePath } from '~anyx/common/routing';
import { ArrowRightIcon } from '~anyx/shared/icons';
import {
  NoResult,
  Table,
  TableLayout,
  Image,
  NumberWithCurrency,
  NumberWithFormat,
  TooltipAlert,
} from '~anyx/shared/ui';
import { EMPTY_DEFAULT, RouterUtils, useScreenBreakpoint } from '~anyx/shared/utils';

import { HomeReportProductTableData, HomeReportProductTableItem } from '../../type';
import { NoSalesChannelConnection } from '../no-sales-channel-connection';

export const ProductRankingTable = ({
  loading,
  shouldShowMock,
  data,
}: {
  shouldShowMock: boolean;
  loading?: boolean;
  data?: HomeReportProductTableData;
}) => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenBreakpoint('lg');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const allSearchParams = useMemo(() => {
    return RouterUtils.getAllSearchParams(searchParams);
  }, [searchParams]);
  const { dimensions: _dimensions, tab: _tab, metric: _metric, ...search } = allSearchParams;

  const layout = useMemo(
    () =>
      [
        {
          accessor: 'product',
          sticky: true,
          maxWidth: isLargeScreen ? '21.875rem' : '15.625rem', // 21.875rem: 350px, 15.625rem: 250px
          minWidth: isLargeScreen ? '21.875rem' : '15.625rem',
          truncated: false,
          headerRender: () => t('core.page.home.report.table.column.productName', { ns: 'core' }),
          cellRender: ({ image, productName, productId }, index) => {
            return (
              <div className="flex items-center">
                <div className="bg-gray-9 flex-0 text-2xs mr-2 flex h-6 w-6 shrink-0 items-center justify-center rounded font-bold text-white">
                  {index + 1}
                </div>
                <div
                  title={productName || EMPTY_DEFAULT}
                  className="flex-0 relative my-2 flex items-center"
                >
                  <Image
                    alt={t('shared.action.image', {
                      ns: 'shared',
                      entity: t('shared.entity.product', { ns: 'shared', count: 1 }),
                    })}
                    className="mr-2 h-10 w-10 border-none"
                    noImageClassNameWrapper="mr-2 h-10 w-10"
                    src={image}
                  />
                  {productId ? (
                    <Link
                      title={productName || EMPTY_DEFAULT}
                      to={
                        ProductRoutePath().edit({
                          productId,
                        }).path
                      }
                      className="group-hover:text-link hover:text-link ml-1 hover:underline"
                    >
                      <TooltipAlert
                        title={
                          productName ||
                          t('shared.entity.unknownProduct', { ns: 'shared', count: 1 })
                        }
                        titleClassName="line-clamp-2 break-words break-all"
                        tooltipContent={t('shared.field.productName.tooltip', { ns: 'shared' })}
                        isTooltipVisible={!productName}
                      />
                    </Link>
                  ) : (
                    <TooltipAlert
                      title={
                        productName || t('shared.entity.unknownProduct', { ns: 'shared', count: 1 })
                      }
                      titleClassName="line-clamp-2 break-words"
                      tooltipContent={t('shared.field.productId.tooltip', { ns: 'shared' })}
                      isTooltipVisible
                    />
                  )}
                </div>
              </div>
            );
          },
        },
        {
          accessor: 'orders',
          headerRender: () => t('core.page.home.report.table.column.orders', { ns: 'core' }),
          cellRender: ({ orderCount }) => {
            return (
              <span className="text-gray-9 font-semibold">
                {orderCount ? <NumberWithFormat amount={orderCount} /> : EMPTY_DEFAULT}
              </span>
            );
          },
        },
        {
          accessor: 'grossSales',
          headerRender: () => t('core.page.home.report.table.column.grossSales', { ns: 'core' }),
          cellRender: ({ grossSales, currency }) => {
            return (
              <span className="text-gray-9 font-semibold">
                <NumberWithCurrency amount={grossSales} currency={currency} />
              </span>
            );
          },
        },
      ] as TableLayout<HomeReportProductTableItem>[],
    [isLargeScreen, t]
  );
  return (
    <div
      data-test-id="report-product-ranking-table"
      className="relative overflow-hidden shadow lg:rounded"
    >
      <div className="flex justify-between bg-white p-4">
        <span className="text-sm font-semibold leading-7">
          {t('core.page.home.report.table.product.title', { ns: 'core' })}
        </span>
      </div>
      <Table<HomeReportProductTableItem>
        layout={layout}
        rowClassName={classNames('h-14', { 'blur-xs bg-opacity-40 bg-white': shouldShowMock })}
        data={data}
        loading={loading}
        skeletonCount={5}
        notFoundTemplate={
          <NoResult
            wrapperClassName="bg-white h-80 !gap-2"
            titleClassName="text-sm"
            descriptionClassName="!text-2xs"
            title={t('shared.table.empty', {
              ns: 'shared',
              element: t('shared.entity.data', { ns: 'shared', count: 1 }),
            })}
            description={t('shared.chart.guideline', { ns: 'shared' })}
          />
        }
      />
      {shouldShowMock ? (
        <NoSalesChannelConnection />
      ) : (
        <div className="border-gray-2 flex h-10 justify-end overflow-hidden border-t bg-white px-4 py-3 leading-8">
          <button
            type="button"
            onClick={() =>
              navigate({
                pathname: ReportProductRoutePath().list().path,
                search: createSearchParams(search).toString(),
              })
            }
            className="link-basic text-2xs ml-auto inline-flex items-center "
          >
            {t('core.page.home.report.table.link.seeMore', { ns: 'core' })}
            <ArrowRightIcon className="ml-2 h-4 w-4" />
          </button>
        </div>
      )}
    </div>
  );
};
