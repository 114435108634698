import { useTranslation } from 'react-i18next';

import { useMultiSelect } from '@any-ui-react/select';

import { MarketplaceSaleChannelType } from '~anyx/common/graphql';

import { ChannelUtils, getChannelOptionLabel } from '../utils';

export const useChannelsOptions = (
  channels: MarketplaceSaleChannelType[],
  disabledChannels?: MarketplaceSaleChannelType[]
) => {
  const { t } = useTranslation();

  const channelOptions = ChannelUtils.sortChannels({
    channels,
    disabled: disabledChannels,
  }).map((channel) => ({
    label: getChannelOptionLabel(channel),
    value: channel,
  }));

  const getSelectedLabel = (selectedCount: number) => {
    if (selectedCount === channelOptions.length) {
      return t('shared.action.all', {
        ns: 'shared',
        entity: t('shared.entity.channel', { ns: 'shared', count: 2 }),
      });
    }
    return t('shared.select.optionSelected', { ns: 'shared', amount: selectedCount });
  };

  const channelSelectProps = useMultiSelect({
    options: channelOptions,
    getSelectedLabel,
  });

  return {
    channelOptions,
    channelSelectProps,
    getSelectedLabel,
  };
};
