import { gql } from '~anyx/common/graphql';

export const CRM_STORE_CONNECTION = gql(/* GraphQL */ `
  query CrmStoreConnection($input: AnyChatGetStoreRequest!) {
    AnyChatStore(input: $input) {
      id
      name
      isCrmMerchantEnabled
      chats {
        ... on LineStoreChat {
          ...CrmLineConnection
        }
        ... on InstagramStoreChat {
          ...CrmInstagramConnection
        }
        ... on MessengerStoreChat {
          ...CrmMessengerConnection
        }
        ... on LazadaStoreChat {
          ...CrmLazadaConnection
        }
        ... on WhatsAppStoreChat {
          ...CrmWhatsAppConnection
        }
        ... on ShopeeStoreChat {
          ...CrmShopeeConnection
        }
        ... on TikTokShopStoreChat {
          ...CrmTikTokShopConnection
        }
      }
    }
  }
`);

export const CRM_STORE_CONNECTION_STATUS = gql(/* GraphQL */ `
  query CrmStoreConnectionStatus($input: AnyChatGetStoreRequest!) {
    AnyChatStore(input: $input) {
      id
      chats {
        id
        ... on InstagramStoreChat {
          tokenStatusV2 {
            ...CrmMetaTokenStatus
          }
        }
        ... on MessengerStoreChat {
          tokenStatusV2 {
            ...CrmMetaTokenStatus
          }
        }
      }
    }
  }
`);
