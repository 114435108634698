import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AmazonSalesDetailedReportQueryVariables = Types.Exact<{
  input: Types.AmazonSalesDetailedReportInput;
}>;


export type AmazonSalesDetailedReportQuery = { readonly amazonSalesDetailedReport: { readonly __typename?: 'AmazonSalesDetailedReportPayloadList', readonly items: ReadonlyArray<{ readonly __typename?: 'AmazonSalesDetailedReportPayload', readonly averageNumberOfUnitsPerOrderItem: string, readonly currency: Types.ReportSupportedCurrency, readonly date: any, readonly itemAverageCost: string, readonly itemsOrdered: string, readonly sales: string, readonly unitAverageCost: string, readonly unitsOrdered: string }>, readonly meta: { readonly __typename?: 'ReportListMeta', readonly length: number, readonly limit: number, readonly start: number, readonly total: number }, readonly total: { readonly __typename?: 'AmazonSalesDetailedReportTotalPayload', readonly averageNumberOfUnitsPerOrderItem: string, readonly currency: Types.ReportSupportedCurrency, readonly itemAverageCost: string, readonly itemsOrdered: string, readonly sales: string, readonly unitAverageCost: string, readonly unitsOrdered: string } } };


export const AmazonSalesDetailedReportDocument = gql`
    query AmazonSalesDetailedReport($input: AmazonSalesDetailedReportInput!) {
  amazonSalesDetailedReport(input: $input) {
    items {
      averageNumberOfUnitsPerOrderItem
      currency
      date
      itemAverageCost
      itemsOrdered
      sales
      unitAverageCost
      unitsOrdered
    }
    meta {
      length
      limit
      start
      total
    }
    total {
      averageNumberOfUnitsPerOrderItem
      currency
      itemAverageCost
      itemsOrdered
      sales
      unitAverageCost
      unitsOrdered
    }
  }
}
    `;

/**
 * __useAmazonSalesDetailedReportQuery__
 *
 * To run a query within a React component, call `useAmazonSalesDetailedReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmazonSalesDetailedReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmazonSalesDetailedReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAmazonSalesDetailedReportQuery(baseOptions: Apollo.QueryHookOptions<AmazonSalesDetailedReportQuery, AmazonSalesDetailedReportQueryVariables> & ({ variables: AmazonSalesDetailedReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AmazonSalesDetailedReportQuery, AmazonSalesDetailedReportQueryVariables>(AmazonSalesDetailedReportDocument, options);
      }
export function useAmazonSalesDetailedReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AmazonSalesDetailedReportQuery, AmazonSalesDetailedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AmazonSalesDetailedReportQuery, AmazonSalesDetailedReportQueryVariables>(AmazonSalesDetailedReportDocument, options);
        }
export function useAmazonSalesDetailedReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AmazonSalesDetailedReportQuery, AmazonSalesDetailedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AmazonSalesDetailedReportQuery, AmazonSalesDetailedReportQueryVariables>(AmazonSalesDetailedReportDocument, options);
        }
export type AmazonSalesDetailedReportQueryHookResult = ReturnType<typeof useAmazonSalesDetailedReportQuery>;
export type AmazonSalesDetailedReportLazyQueryHookResult = ReturnType<typeof useAmazonSalesDetailedReportLazyQuery>;
export type AmazonSalesDetailedReportSuspenseQueryHookResult = ReturnType<typeof useAmazonSalesDetailedReportSuspenseQuery>;
export type AmazonSalesDetailedReportQueryResult = Apollo.QueryResult<AmazonSalesDetailedReportQuery, AmazonSalesDetailedReportQueryVariables>;
export function refetchAmazonSalesDetailedReportQuery(variables: AmazonSalesDetailedReportQueryVariables) {
      return { query: AmazonSalesDetailedReportDocument, variables: variables }
    }