import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { ConnectToFacebookRequestV2Input } from '~anyx/common/graphql';
import { AnyXError } from '~anyx/shared/utils';

import { REFRESH_FACEBOOK_TOKEN_CONNECTION } from '../graphql';

export const useRefreshFBToken = () => {
  const [refreshFBTokenMutation] = useMutation(REFRESH_FACEBOOK_TOKEN_CONNECTION);

  const { t } = useTranslation('crmIntegration');

  const refreshFBToken = async (input: ConnectToFacebookRequestV2Input) => {
    try {
      const { data, errors } = await refreshFBTokenMutation({
        variables: {
          input,
        },
      });

      if (!data?.RefreshFacebookTokenConnection)
        throw new Error('Unexpected error when trying to refresh');
      if (errors) throw errors;

      const { RefreshFacebookTokenConnection } = data;

      switch (RefreshFacebookTokenConnection?.__typename) {
        case 'UnknownErrorRefreshTokenError': {
          throw new AnyXError('REFRESH_CONNECTION_ERROR');
        }
        case 'WrongPageSelectedRefreshTokenError': {
          throw new AnyXError(
            'WRONG_PAGE_SELECTED',
            t('crmIntegration.shared.notify.refreshPermission.wrongPage')
          );
        }
        case 'NoInstagramAccountRefreshTokenError': {
          throw new AnyXError(
            'NO_INSTAGRAM_ACCOUNT',
            t('crmIntegration.shared.notify.refreshPermission.noInstagramAccount')
          );
        }
      }
    } catch (error) {
      if (!(error instanceof AnyXError)) Sentry.captureException(error);

      throw error;
    }
  };

  return {
    refreshFBToken,
  };
};
