import { useCallback } from 'react';
import { To, useNavigate } from 'react-router';

import { MutationHookOptions } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { AlertType, useApiTranslationMapper } from '~anyx/common/graphql';
import { toast } from '~anyx/shared/ui';
import { FilesUtils } from '~anyx/shared/utils';

import { useProductMediaUploadLazyQuery } from './graphql';
import { ProductMediaUploadQuery, ProductMediaUploadQueryVariables } from './graphql';

export const useProductUploadMedia = ({
  redirectTo,
  options,
}: {
  redirectTo?: To;
  options?: MutationHookOptions<ProductMediaUploadQuery, ProductMediaUploadQueryVariables>;
} = {}) => {
  const navigate = useNavigate();
  const { getErrorMessage, getTranslationKey } = useApiTranslationMapper();
  const [uploadProductMedia, { loading: uploading }] = useProductMediaUploadLazyQuery();

  const handleUploadProduct = useCallback(
    async ({ file }: { file?: File }) => {
      try {
        if (!file) {
          return;
        }

        const response = await uploadProductMedia({
          ...options,
          variables: {
            fileName: file.name,
          },
        });

        if (!response.data?.productMediaUpload?.signedUrl) {
          Sentry.captureException(new Error("Couldn't generate Product Upload Signed Url"));
          toast.error(
            getTranslationKey({
              namespace: 'gql',
              type: AlertType.ERROR,
              path: 'upload',
              message: 'uploadUrl',
            })
          );
          return;
        }

        const fileUrl = await FilesUtils.uploadFile(
          file,
          response.data?.productMediaUpload?.signedUrl
        );

        toast.success(
          getTranslationKey({
            namespace: 'gql',
            type: AlertType.SUCCESS,
            path: 'product',
            message: 'uploadProduct',
          })
        );

        if (redirectTo) {
          navigate(redirectTo);
        }

        return fileUrl;
      } catch (e: unknown) {
        Sentry.captureException(e);
        toast.error(
          getTranslationKey({
            namespace: 'gql',
            type: AlertType.ERROR,
            path: 'product',
            message: getErrorMessage(e),
          })
        );
        return;
      }
    },
    [uploadProductMedia, options, getTranslationKey, redirectTo, navigate, getErrorMessage]
  );

  return { handleUploadProduct, loading: uploading };
};
