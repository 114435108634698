import { Ref, forwardRef, useMemo } from 'react';

import { Select, SelectProps } from '@any-ui-react/core';
import { t } from 'i18next';

import { Country, MdCountryEnum } from '~anyx/common/graphql';
import { ObjectUtils } from '~anyx/shared/utils';

export interface CountrySelectProps extends Omit<SelectProps, 'data'> {
  countries: (Country | MdCountryEnum | string)[];
}

export const CountrySelect = forwardRef(
  (
    {
      countries = ObjectUtils.arrayFromEnum<MdCountryEnum>(MdCountryEnum),
      ...rest
    }: CountrySelectProps,
    ref: Ref<SelectProps & HTMLInputElement>
  ) => {
    const countryOptions = useMemo(
      () =>
        countries.map((country) => ({
          label: t('shared.enum.country', { ns: 'shared', country }),
          value: country,
        })),
      [countries]
    );

    return <Select data={countryOptions} ref={ref} {...rest} />;
  }
);
