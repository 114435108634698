import { Suspense, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { ErrorBoundary } from '@sentry/react';

import { environment } from '~anyx/app-core/env';

import { AuthProvider } from '~anyx/common/auth';
import { PermissionProvider } from '~anyx/common/permission';
import { ErrorsRoutePath, HomeRoutePath, OnboardingRoutePath } from '~anyx/common/routing';
import { SelfProvider } from '~anyx/common/self';
import { WorkspaceProvider } from '~anyx/common/workspace';
import { CrowdinInContext } from '~anyx/external/crowdin';
import { FirebaseProvider } from '~anyx/external/firebase';
import { AccountDetailProvider } from '~anyx/feature/account';
import { usePolicyDomainLink } from '~anyx/function/policy';
import { useDomainProduct, getAppNameByDomainProduct } from '~anyx/shared/domain-product';
import { ThemeProvider, ThemeUtils } from '~anyx/shared/theme';
import {
  AppLayout,
  ErrorBoundaryMessage,
  PwaNotificationBanner,
  Spinner,
  Toaster,
  ModalsProvider,
  Footer,
} from '~anyx/shared/ui';
import {
  GoogleLocationServiceUtils,
  LocationProvider,
  useDocumentHead,
  useEnvironmentStore,
  FetchProvider,
  DatesProvider,
} from '~anyx/shared/utils';

import { ApolloProvider } from './apollo';
import { TimezoneCheck, DomainSwitcher, Navigation } from './components';
import { AnyxAuthProvider } from './providers';

export const App = () => {
  const { t } = useTranslation(['core', 'shared']);
  const { product } = useDomainProduct();
  const setAppName = useEnvironmentStore((s) => s.setAppName);
  const appName = useEnvironmentStore((s) => s.appName);
  const { documentTitle } = useDocumentHead({ appName });
  const policyLinks = usePolicyDomainLink({ product, overrideDomain: window.location.origin });

  useEffect(() => {
    GoogleLocationServiceUtils.ENDPOINT = environment.google.locationServiceEndpoint;
  }, []);

  useEffect(() => {
    setAppName(getAppNameByDomainProduct(product));
  }, [product, setAppName]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <ThemeProvider theme={ThemeUtils.getProductTheme(product)}>
        <ErrorBoundary
          fallback={({ error, componentStack, resetError }) => {
            if ((error as Error)?.name === 'ChunkLoadError') {
              window.location.reload();

              return <div />;
            }

            return (
              <ErrorBoundaryMessage
                titleText={t('core.page.error.title')}
                messageText={t('core.page.error.message')}
                btnText={t('shared.button.home', { ns: 'shared' })}
                redirectTo={HomeRoutePath().root().path}
                componentStack={componentStack}
                error={error}
                resetError={resetError}
                showDetails={environment.enableDebug}
              />
            );
          }}
        >
          <AnyxAuthProvider
            clientId={environment.auth0.clientId?.[product]}
            domain={environment.auth0.domain}
            authorizationParams={{
              audience: environment.auth0.audience,
              redirect_uri: window.location.origin,
            }}
            skipRedirectCallbackRegex={[/^\/integrations\/.*$/, /^\/crm\/oauth\/.*$/]}
          >
            <ApolloProvider>
              <FetchProvider baseURL={environment.api.rest}>
                <FirebaseProvider
                  config={environment.firebase.config}
                  remoteConfig={environment.firebase.remoteConfig}
                >
                  <PwaNotificationBanner />
                  <Toaster />
                  <ModalsProvider>
                    <AuthProvider
                      loginOptions={{
                        appState: {
                          returnTo: `${window.location.pathname}${window.location.search}`,
                        },
                        authorizationParams: {
                          product,
                        },
                      }}
                      logoutOptions={{
                        logoutParams: {
                          returnTo: window.location.origin,
                        },
                      }}
                    >
                      <SelfProvider
                        unavailableLink={ErrorsRoutePath().unavailable().path}
                        permissionPath={environment.auth0.permissions}
                      >
                        <DatesProvider>
                          <LocationProvider>
                            <PermissionProvider permissionPath={environment.auth0.permissions}>
                              <WorkspaceProvider
                                onboardingRedirect={OnboardingRoutePath().root().path}
                                children={(workspace) => {
                                  return (
                                    <>
                                      <AccountDetailProvider accountId={workspace?.accountId}>
                                        <DomainSwitcher />
                                        <TimezoneCheck />
                                        <AppLayout
                                          navigation={<Navigation />}
                                          footer={<Footer {...policyLinks} />}
                                          content={
                                            <Suspense
                                              fallback={
                                                <div className="m-auto flex h-full w-full flex-col">
                                                  <Spinner />
                                                </div>
                                              }
                                            >
                                              <Outlet />
                                            </Suspense>
                                          }
                                        />
                                      </AccountDetailProvider>
                                      {environment.crowdin.allowTranslation && (
                                        <CrowdinInContext
                                          projectId={environment.crowdin.project}
                                          pseudoLanguage={environment.crowdin.pseudoLanguage}
                                        />
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </PermissionProvider>
                          </LocationProvider>
                        </DatesProvider>
                      </SelfProvider>
                    </AuthProvider>
                  </ModalsProvider>
                </FirebaseProvider>
              </FetchProvider>
            </ApolloProvider>
          </AnyxAuthProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </HelmetProvider>
  );
};
