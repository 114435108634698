import { forwardRef, ReactElement, Ref, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GroupBase,
  PropsValue,
  OnChangeValue,
  ActionMeta,
  AsyncPaginateSelectProps,
  SelectForwardRef,
  useAsyncPaginateMultiSelect,
} from '@any-ui-react/select';
import useAsyncEffect from 'use-async-effect';

import {
  LoadOptionDefaultOption,
  LoadOptionAdditional,
  MdAccountFilterInput,
} from '~anyx/common/graphql';
import { LazySelectV2 } from '~anyx/shared/ui';
import { BasicOption, EMPTY_DEFAULT } from '~anyx/shared/utils';

import { useAccountSelect, useAccountsSelect } from './operations';

interface AccountSelectProps<T = string, IsMulti extends boolean = boolean>
  extends Omit<
    AsyncPaginateSelectProps<
      LoadOptionDefaultOption<T>,
      GroupBase<LoadOptionDefaultOption<T>>,
      LoadOptionAdditional,
      IsMulti
    >,
    'loadOptions' | 'components' | 'value'
  > {
  filters?: MdAccountFilterInput;
  isMulti?: IsMulti;
  value?: PropsValue<LoadOptionDefaultOption<T> | T>;
  onChange?: (
    newValue: OnChangeValue<LoadOptionDefaultOption<T>, IsMulti>,
    actionMeta: ActionMeta<LoadOptionDefaultOption<T> | T>
  ) => void;
}
const AccountSelectGn = <T = string, IsMulti extends boolean = false>(
  { value: accountIds, filters, isMulti, ...rest }: AccountSelectProps<T, IsMulti>,
  ref: SelectForwardRef<LoadOptionDefaultOption<T>, IsMulti, GroupBase<LoadOptionDefaultOption<T>>>
) => {
  const arrayValue = useMemo(() => {
    const value = accountIds ? accountIds : [];
    return Array.isArray(value) ? value : [value];
  }, [accountIds]);

  const [values, setValues] = useState<BasicOption<T>[]>([]);

  const { t } = useTranslation();
  const paginateMultiSelectProps = useAsyncPaginateMultiSelect<
    LoadOptionDefaultOption<T>,
    GroupBase<LoadOptionDefaultOption<T>>
  >({
    getSelectedLabel: (selectedCount) =>
      t('shared.select.accountSelected', { ns: 'shared', amount: selectedCount }),
  });

  const { getAccountDetails } = useAccountSelect();
  const { loadOptions } = useAccountsSelect<T>({ filters });

  useAsyncEffect(
    async (isMounted) => {
      if (!arrayValue || arrayValue.length === 0) {
        setValues([]);
        return;
      }

      //TODO Currently we will show the name for one value, and X selected when more,
      //That s why we only care to load the first value name and ignore the other names
      try {
        if (!isMounted()) return;
        const accountDetails = await getAccountDetails({ accountId: arrayValue[0] });
        const displayValue = accountDetails?.name || EMPTY_DEFAULT;
        const selectedOption = arrayValue?.map((value, i) => ({
          value: value,
          label: i === 0 ? displayValue : EMPTY_DEFAULT,
        }));
        setValues(selectedOption);
      } catch (e) {
        if (!isMounted()) return;
      }
    },
    [arrayValue]
  );

  const multiProps = isMulti ? paginateMultiSelectProps : {};

  return (
    <LazySelectV2
      {...multiProps}
      className="select-has-min-w-menu [&_.any-select\_\_value-container]:flex-nowrap [&_.option-label]:truncate [&_label]:truncate"
      isClearable
      isSearchable
      // TODO this line should not be needed
      // It is currently used to redraw the component so it can trigger the query again
      // This whole MdStore/Supplier/Account selector need to be cleaned up
      key={`${JSON.stringify(filters)}`}
      {...rest}
      value={values}
      onChange={(value, meta) => {
        rest?.onChange?.(value, meta);
        if (value === null) {
          setValues([]);
        } else {
          setValues(Array.isArray(value) ? value : [value]);
        }
      }}
      ref={ref}
      loadOptions={loadOptions}
    />
  );
};

export const AccountSelect = forwardRef(AccountSelectGn) as <
  T = string,
  IsMulti extends boolean = boolean
>(
  p: AccountSelectProps<T, IsMulti> & {
    ref?: Ref<
      SelectForwardRef<LoadOptionDefaultOption<T>, IsMulti, GroupBase<LoadOptionDefaultOption<T>>>
    >;
  }
) => ReactElement;
