import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/common/graphql';

const TokopediaAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.TokopediaAddPage }))
);

const TokopediaEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.TokopediaEditPage }))
);

const TokopediaTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.TokopediaTemplate }))
);

export const tokopediaRouting = [
  {
    path: MarketplaceSaleChannelType.Tokopedia,
    element: <TokopediaTemplate />,
    children: [
      {
        index: true,
        element: <TokopediaAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            index: true,
            element: <TokopediaEditPage />,
          },
        ],
      },
    ],
  },
];
