import React from 'react';
import { RouteObject, Navigate } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { Auth0Permissions, Restricted } from '~anyx/common/permission';
import { FALLBACK_ROUTE, SupplierRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const SupplierListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.SupplierListPage }))
);
const SupplierAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.SupplierAddPage }))
);
const SupplierEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.SupplierEditPage }))
);
const SupplierListUserPage = React.lazy(() =>
  import('./pages/edit/list-user').then((module) => ({ default: module.SupplierListUserPage }))
);
const SupplierAddUserPage = React.lazy(() =>
  import('./pages/edit/add-user').then((module) => ({ default: module.SupplierAddUserPage }))
);
const SupplierEditUserPage = React.lazy(() =>
  import('./pages/edit/edit-user').then((module) => ({ default: module.SupplierEditUserPage }))
);
const SupplierTemplate = React.lazy(() =>
  import('./templates/SupplierTemplate').then((module) => ({ default: module.SupplierTemplate }))
);
const SupplierEditTemplate = React.lazy(() =>
  import('./templates/SupplierEditTemplate').then((module) => ({
    default: module.SupplierEditTemplate,
  }))
);

export const routes: RouteObject[] = [
  {
    path: 'supplier',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_INVENTORY]]}
          fallback={<UnauthorizedMessage />}
        >
          <SupplierTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <SupplierListPage />,
      },
      {
        path: 'add',
        element: <SupplierAddPage />,
      },
      {
        path: ':supplierId',
        element: <SupplierEditTemplate />,
        children: [
          {
            index: true,
            element: <SupplierEditPage />,
          },
          {
            path: 'list',
            element: <SupplierListUserPage />,
          },
          {
            path: 'add',
            element: <SupplierAddUserPage />,
          },
          {
            path: ':userId',
            element: <SupplierEditUserPage />,
          },
          { element: <Navigate to={FALLBACK_ROUTE} replace={true} /> },
        ],
      },
      {
        index: true,
        element: (
          <Navigate to={SupplierRoutePath({ workspaceStoreId: null }).list().path} replace={true} />
        ),
      },
    ],
  },
];
