import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MdAccountSelectQueryVariables = Types.Exact<{
  id: Types.Scalars['Long']['input'];
}>;


export type MdAccountSelectQuery = { MdAccount: { __typename?: 'MdAccount', id: string, name: string } };


export const MdAccountSelectDocument = gql`
    query MdAccountSelect($id: Long!) {
  MdAccount(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useMdAccountSelectQuery__
 *
 * To run a query within a React component, call `useMdAccountSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMdAccountSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMdAccountSelectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMdAccountSelectQuery(baseOptions: Apollo.QueryHookOptions<MdAccountSelectQuery, MdAccountSelectQueryVariables> & ({ variables: MdAccountSelectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MdAccountSelectQuery, MdAccountSelectQueryVariables>(MdAccountSelectDocument, options);
      }
export function useMdAccountSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MdAccountSelectQuery, MdAccountSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MdAccountSelectQuery, MdAccountSelectQueryVariables>(MdAccountSelectDocument, options);
        }
export function useMdAccountSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MdAccountSelectQuery, MdAccountSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MdAccountSelectQuery, MdAccountSelectQueryVariables>(MdAccountSelectDocument, options);
        }
export type MdAccountSelectQueryHookResult = ReturnType<typeof useMdAccountSelectQuery>;
export type MdAccountSelectLazyQueryHookResult = ReturnType<typeof useMdAccountSelectLazyQuery>;
export type MdAccountSelectSuspenseQueryHookResult = ReturnType<typeof useMdAccountSelectSuspenseQuery>;
export type MdAccountSelectQueryResult = Apollo.QueryResult<MdAccountSelectQuery, MdAccountSelectQueryVariables>;
export function refetchMdAccountSelectQuery(variables: MdAccountSelectQueryVariables) {
      return { query: MdAccountSelectDocument, variables: variables }
    }