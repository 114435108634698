import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@any-ui-react/core';

import { SaleChannelSyncStatus, Action, MarketplaceSaleChannelType } from '~anyx/common/graphql';
import { AlertBanner } from '~anyx/shared/ui';

import { ChannelUtils } from '../../utils';

import { useGetBannersInterval } from './operations';
import { clearStatus, getSaleChannelSyncStatus } from './saleChannelSync.utils';

export type SyncBannerAction = Extract<
  'INITIAL_ORDER_SYNC' | 'LISTING_SYNC' | 'INITIAL_SYNC',
  Action
>;
export type Syncs = Record<
  SyncBannerAction,
  Record<SaleChannelSyncStatus, MarketplaceSaleChannelType[]>
>;
type BannerData = {
  action: SyncBannerAction;
  status: SaleChannelSyncStatus;
  saleChannelTypes: MarketplaceSaleChannelType[];
  errors: { action: Action; code: string }[];
};

export enum BannerType {
  SUCCESS = 'success',
  PENDING = 'info',
}

export const SyncBanner = ({
  storeId,
  onlyChannel,
  actions,
  onRefetch,
}: {
  storeId?: string;
  onlyChannel?: MarketplaceSaleChannelType;
  actions: SyncBannerAction[];
  onRefetch?: () => void;
}) => {
  const { t } = useTranslation();
  const [channelSyncs, setChannelSyncs] = useState<Syncs>({
    [Action.INITIAL_SYNC]: {
      [SaleChannelSyncStatus.PENDING]: [],
      [SaleChannelSyncStatus.SUCCESS]: [],
    },
    [Action.INITIAL_ORDER_SYNC]: {
      [SaleChannelSyncStatus.PENDING]: [],
      [SaleChannelSyncStatus.SUCCESS]: [],
    },
    [Action.LISTING_SYNC]: {
      [SaleChannelSyncStatus.PENDING]: [],
      [SaleChannelSyncStatus.SUCCESS]: [],
    },
  });
  const { data } = useGetBannersInterval({ storeId });

  useEffect(() => {
    if (data?.saleChannelSyncs?.saleChannelSyncs) {
      actions.forEach((propAction) => {
        const items = data.saleChannelSyncs.saleChannelSyncs.filter((sync) => {
          switch (propAction) {
            case Action.INITIAL_SYNC:
              return sync.action === Action.INITIAL_SYNC;
            case Action.LISTING_SYNC:
              return sync.action === Action.LISTING_SYNC;
            case Action.INITIAL_ORDER_SYNC:
              return (
                sync.action === Action.INITIAL_SYNC || sync.action === Action.INITIAL_ORDER_SYNC
              );
            default:
              return false;
          }
        });

        if (items.length) {
          setChannelSyncs((prevState) => getSaleChannelSyncStatus(prevState, propAction, items));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const banners = useMemo(() => {
    const items = (Object.keys(channelSyncs) as SyncBannerAction[]).reduce<BannerData[]>(
      (acc, action) => {
        const innerReduced = (Object.keys(channelSyncs[action]) as SaleChannelSyncStatus[]).reduce<
          BannerData[]
        >((innerAcc, status) => {
          return [
            ...innerAcc,
            {
              action,
              status,
              saleChannelTypes: channelSyncs[action][status],
              errors:
                (data?.saleChannelSyncs.saleChannelSyncs || []).find(
                  ({ action: dAction }) => dAction === action
                )?.errors || [],
            } as BannerData,
          ];
        }, []);
        return [...acc, ...innerReduced];
      },
      []
    );

    return items.sort((a, b) => a.status.localeCompare(b.status));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelSyncs]);

  return (
    <>
      {banners.map(({ action, status, saleChannelTypes }) => {
        const hasRefetch = status === SaleChannelSyncStatus.SUCCESS;

        if (!saleChannelTypes.length || (onlyChannel && !saleChannelTypes.includes(onlyChannel))) {
          return null;
        }

        return (
          <AlertBanner
            key={`${action}${status}`}
            type={BannerType[status]}
            title={t('marketplace.page.order.list.banners.sync.title', {
              ns: 'marketplace',
              status,
              action,
              channels: ChannelUtils.sortChannels({ channels: saleChannelTypes })
                .map((channel) => t(`gql.enum.channel`, { ns: `gql`, channel }))
                .join(', '),
            })}
            classNames={{
              root: 'mb-4 shadow-banner',
            }}
            withCloseButton={hasRefetch}
            onClose={() => setChannelSyncs((prevState) => clearStatus(prevState, status, action))}
          >
            <>
              <p>
                {t('marketplace.page.order.list.banners.sync.description', {
                  ns: 'marketplace',
                  status,
                  action,
                  channels: ChannelUtils.sortChannels({ channels: saleChannelTypes })
                    .map((channel) => t(`gql.enum.channel`, { ns: `gql`, channel }))
                    .join(', '),
                })}
              </p>
              {hasRefetch && onRefetch && (
                <Button
                  variant="default"
                  onClick={() => {
                    onRefetch();
                    setChannelSyncs((prevState) => clearStatus(prevState, status, action));
                  }}
                >
                  {t('shared.button.reload', {
                    ns: 'shared',
                  })}
                </Button>
              )}
            </>
          </AlertBanner>
        );
      })}
    </>
  );
};
