import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopifySalesReportSummaryQueryVariables = Types.Exact<{
  input: Types.ShopifySalesReportSummaryInput;
}>;


export type ShopifySalesReportSummaryQuery = { readonly shopifySalesReportSummary: { readonly __typename?: 'ShopifySalesReportSummaryPayload', readonly currency: Types.ReportSupportedCurrency, readonly discounts: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly grossSales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly netSales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly orderCount: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly returns: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly shipping: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly taxes: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly totalSales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string } } };


export const ShopifySalesReportSummaryDocument = gql`
    query ShopifySalesReportSummary($input: ShopifySalesReportSummaryInput!) {
  shopifySalesReportSummary(input: $input) {
    currency
    discounts {
      diff
      diffPercentage
      value
    }
    grossSales {
      diff
      diffPercentage
      value
    }
    netSales {
      diff
      diffPercentage
      value
    }
    orderCount {
      diff
      diffPercentage
      value
    }
    returns {
      diff
      diffPercentage
      value
    }
    shipping {
      diff
      diffPercentage
      value
    }
    taxes {
      diff
      diffPercentage
      value
    }
    totalSales {
      diff
      diffPercentage
      value
    }
  }
}
    `;

/**
 * __useShopifySalesReportSummaryQuery__
 *
 * To run a query within a React component, call `useShopifySalesReportSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifySalesReportSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifySalesReportSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopifySalesReportSummaryQuery(baseOptions: Apollo.QueryHookOptions<ShopifySalesReportSummaryQuery, ShopifySalesReportSummaryQueryVariables> & ({ variables: ShopifySalesReportSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopifySalesReportSummaryQuery, ShopifySalesReportSummaryQueryVariables>(ShopifySalesReportSummaryDocument, options);
      }
export function useShopifySalesReportSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopifySalesReportSummaryQuery, ShopifySalesReportSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopifySalesReportSummaryQuery, ShopifySalesReportSummaryQueryVariables>(ShopifySalesReportSummaryDocument, options);
        }
export function useShopifySalesReportSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShopifySalesReportSummaryQuery, ShopifySalesReportSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShopifySalesReportSummaryQuery, ShopifySalesReportSummaryQueryVariables>(ShopifySalesReportSummaryDocument, options);
        }
export type ShopifySalesReportSummaryQueryHookResult = ReturnType<typeof useShopifySalesReportSummaryQuery>;
export type ShopifySalesReportSummaryLazyQueryHookResult = ReturnType<typeof useShopifySalesReportSummaryLazyQuery>;
export type ShopifySalesReportSummarySuspenseQueryHookResult = ReturnType<typeof useShopifySalesReportSummarySuspenseQuery>;
export type ShopifySalesReportSummaryQueryResult = Apollo.QueryResult<ShopifySalesReportSummaryQuery, ShopifySalesReportSummaryQueryVariables>;
export function refetchShopifySalesReportSummaryQuery(variables: ShopifySalesReportSummaryQueryVariables) {
      return { query: ShopifySalesReportSummaryDocument, variables: variables }
    }