import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { usePwaStore } from '~anyx/shared/utils';

export const PwaNotificationBanner = () => {
  const hasUpdate = usePwaStore((state) => state.hasUpdate);
  const [route, setRoute] = useState('');
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (hasUpdate && !route) {
      setRoute(location.pathname);
    }
  }, [hasUpdate, location, route]);

  useEffect(() => {
    if (route && location.pathname) {
      window.location.reload();
    }
  }, [location, route]);

  return hasUpdate ? (
    <div className="absolute bottom-0 left-0 z-10">
      {t('shared.app.updateInProgress', { ns: 'shared' })}
    </div>
  ) : null;
};
