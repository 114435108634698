import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TokopediaSalesChartQueryVariables = Types.Exact<{
  input: Types.TokopediaSalesChartInput;
}>;


export type TokopediaSalesChartQuery = { readonly tokopediaSalesChart: { readonly __typename?: 'TokopediaSalesChartPayload', readonly currency: Types.ReportSupportedCurrency, readonly orders: ReadonlyArray<Record<string, any>>, readonly sales: ReadonlyArray<Record<string, any>> } };


export const TokopediaSalesChartDocument = gql`
    query TokopediaSalesChart($input: TokopediaSalesChartInput!) {
  tokopediaSalesChart(input: $input) {
    currency
    orders
    sales
  }
}
    `;

/**
 * __useTokopediaSalesChartQuery__
 *
 * To run a query within a React component, call `useTokopediaSalesChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokopediaSalesChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokopediaSalesChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTokopediaSalesChartQuery(baseOptions: Apollo.QueryHookOptions<TokopediaSalesChartQuery, TokopediaSalesChartQueryVariables> & ({ variables: TokopediaSalesChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokopediaSalesChartQuery, TokopediaSalesChartQueryVariables>(TokopediaSalesChartDocument, options);
      }
export function useTokopediaSalesChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokopediaSalesChartQuery, TokopediaSalesChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokopediaSalesChartQuery, TokopediaSalesChartQueryVariables>(TokopediaSalesChartDocument, options);
        }
export function useTokopediaSalesChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokopediaSalesChartQuery, TokopediaSalesChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokopediaSalesChartQuery, TokopediaSalesChartQueryVariables>(TokopediaSalesChartDocument, options);
        }
export type TokopediaSalesChartQueryHookResult = ReturnType<typeof useTokopediaSalesChartQuery>;
export type TokopediaSalesChartLazyQueryHookResult = ReturnType<typeof useTokopediaSalesChartLazyQuery>;
export type TokopediaSalesChartSuspenseQueryHookResult = ReturnType<typeof useTokopediaSalesChartSuspenseQuery>;
export type TokopediaSalesChartQueryResult = Apollo.QueryResult<TokopediaSalesChartQuery, TokopediaSalesChartQueryVariables>;
export function refetchTokopediaSalesChartQuery(variables: TokopediaSalesChartQueryVariables) {
      return { query: TokopediaSalesChartDocument, variables: variables }
    }