import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { ReportChartTab, ReportSupportedCurrency, SaleChannelType } from '~anyx/common/graphql';
import { ChannelUtils } from '~anyx/feature/marketplace';
import {
  NoResult,
  NumberFormat,
  PieChart,
  SkeletonChartWrapper,
  SkeletonLoader,
} from '~anyx/shared/ui';
import { FilterProps, NumberUtils } from '~anyx/shared/utils';

import { DdiHomeReportPieChartData } from '../../type';
import { DdiHomeFilterInputFilterType } from '../home-filters';
import { NoSalesChannelConnection } from '../no-sales-channel-connection';

export interface DdiPieChartProps {
  filters: FilterProps<DdiHomeFilterInputFilterType>;
  shouldShowMock: boolean;
  colors: Record<string, string>;
  dataKeys: Array<string | SaleChannelType>;
  loading?: boolean;
  data?: DdiHomeReportPieChartData;
  currency?: ReportSupportedCurrency;
}

type DdiPieData = {
  name: string;
  key: string | null;
  storeId?: string | null;
  value: number;
  numberFormat: NumberFormat;
  currency: ReportSupportedCurrency;
  channel?: SaleChannelType | null;
};

export const DdiSalesChannelPieChart = ({
  loading,
  shouldShowMock,
  data,
  colors,
  dataKeys,
  currency = ReportSupportedCurrency.USD,
}: DdiPieChartProps) => {
  const { t } = useTranslation();

  const channelPieData = useMemo(
    () =>
      ChannelUtils.sortChannels({
        channels: data?.grossSales || [],
        byField: 'saleChannelType',
      }).map((item) => {
        return {
          name: t('core.page.home.report.table.column.grossSales', { ns: 'core' }),
          key: t('gql.enum.channel', { ns: 'gql', channel: item.saleChannelType }),
          storeId: null,
          value: NumberUtils.toNumber(item.value),
          numberFormat: NumberFormat.PRICE,
          channel: item.saleChannelType,
          currency,
        };
      }),
    [currency, data, t]
  );

  const pieData: DdiPieData[] = (channelPieData || []).map((item) => {
    return {
      name: item.name,
      key: item.key,
      storeId: item.storeId,
      value: item.value,
      numberFormat: item.numberFormat,
      currency: item.currency,
      channel: item.channel,
    };
  });
  return (
    <div
      data-test-id="report-sales-channel-pie-chart"
      className="relative flex h-full flex-col justify-between rounded-none lg:rounded"
    >
      <div className={classNames({ 'blur-sm': shouldShowMock })}>
        <div className="px-4 pt-4">
          <div className="mb-4 items-center">
            <SkeletonLoader loading={loading}>
              <div className="mr-4 font-semibold">
                {t('core.page.home.report.chart.pieChart.title', {
                  ns: 'core',
                  chartTab: ReportChartTab.STORE,
                })}
              </div>
            </SkeletonLoader>
          </div>
          <SkeletonChartWrapper
            error={!data?.grossSales.length}
            loading={loading}
            errorNode={
              <NoResult
                wrapperClassName="py-20 my-4 text-center !gap-2"
                titleClassName="text-sm"
                descriptionClassName="lg:!text-2xs"
                title={t('shared.table.empty', {
                  ns: 'shared',
                  element: t('shared.entity.data', { ns: 'shared', count: 1 }),
                })}
                description={t('shared.chart.guideline', { ns: 'shared' })}
              />
            }
          >
            <div className="h-64">
              <PieChart
                colors={colors}
                data={pieData}
                dataKeys={dataKeys}
                innerRadius={35}
                outerRadius={60}
              />
            </div>
          </SkeletonChartWrapper>
        </div>
      </div>
      {shouldShowMock && <NoSalesChannelConnection />}
    </div>
  );
};
