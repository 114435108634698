import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HasLogisticsChannelQueryVariables = Types.Exact<{
  storeId?: Types.InputMaybe<Types.Scalars['Long']['input']>;
}>;


export type HasLogisticsChannelQuery = { hasLogisticsChannel: { __typename?: 'HasLogisticsChannel', boolean: boolean } };


export const HasLogisticsChannelDocument = gql`
    query HasLogisticsChannel($storeId: Long) {
  hasLogisticsChannel(storeId: $storeId) {
    boolean
  }
}
    `;

/**
 * __useHasLogisticsChannelQuery__
 *
 * To run a query within a React component, call `useHasLogisticsChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasLogisticsChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasLogisticsChannelQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useHasLogisticsChannelQuery(baseOptions?: Apollo.QueryHookOptions<HasLogisticsChannelQuery, HasLogisticsChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasLogisticsChannelQuery, HasLogisticsChannelQueryVariables>(HasLogisticsChannelDocument, options);
      }
export function useHasLogisticsChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasLogisticsChannelQuery, HasLogisticsChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasLogisticsChannelQuery, HasLogisticsChannelQueryVariables>(HasLogisticsChannelDocument, options);
        }
export function useHasLogisticsChannelSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HasLogisticsChannelQuery, HasLogisticsChannelQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HasLogisticsChannelQuery, HasLogisticsChannelQueryVariables>(HasLogisticsChannelDocument, options);
        }
export type HasLogisticsChannelQueryHookResult = ReturnType<typeof useHasLogisticsChannelQuery>;
export type HasLogisticsChannelLazyQueryHookResult = ReturnType<typeof useHasLogisticsChannelLazyQuery>;
export type HasLogisticsChannelSuspenseQueryHookResult = ReturnType<typeof useHasLogisticsChannelSuspenseQuery>;
export type HasLogisticsChannelQueryResult = Apollo.QueryResult<HasLogisticsChannelQuery, HasLogisticsChannelQueryVariables>;
export function refetchHasLogisticsChannelQuery(variables?: HasLogisticsChannelQueryVariables) {
      return { query: HasLogisticsChannelDocument, variables: variables }
    }