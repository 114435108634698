import React from 'react';
import { RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { CrossBorderCourierProvider } from '~anyx/common/graphql';

const FedexEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.FedexEditPage }))
);

const FedexAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.FedexAddPage }))
);

const FedexTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.FedexTemplate }))
);

export const fedexRoutes: RouteObject[] = [
  {
    path: CrossBorderCourierProvider.FED_EX,
    element: (
      <Auth0Guard>
        <FedexTemplate />
      </Auth0Guard>
    ),
    children: [
      { index: true, element: <FedexAddPage /> },
      {
        path: ':courierId',
        element: <FedexEditPage />,
      },
    ],
  },
];
