import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TikTokSalesReportSummaryQueryVariables = Types.Exact<{
  input: Types.TikTokSalesReportSummaryInput;
}>;


export type TikTokSalesReportSummaryQuery = { readonly tikTokSalesReportSummary: { readonly __typename?: 'TikTokSalesReportSummaryPayload', readonly currency: Types.ReportSupportedCurrency, readonly itemsOrdered: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly sales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly unitsOrdered: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string } } };


export const TikTokSalesReportSummaryDocument = gql`
    query TikTokSalesReportSummary($input: TikTokSalesReportSummaryInput!) {
  tikTokSalesReportSummary(input: $input) {
    currency
    itemsOrdered {
      diff
      diffPercentage
      value
    }
    sales {
      diff
      diffPercentage
      value
    }
    unitsOrdered {
      diff
      diffPercentage
      value
    }
  }
}
    `;

/**
 * __useTikTokSalesReportSummaryQuery__
 *
 * To run a query within a React component, call `useTikTokSalesReportSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTikTokSalesReportSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTikTokSalesReportSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTikTokSalesReportSummaryQuery(baseOptions: Apollo.QueryHookOptions<TikTokSalesReportSummaryQuery, TikTokSalesReportSummaryQueryVariables> & ({ variables: TikTokSalesReportSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TikTokSalesReportSummaryQuery, TikTokSalesReportSummaryQueryVariables>(TikTokSalesReportSummaryDocument, options);
      }
export function useTikTokSalesReportSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TikTokSalesReportSummaryQuery, TikTokSalesReportSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TikTokSalesReportSummaryQuery, TikTokSalesReportSummaryQueryVariables>(TikTokSalesReportSummaryDocument, options);
        }
export function useTikTokSalesReportSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TikTokSalesReportSummaryQuery, TikTokSalesReportSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TikTokSalesReportSummaryQuery, TikTokSalesReportSummaryQueryVariables>(TikTokSalesReportSummaryDocument, options);
        }
export type TikTokSalesReportSummaryQueryHookResult = ReturnType<typeof useTikTokSalesReportSummaryQuery>;
export type TikTokSalesReportSummaryLazyQueryHookResult = ReturnType<typeof useTikTokSalesReportSummaryLazyQuery>;
export type TikTokSalesReportSummarySuspenseQueryHookResult = ReturnType<typeof useTikTokSalesReportSummarySuspenseQuery>;
export type TikTokSalesReportSummaryQueryResult = Apollo.QueryResult<TikTokSalesReportSummaryQuery, TikTokSalesReportSummaryQueryVariables>;
export function refetchTikTokSalesReportSummaryQuery(variables: TikTokSalesReportSummaryQueryVariables) {
      return { query: TikTokSalesReportSummaryDocument, variables: variables }
    }