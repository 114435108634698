import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button, TextInput } from '@any-ui-react/core';
import { vestResolver } from '@hookform/resolvers/vest';

import { useAuthCtx } from '~anyx/common/auth';
import { MdUserRole } from '~anyx/common/graphql';
import { Restricted } from '~anyx/common/permission';
import { useSelfCtx } from '~anyx/common/self';
import { ConnectFirstBrandIcon } from '~anyx/shared/icons';
import { PageLayout, UnavailableMessage } from '~anyx/shared/ui';

import { useWorkspaceHomepage } from '../../../../hooks';

import { CreateStoreFormData, validationSuite } from './onboardingPage.validator';
import { useOnboardingCreateStore } from './operation';

export const OnboardingPage = () => {
  const {
    self: { accountIds, role },
  } = useSelfCtx();
  const { t } = useTranslation();
  const { homepageLink } = useWorkspaceHomepage();
  const navigate = useNavigate();
  const { logout } = useAuthCtx();
  const { handleCreateStore, creating } = useOnboardingCreateStore();
  const defaultValues: CreateStoreFormData = {
    accountId: '',
    name: '',
  };

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm<CreateStoreFormData>({
    defaultValues,
    resolver: vestResolver(validationSuite),
  });

  useEffect(() => {
    setValue('accountId', accountIds[0] || '');
  }, [accountIds, role, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    const result = await handleCreateStore(data);

    const storeId = result?.MdCreateStore?.id;
    if (storeId) {
      navigate(homepageLink);
    }
  });

  return (
    <PageLayout withNavigation={false}>
      <Restricted
        roles={[MdUserRole.MERCHANT, MdUserRole.STAFF]}
        fallback={<UnavailableMessage action="retry" />}
      >
        <div className="lg:py-42 mx-auto flex flex-col items-center px-4 lg:px-48">
          <ConnectFirstBrandIcon className="h-auto w-48 lg:w-72" />
          <div className="text-lg lg:mt-8">
            {t('core.page.onboarding.landing.title', { ns: 'core' })}
          </div>
          <div className="text-gray-7 mt-4 text-center">
            {t('core.page.onboarding.landing.description', { ns: 'core' })}
          </div>
          <div className="mt-4">{t('core.page.onboarding.landing.guideline', { ns: 'core' })}</div>
          <div className="mt-4 flex items-center">
            <form
              noValidate
              className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6"
              onSubmit={onSubmit}
            >
              <div className="col-span-1">
                <TextInput
                  {...register('name')}
                  placeholder={t('core.page.onboarding.landing.placeholder', { ns: 'core' })}
                  error={errors.name?.message}
                />
              </div>
              <div className="col-span-1">
                <Button className="w-full" type="submit" loading={creating}>
                  {t('core.page.onboarding.landing.button', { ns: 'core' })}
                </Button>
              </div>
            </form>
          </div>
          <div className="text-gray-7 mt-4 w-full text-left">
            {t('core.page.onboarding.landing.changeAccount', { ns: 'core' })}
            <button className="ml-2 underline" onClick={() => logout()}>
              {t('shared.button.logout', { ns: 'shared' })}
            </button>
          </div>
        </div>
      </Restricted>
    </PageLayout>
  );
};
