import { t } from 'i18next';
import { create, enforce, test } from 'vest';

import { MdCreateStoreInput } from '~anyx/common/graphql';

export type CreateStoreFormData = MdCreateStoreInput;

export const validationSuite = create(({ accountId, name }: CreateStoreFormData) => {
  test(
    'masterDataAccountId',
    t('shared.form.validation.error.required', {
      ns: 'shared',
      field: t('shared.field.account.name', { ns: 'shared' }),
    }),
    () => {
      enforce(accountId).isNotEmpty();
    }
  );

  test(
    'name',
    t('shared.form.validation.error.required', {
      ns: 'shared',
      field: t('shared.field.name.name', { ns: 'shared' }),
    }),
    () => {
      enforce(name).isNotEmpty();
    }
  );
});
