import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HomeReportChartQueryVariables = Types.Exact<{
  filter: Types.SaleChannelReportTableFilterInput;
}>;


export type HomeReportChartQuery = { saleChannelReportChart: { __typename?: 'SaleChannelReportChartPayload', channels: Array<Types.SaleChannelType>, currency: Types.ReportSupportedCurrency, totalItems: number, list: Array<{ __typename?: 'SaleChannelReportChartData', advertisingCost: string, advertisingRevenue: string, avgOrderValues: string, channel?: Types.SaleChannelType | null, commissionFee: string, cvr?: string | null, dailySales?: string | null, day?: any | null, discountRate: string, discounts: string, dow?: number | null, grossProfit: string, grossProfitRate: string, grossSales: string, netSales: string, totalSales: string, cancelAndOrRefund: string, logisticsCost: string, marginalProfit: string, marginalProfitRate: string, masterDataStoreId?: string | null, merchandiseCost: string, orders: string, otherCost: string, roas: string, sessions: string, shippingFee: string, storeName?: string | null, yearMonth?: any | null, yearWeek?: any | null, marketplaceCalendarEvents?: Array<{ __typename?: 'MarketplaceCalendarEventPayload', endDate: string, id: string, masterDataStoreId?: string | null, saleChannelCountry?: string | null, saleChannelType: Types.SaleChannelType, startDate: string, title: string, target?: { __typename?: 'MarketplaceCalendarEventTargetPayload', currency: Types.ReportSupportedCurrency, revenue?: string | null } | null }> | null }>, stores: Array<{ __typename?: 'SaleChannelReportChartStore', id: string, name: string }> } };


export const HomeReportChartDocument = gql`
    query HomeReportChart($filter: SaleChannelReportTableFilterInput!) {
  saleChannelReportChart(filter: $filter) {
    channels
    currency
    list {
      advertisingCost
      advertisingRevenue
      avgOrderValues
      channel
      commissionFee
      cvr
      dailySales
      day
      discountRate
      discounts
      dow
      grossProfit
      grossProfitRate
      grossSales
      netSales
      totalSales
      cancelAndOrRefund
      logisticsCost
      marginalProfit
      marginalProfitRate
      marketplaceCalendarEvents {
        endDate
        id
        masterDataStoreId
        saleChannelCountry
        saleChannelType
        startDate
        target {
          currency
          revenue
        }
        title
      }
      masterDataStoreId
      merchandiseCost
      orders
      otherCost
      roas
      sessions
      shippingFee
      storeName
      yearMonth
      yearWeek
    }
    stores {
      id
      name
    }
    totalItems
  }
}
    `;

/**
 * __useHomeReportChartQuery__
 *
 * To run a query within a React component, call `useHomeReportChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeReportChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeReportChartQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHomeReportChartQuery(baseOptions: Apollo.QueryHookOptions<HomeReportChartQuery, HomeReportChartQueryVariables> & ({ variables: HomeReportChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeReportChartQuery, HomeReportChartQueryVariables>(HomeReportChartDocument, options);
      }
export function useHomeReportChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeReportChartQuery, HomeReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeReportChartQuery, HomeReportChartQueryVariables>(HomeReportChartDocument, options);
        }
export function useHomeReportChartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HomeReportChartQuery, HomeReportChartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeReportChartQuery, HomeReportChartQueryVariables>(HomeReportChartDocument, options);
        }
export type HomeReportChartQueryHookResult = ReturnType<typeof useHomeReportChartQuery>;
export type HomeReportChartLazyQueryHookResult = ReturnType<typeof useHomeReportChartLazyQuery>;
export type HomeReportChartSuspenseQueryHookResult = ReturnType<typeof useHomeReportChartSuspenseQuery>;
export type HomeReportChartQueryResult = Apollo.QueryResult<HomeReportChartQuery, HomeReportChartQueryVariables>;
export function refetchHomeReportChartQuery(variables: HomeReportChartQueryVariables) {
      return { query: HomeReportChartDocument, variables: variables }
    }