import { useTranslation } from 'react-i18next';

import { Select, SelectProps } from '@any-ui-react/select';

import { DdiWarehouse } from '~anyx/common/graphql';
import { ObjectUtils, ReactNodeOption } from '~anyx/shared/utils';

export const WarehouseSelect = ({
  onChange,
  value,
  clearable = true,
  ...rest
}: {
  onChange: (value: DdiWarehouse) => void;
  value?: DdiWarehouse;
  clearable?: boolean;
  rest?: SelectProps<ReactNodeOption<DdiWarehouse>>;
}) => {
  const { t } = useTranslation();
  const warehouseOptions = ObjectUtils.arrayFromEnum<DdiWarehouse>(DdiWarehouse);
  return (
    <Select
      isSearchable
      isClearable={clearable}
      className="whitespace-nowrap"
      onChange={(option) => {
        if (option?.value) {
          onChange(option.value);
        }
      }}
      value={value}
      options={warehouseOptions.map((warehouse) => ({
        label: t('gql.enum.ddiWarehouse', {
          ns: 'gql',
          warehouse,
        }),
        value: warehouse,
      }))}
      placeholder={t('shared.entity.warehouse', { ns: 'shared', count: 1 })}
      {...rest}
    />
  );
};
