import { z } from 'zod';

import { DdiHomeReportInput, SaleChannelType } from '~anyx/common/graphql';
import { DdiHomeMetricOption } from '~anyx/feature/report';
import { DateUtils } from '~anyx/shared/utils';

type Constraints = DdiHomeReportInput & {
  metric: DdiHomeMetricOption;
};

export const DdiHomePageFiltersParser = z.object({
  endDate: z.string().catch(DateUtils.toISOString(new Date(), { endOf: 'day' })),
  startDate: z.string().catch(DateUtils.toISOString(new Date(), { startOf: 'day' })),
  saleChannelTypes: z.array(z.nativeEnum(SaleChannelType)).catch([]),
  masterDataStoreIds: z.array(z.string()).catch([]),
  metric: z.nativeEnum(DdiHomeMetricOption).catch(DdiHomeMetricOption.GROSS_SALES),
}) satisfies z.ZodType<Constraints, z.ZodTypeDef, unknown>;

export type DdiHomeFilterInputFilterType = z.infer<typeof DdiHomePageFiltersParser>;
