import React from 'react';
import { RouteObject } from 'react-router';

const WhatsappBroadcastListPage = React.lazy(() => import('./pages/list'));
const WhatsappBroadcastAddPage = React.lazy(() => import('./pages/add'));
const WhatsappBroadcastEditPage = React.lazy(() => import('./pages/edit'));
const WhatsappBroadcastConnectWhatsapp = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.WhatsappBroadcastConnectWhatsapp }))
);

export const routes: RouteObject[] = [
  {
    path: 'broadcast',
    element: <WhatsappBroadcastConnectWhatsapp />,
    children: [
      {
        index: true,
        element: <WhatsappBroadcastListPage />,
      },
      {
        path: 'add',
        element: <WhatsappBroadcastAddPage />,
      },
      {
        path: ':broadcastId',
        element: <WhatsappBroadcastEditPage />,
      },
    ],
  },
];
