import { ChatType } from '~anyx/common/graphql';
import { CrmRoutePaths } from '~anyx/common/routing';
import { ChevronRightIcon, CrmIcon } from '~anyx/shared/icons';
import { RouteLink, RouteLinks, RouteMenu } from '~anyx/shared/utils';

import { LinkAdapter } from '../types';

import { AnyChatUnreadBadge } from './anychat-unread-badge';

type BuildCrmProperties = {
  storeId?: string;
  isCrmEnabled?: boolean;
  chats: ChatType[];
};

export type WithWorkspaceCrm = BuildCrmProperties & {
  canUseCrm: boolean;
};

const buildCustomerLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.customer',
    options: { count: 2, ns: 'shared' },
  },
  path: CrmRoutePaths(storeId).customer().path,
  activePath: CrmRoutePaths().customer().path,
});

const buildTemplateLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.template',
    options: { count: 2, ns: 'shared' },
  },
  path: CrmRoutePaths(storeId).template().path,
  activePath: CrmRoutePaths().template().path,
});

const buildBroadcastLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.broadcast',
    options: { count: 2, ns: 'shared' },
  },
  path: CrmRoutePaths(storeId).line().broadcast().path,
  activePath: CrmRoutePaths().line().broadcast().path,
});

const buildRichMenuLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.richMenu',
    options: { count: 2, ns: 'shared' },
  },
  path: CrmRoutePaths(storeId).line().richMenu().path,
  activePath: CrmRoutePaths().line().richMenu().path,
});

const buildGreetingMessageLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.greetingMessage',
    options: { count: 1, ns: 'shared' },
  },
  path: CrmRoutePaths(storeId).line().greetingMessage().path,
  activePath: CrmRoutePaths().line().greetingMessage().path,
});

const buildAutomationLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.automation',
    options: { count: 2, ns: 'shared' },
  },
  path: CrmRoutePaths(storeId).instagram().automation().path,
  activePath: CrmRoutePaths().instagram().automation().path,
});

const buildMessengerOptInLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.optInMessage',
    options: { count: 1, ns: 'shared' },
  },
  path: CrmRoutePaths(storeId).messenger().optIn().path,
  activePath: CrmRoutePaths().messenger().optIn().path,
});

const buildMessengerBroadcastLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.broadcast',
    options: { count: 2, ns: 'shared' },
  },
  path: CrmRoutePaths(storeId).messenger().broadcast().path,
  activePath: CrmRoutePaths().messenger().broadcast().path,
});

const buildWhatsappBroadcastLink = (storeId?: string): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.broadcast',
    options: { count: 2, ns: 'shared' },
  },
  path: CrmRoutePaths(storeId).whatsapp().broadcast().path,
  activePath: CrmRoutePaths().whatsapp().broadcast().path,
});

const buildLineRouteMenu = (storeId?: string): RouteMenu => ({
  type: 'menu',
  name: {
    key: 'gql.enum.crmChannel',
    options: { channel: ChatType.LINE, ns: 'gql' },
  },
  aside: <ChevronRightIcon className="text-gray-7 h-4 w-4" />,
  activePath: CrmRoutePaths().line().path,
  path: CrmRoutePaths(storeId).line().path,
  children: [
    buildGreetingMessageLink(storeId),
    buildRichMenuLink(storeId),
    buildBroadcastLink(storeId),
  ],
});

const buildInstagramRouteMenu = (storeId?: string): RouteMenu => ({
  type: 'menu',
  name: {
    key: 'gql.enum.crmChannel',
    options: { channel: ChatType.INSTAGRAM, ns: 'gql' },
  },
  aside: <ChevronRightIcon className="text-gray-7 h-4 w-4" />,
  activePath: CrmRoutePaths().instagram().path,
  path: CrmRoutePaths(storeId).instagram().path,
  children: [buildAutomationLink(storeId)],
});

const buildMessengerRouteMenu = (storeId?: string): RouteMenu => {
  return {
    type: 'menu',
    name: {
      key: 'gql.enum.crmChannel',
      options: { channel: ChatType.MESSENGER, ns: 'gql' },
    },
    aside: <ChevronRightIcon className="text-gray-7 h-4 w-4" />,
    activePath: CrmRoutePaths().messenger().path,
    path: CrmRoutePaths(storeId).messenger().path,
    children: [buildMessengerOptInLink(storeId), buildMessengerBroadcastLink(storeId)],
  };
};

const buildWhatsappRouteMenu = (storeId?: string): RouteMenu => {
  return {
    type: 'menu',
    name: {
      key: 'gql.enum.crmChannel',
      options: { channel: ChatType.WHATSAPP, ns: 'gql' },
    },
    aside: <ChevronRightIcon className="text-gray-7 h-4 w-4" />,
    activePath: CrmRoutePaths().whatsapp().path,
    path: CrmRoutePaths(storeId).whatsapp().path,
    children: [buildWhatsappBroadcastLink(storeId)],
  };
};

const buildShopeeRouteMenu = (storeId?: string): RouteMenu => {
  return {
    type: 'menu',
    name: {
      key: 'gql.enum.crmChannel',
      options: { channel: ChatType.SHOPEE, ns: 'gql' },
    },
    activePath: CrmRoutePaths().shopee().path,
    path: CrmRoutePaths(storeId).shopee().path,
  };
};

const buildLazadaRouteMenu = (storeId?: string): RouteMenu => {
  return {
    type: 'menu',
    name: {
      key: 'gql.enum.crmChannel',
      options: { channel: ChatType.LAZADA, ns: 'gql' },
    },
    activePath: CrmRoutePaths().lazada().path,
    path: CrmRoutePaths(storeId).lazada().path,
  };
};

const buildTiktokShopRouteMenu = (storeId?: string): RouteMenu => {
  return {
    type: 'menu',
    name: {
      key: 'gql.enum.crmChannel',
      options: { channel: ChatType.TIKTOK_SHOP, ns: 'gql' },
    },
    activePath: CrmRoutePaths()['tiktok-shop']().path,
    path: CrmRoutePaths(storeId)['tiktok-shop']().path,
  };
};

const buildCrmRootLink = ({ storeId, isCrmEnabled }: BuildCrmProperties): RouteLink => ({
  type: 'link',
  name: {
    key: 'shared.entity.crm',
    options: { count: 2, ns: 'shared' },
  },
  icon: (props) => <CrmIcon {...props} />,
  aside: isCrmEnabled && <AnyChatUnreadBadge storeId={storeId} />,
  path: isCrmEnabled ? CrmRoutePaths(storeId).chat().path : CrmRoutePaths(storeId).welcome().path,
  activePath: CrmRoutePaths().home().path,
});

/*
 *
 * Get Crm Links when the workspace is selected
 * */
const getCrmWithWorkspaceLinks = ({
  isCrmEnabled,
  chats,
  storeId,
  canUseCrm,
}: WithWorkspaceCrm): RouteLinks[] => {
  if (!canUseCrm) return [];

  const crmRootLink = buildCrmRootLink({ storeId, isCrmEnabled, chats });

  const crmChildren: Array<RouteLink | RouteMenu> = [
    buildCustomerLink(storeId),
    buildTemplateLink(storeId),
    ...(chats.includes(ChatType.LINE) ? [buildLineRouteMenu(storeId)] : []),
    ...(chats.includes(ChatType.INSTAGRAM) ? [buildInstagramRouteMenu(storeId)] : []),
    ...(chats.includes(ChatType.MESSENGER) ? [buildMessengerRouteMenu(storeId)] : []),
    ...(chats.includes(ChatType.WHATSAPP) ? [buildWhatsappRouteMenu(storeId)] : []),
    ...(chats.includes(ChatType.SHOPEE) ? [buildShopeeRouteMenu(storeId)] : []),
    ...(chats.includes(ChatType.LAZADA) ? [buildLazadaRouteMenu(storeId)] : []),
    ...(chats.includes(ChatType.TIKTOK_SHOP) ? [buildTiktokShopRouteMenu(storeId)] : []),
  ];

  return [
    {
      category: {
        ...crmRootLink,
        children: isCrmEnabled ? crmChildren : [],
      },
    },
  ];
};

type CrmLinks = LinkAdapter<WithWorkspaceCrm>;

export const crmLinks: CrmLinks = {
  noWorkspace: () => [],
  withWorkspace: getCrmWithWorkspaceLinks,
};
