import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopeeSalesDetailedReportQueryVariables = Types.Exact<{
  input: Types.ShopeeSalesDetailedReportInput;
}>;


export type ShopeeSalesDetailedReportQuery = { readonly shopeeSalesDetailedReport: { readonly __typename?: 'ShopeeSalesDetailedReportPayloadList', readonly items: ReadonlyArray<{ readonly __typename?: 'ShopeeSalesDetailedReportPayload', readonly currency: Types.ReportSupportedCurrency, readonly date: any, readonly orders: string, readonly sales: string, readonly salesPerOrder: string, readonly visitors: string }>, readonly meta: { readonly __typename?: 'ReportListMeta', readonly length: number, readonly limit: number, readonly start: number, readonly total: number }, readonly total: { readonly __typename?: 'ShopeeSalesDetailedReportTotalPayload', readonly currency: Types.ReportSupportedCurrency, readonly orders: string, readonly sales: string, readonly salesPerOrder: string, readonly visitors: string } } };


export const ShopeeSalesDetailedReportDocument = gql`
    query ShopeeSalesDetailedReport($input: ShopeeSalesDetailedReportInput!) {
  shopeeSalesDetailedReport(input: $input) {
    items {
      currency
      date
      orders
      sales
      salesPerOrder
      visitors
    }
    meta {
      length
      limit
      start
      total
    }
    total {
      currency
      orders
      sales
      salesPerOrder
      visitors
    }
  }
}
    `;

/**
 * __useShopeeSalesDetailedReportQuery__
 *
 * To run a query within a React component, call `useShopeeSalesDetailedReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopeeSalesDetailedReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopeeSalesDetailedReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopeeSalesDetailedReportQuery(baseOptions: Apollo.QueryHookOptions<ShopeeSalesDetailedReportQuery, ShopeeSalesDetailedReportQueryVariables> & ({ variables: ShopeeSalesDetailedReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopeeSalesDetailedReportQuery, ShopeeSalesDetailedReportQueryVariables>(ShopeeSalesDetailedReportDocument, options);
      }
export function useShopeeSalesDetailedReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopeeSalesDetailedReportQuery, ShopeeSalesDetailedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopeeSalesDetailedReportQuery, ShopeeSalesDetailedReportQueryVariables>(ShopeeSalesDetailedReportDocument, options);
        }
export function useShopeeSalesDetailedReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ShopeeSalesDetailedReportQuery, ShopeeSalesDetailedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ShopeeSalesDetailedReportQuery, ShopeeSalesDetailedReportQueryVariables>(ShopeeSalesDetailedReportDocument, options);
        }
export type ShopeeSalesDetailedReportQueryHookResult = ReturnType<typeof useShopeeSalesDetailedReportQuery>;
export type ShopeeSalesDetailedReportLazyQueryHookResult = ReturnType<typeof useShopeeSalesDetailedReportLazyQuery>;
export type ShopeeSalesDetailedReportSuspenseQueryHookResult = ReturnType<typeof useShopeeSalesDetailedReportSuspenseQuery>;
export type ShopeeSalesDetailedReportQueryResult = Apollo.QueryResult<ShopeeSalesDetailedReportQuery, ShopeeSalesDetailedReportQueryVariables>;
export function refetchShopeeSalesDetailedReportQuery(variables: ShopeeSalesDetailedReportQueryVariables) {
      return { query: ShopeeSalesDetailedReportDocument, variables: variables }
    }