import { useTranslation } from 'react-i18next';

import { Select } from '@any-ui-react/select';
import classNames from 'classnames';

import { SaleChannelType } from '~anyx/common/graphql';
import { DdiHomeMetricOption, ReportUtils } from '~anyx/feature/report';
import {
  MultiValueTooltip,
  SkeletonChartWrapper,
  StackedBarChart,
  NoResult,
  ContentColumn,
} from '~anyx/shared/ui';
import { FilterProps, ObjectUtils, useScreenBreakpoint } from '~anyx/shared/utils';

import { DdiHomeFilterInputFilterType } from '../home-filters';
import { NoSalesChannelConnection } from '../no-sales-channel-connection';

export interface DdiDailyChartProps {
  shouldShowMock: boolean;
  shouldShowNoData: boolean;
  filters: FilterProps<DdiHomeFilterInputFilterType>;
  metric: DdiHomeMetricOption;
  colors: Record<string, string>;
  dataKeys: Array<string | SaleChannelType>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<DdiHomeMetricOption, any>;
  dataKeyTranslation: (dataKey?: string | number) => string;
  loading?: boolean;
}

export const DdiDailyChart = ({
  data,
  filters,
  shouldShowMock,
  shouldShowNoData,
  loading,
  metric,
  dataKeys,
  dataKeyTranslation,
  colors,
}: DdiDailyChartProps) => {
  const { t } = useTranslation();
  const { changeFilter } = filters;
  const isLargeScreen = useScreenBreakpoint('lg');
  const options = ObjectUtils.arrayFromEnum<DdiHomeMetricOption>(DdiHomeMetricOption);
  const getInterval = (length = 0) => {
    return isLargeScreen ? Math.floor(length / 14) : Math.floor(length / 10);
  };

  return (
    <div data-test-id="report-daily-chart" className="relative h-full rounded-none lg:rounded">
      <div className={classNames('mb-2 px-4 pt-3', { 'blur-sm': shouldShowMock })}>
        <div className="mb-2 flex justify-start gap-2">
          <div className="w-1/2">
            <Select
              onChange={(option) => {
                if (option?.value) {
                  changeFilter('metric', option.value);
                }
              }}
              options={options.map((metric) => ({
                label: t('gql.enum.reportSummary', { ns: 'gql', metric }),
                value: metric,
              }))}
              value={metric}
            />
          </div>
        </div>
        <SkeletonChartWrapper
          loading={loading}
          error={!data?.[metric]?.length || shouldShowNoData}
          errorNode={
            <NoResult
              wrapperClassName="py-20 my-4 text-center !gap-2"
              titleClassName="text-sm"
              descriptionClassName="lg:!text-2xs"
              title={t('shared.table.empty', {
                ns: 'shared',
                element: t('shared.entity.data', { ns: 'shared', count: 1 }),
              })}
              description={t('shared.chart.guideline', { ns: 'shared' })}
            />
          }
        >
          <StackedBarChart
            legendVerticalAlign="bottom"
            tooltip={
              <MultiValueTooltip
                position="center"
                dataKeyTranslation={dataKeyTranslation}
                columns={ContentColumn.TWO}
              />
            }
            colors={colors}
            data={data[metric]}
            dataKeys={dataKeys}
            xAxisInterval={getInterval(data[metric]?.length)}
            xAxisPadding={ReportUtils.getBarChartPadding({
              length: data[metric]?.length,
              maxPadding: isLargeScreen ? 250 : 200,
            })}
          />
        </SkeletonChartWrapper>
      </div>
      {shouldShowMock && <NoSalesChannelConnection />}
    </div>
  );
};
