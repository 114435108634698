import { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DropZone as AnyUIDropZone,
  DefaultAddButton,
  DefaultPlaceholder,
  DropZoneProps,
  DropZoneViewMode,
  FileRejection,
} from '@any-ui-react/dropzone';

import {
  FileRestriction,
  FileTypes,
  FilesUtils,
  useFileUploadErrorTranslationMapper,
} from '~anyx/shared/utils';

type Optional = 'onDropRejected' | 'accept' | 'maxSize' | 'addButtonRender' | 'placeholderRender';
interface AnyUIDropZoneProps extends Omit<DropZoneProps, Optional> {
  restrictions: FileRestriction;
  onDropRejected?: (message: string) => void;
}

export const DropZone = forwardRef((props: AnyUIDropZoneProps, ref: ForwardedRef<unknown>) => {
  useImperativeHandle(
    ref,
    () => {
      return {
        focus() {
          return;
        },
      };
    },
    []
  );
  const {
    width = '100%',
    viewMode = DropZoneViewMode.CUSTOM,
    restrictions,
    onDropRejected,
    ...restProps
  } = props;
  const { getErrorMessages, getErrorCodes } = useFileUploadErrorTranslationMapper();
  const { t } = useTranslation();
  const handleFileRejection = (fileRejections: FileRejection[]) => {
    const errorCodes = getErrorCodes(fileRejections);
    const message = getErrorMessages(errorCodes, restrictions);
    onDropRejected?.(message);
  };

  const extensions = useMemo(() => {
    return Object.values(restrictions.types || {})
      .map((type) => (type[0] ? type[0].toUpperCase().split('.').pop() : []))
      .join(', ');
  }, [restrictions.types]);

  const fileType = useMemo(() => {
    if (Object.keys(restrictions.types || {}).some((element) => element.includes('image'))) {
      return FileTypes.IMAGE;
    }
    return FileTypes.FILE;
  }, [restrictions.types]);

  const memoizedValue = useMemo(() => {
    if (!Array.isArray(restProps.value)) return restProps.value;

    // If previous value exists and has same IDs, keep previous value
    if (restProps.value.length === 0) return [];

    return restProps.value.map((file) => ({
      ...file,
      id: file.id, // Preserve ID to maintain reference equality
    }));
  }, [restProps.value?.map((f) => f.id).join(',')]); // Only depend on IDs

  const propsWithMemoizedValue = {
    ...restProps,
    value: memoizedValue,
  };

  return (
    <AnyUIDropZone
      accept={restrictions.types}
      maxSize={restrictions.size}
      onDropRejected={handleFileRejection}
      viewMode={viewMode}
      width={width}
      addButtonRender={(props) => (
        <div className="text-4xs m-auto sm:text-sm">
          <DefaultAddButton
            {...props}
            text={t('shared.action.add', {
              ns: 'shared',
              entity: t('shared.entity.file', { ns: 'shared', count: 1 }),
            })}
          />
        </div>
      )}
      placeholderRender={(props) => (
        <DefaultPlaceholder
          {...props}
          buttonText={t('shared.action.upload', {
            ns: 'shared',
            entity: t('shared.entity.file', { ns: 'shared', count: 1 }),
          })}
          placeholderTitle={t('shared.fileUploader.content', {
            ns: 'shared',
            fileType,
            entity: t('shared.entity.file', { ns: 'shared', count: 1 }),
          })}
          placeholderDescription={
            <>
              {!!restrictions.size &&
                t('shared.fileUploader.maxSize', {
                  ns: 'shared',
                  fileType,
                  size: `${restrictions.size / FilesUtils.MEGABYTE}MB`,
                })}
              <br />
              {restrictions.types &&
                t('shared.fileUploader.supportedExtension', {
                  ns: 'shared',
                  fileType,
                  extensions,
                })}
            </>
          }
        />
      )}
      {...propsWithMemoizedValue}
    />
  );
});
