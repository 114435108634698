import { useState } from 'react';
import { Navigate } from 'react-router';

import { useAsyncEffect } from 'use-async-effect';

import { MdUserRole } from '~anyx/common/graphql';
import { OnboardingRoutePath } from '~anyx/common/routing';
import { useSelfCtx } from '~anyx/common/self';
import { setWorkspace, useFirstWorkspace, useWorkspaceCtx } from '~anyx/common/workspace';
import { LoadWrapper } from '~anyx/shared/ui';

import { useWorkspaceHomepage } from '../../hooks';

export const IndexPage = () => {
  const {
    self: { accountIds, role },
  } = useSelfCtx();
  const { homepageLink } = useWorkspaceHomepage();
  const storeId = useWorkspaceCtx((state) => state.workspace?.storeId);

  const { getFirstWorkspace } = useFirstWorkspace({
    accountId: role === MdUserRole.MERCHANT ? accountIds[0] ?? undefined : undefined,
  });
  const [link, setLink] = useState<string | undefined>(undefined);

  useAsyncEffect(
    async (isMounted) => {
      try {
        if (storeId) {
          setLink(`/${storeId}${homepageLink}`);
        }

        const workspace = await getFirstWorkspace();

        if (!isMounted()) return;
        setWorkspace(workspace);

        if (!workspace) {
          setLink(OnboardingRoutePath().root().path);
          return;
        }

        setLink(homepageLink);
      } catch (e) {
        if (!isMounted()) return;
        setLink(homepageLink);
      }
    },
    [getFirstWorkspace, storeId]
  );

  return (
    <LoadWrapper className="flex h-full w-full" loading={!link}>
      <Navigate to={link as string} replace={true} />
    </LoadWrapper>
  );
};
