import classNames from 'classnames';

import { SkeletonLoader } from '~anyx/shared/ui';

import { ReportPercentageArrow } from '../report-percentage-arrow';

interface GroupedReportCardContainerProps {
  cards: {
    title: string;
    value: string | JSX.Element;
    percentage: string;
  }[];
  wrapperClassName?: string;
  loading?: boolean;
}
export const GroupedReportCardContainer = ({
  cards,
  wrapperClassName,
  loading,
  ...rest
}: GroupedReportCardContainerProps) => {
  return (
    <div className={classNames(wrapperClassName, 'card grid grid-cols-10 bg-white')} {...rest}>
      {cards.map((card, i) => {
        const isFirstCard = i === 0;
        const isOddCard = (i + 1) % 2 !== 0;
        return (
          <div
            key={i}
            className={classNames('border-gray-1 col-span-6 p-4', {
              'col-span-full border-t-0': isFirstCard,
              'border-t': !isFirstCard,
              '!col-span-4 pl-0': isOddCard && !isFirstCard,
            })}
          >
            <div className={classNames({ 'border-l pl-4': isOddCard && !isFirstCard })}>
              <div className="text-2xs text-gray-7 mb-2 whitespace-nowrap leading-none">
                <SkeletonLoader loading={loading} className="h-3 w-24">
                  {card.title}
                </SkeletonLoader>
              </div>
              <div
                className={classNames('flex', {
                  'flex-col gap-2': !isFirstCard,
                  'items-end gap-3': isFirstCard,
                })}
              >
                <div
                  className={classNames('text-lg font-bold leading-none', {
                    'contents text-xl md:text-2xl': isFirstCard,
                  })}
                >
                  <SkeletonLoader
                    loading={loading}
                    className={classNames('h-5 w-32', {
                      'md:h-6': isFirstCard,
                    })}
                  >
                    {card.value}
                  </SkeletonLoader>
                </div>
                <div className={classNames({ 'mb-1 inline-block': isFirstCard })}>
                  <SkeletonLoader loading={loading} className="h-4 w-16">
                    <ReportPercentageArrow value={card?.percentage} />
                  </SkeletonLoader>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
