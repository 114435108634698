import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router';

import { ReportChartTab, ReportDimensions } from '~anyx/common/graphql';
import { ReportSalesChannelRoutePath } from '~anyx/common/routing';
import { WorkspaceMode } from '~anyx/common/workspace';
import { ChannelUtils } from '~anyx/feature/marketplace';
import {
  CHANNEL_COLOR,
  getStoreColors,
  getChannelColors,
  homeMetricList,
} from '~anyx/feature/report';
import { computeChannelChartData, computeStoreChartData } from '~anyx/function/report/utils';
import { ArrowRightIcon } from '~anyx/shared/icons';
import { RouterUtils } from '~anyx/shared/utils';

import {
  useHomeFilter,
  HomeFilters,
  ProductRankingTable,
  SummaryCard,
  DailyChart,
  DailyChartProps,
  PieChartProps,
  SalesChannelPieChart,
} from './components';
import { MockDailyChart, MockSalesChannels, MockPieChart, MockProductRankingTable } from './mocks';
import {
  useHomeReportSummary,
  useHomeReportPieChart,
  useHomeReportProductTable,
  useHomeReportChart,
  useHomeReportTable,
} from './operation';

interface ReportSectionProps {
  accountIds: string[];
  storeId?: string;
  mode?: WorkspaceMode;
  hasSalesChannels: boolean;
  shouldShowMock: boolean;
}

export const ReportSection = ({
  accountIds,
  storeId,
  mode,
  hasSalesChannels,
  shouldShowMock,
}: ReportSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const allSearchParams = useMemo(() => {
    return RouterUtils.getAllSearchParams(searchParams);
  }, [searchParams]);

  const { dimensions: _dimensions, ...search } = allSearchParams;
  const { filterInputs, filters } = useHomeFilter({
    accountIds,
    storeId,
  });
  const { metric, chartTab } = filters.current;

  const shouldSkip = !hasSalesChannels && mode === WorkspaceMode.ON;

  const { reportSummary, loading: summaryLoading } = useHomeReportSummary({
    filters: filterInputs,
    skip: shouldSkip,
  });

  const { reportPieChart: channelPieChart, loading: channelPieLoading } = useHomeReportPieChart({
    filters: filterInputs,
    skip: shouldSkip || chartTab === ReportChartTab.STORE,
  });

  const { reportPieChart: storePieChart, loading: storePieLoading } = useHomeReportPieChart({
    filters: filterInputs,
    skip: shouldSkip || chartTab === ReportChartTab.CHANNEL,
  });

  const { reportProductTable, loading: productRankingLoading } = useHomeReportProductTable({
    filters: filterInputs,
    skip: shouldSkip,
  });

  const { reportChart, loading: chartLoading } = useHomeReportChart({
    filters: filterInputs,
    skip: shouldSkip,
  });

  const { reportTable, loading: tableLoading } = useHomeReportTable({
    filters: filterInputs,
    skip: shouldSkip,
  });

  const { reportChannelChart, channelList } = computeChannelChartData({
    filterInputs,
    reportChart,
    reportTable,
    metricList: homeMetricList,
  });

  // TODO: https://adasiaholdings.atlassian.net/browse/AXRP-2102
  // Backend will update the Sales Channel Report Chart response to be like Ads Report Chart response
  // So we won't need to transform the data in the frontend
  const { reportStoreChart, storeList, storeIds } = computeStoreChartData({
    filterInputs,
    reportChart,
    reportTable,
    metricList: homeMetricList,
  });

  const storeTranslation = (dataKey?: string | number) =>
    storeList?.find((store) => store.id === dataKey)?.name ?? '';
  const channelTranslation = (dataKey?: string | number) =>
    t('gql.enum.channel', { ns: 'gql', channel: dataKey });

  const storeColors = getStoreColors(storeIds);
  const channelColors = getChannelColors(channelList);
  const hasNoData = reportChart?.list.length === 0;
  const dailyChartProps: DailyChartProps = {
    shouldShowMock,
    shouldShowNoData: !shouldShowMock && hasNoData,
    filters,
    metric,
    loading: chartLoading || tableLoading,
    ...(chartTab === ReportChartTab.CHANNEL
      ? {
          data: reportChannelChart,
          colors: channelColors,
          dataKeys: channelList,
          dataKeyTranslation: channelTranslation,
        }
      : {
          data: reportStoreChart,
          colors: storeColors,
          dataKeys: storeIds,
          dataKeyTranslation: storeTranslation,
        }),
    ...(shouldShowMock
      ? {
          data: MockDailyChart,
          colors: getChannelColors(MockSalesChannels),
          dataKeys: MockSalesChannels,
        }
      : {}),
  };

  const channelPieDataKeys = ChannelUtils.sortChannels({
    channels: channelPieChart?.data,
    byField: 'saleChannelType',
  }).map((item) => item.saleChannelType as string);

  const pieChartProps: PieChartProps = {
    filters,
    shouldShowMock,
    loading: channelPieLoading || storePieLoading || chartLoading,
    currency: channelPieChart?.currency,
    ...(chartTab === ReportChartTab.CHANNEL
      ? {
          data: channelPieChart?.data,
          dataKeys: channelPieDataKeys,
          colors: channelColors,
        }
      : {
          data: storePieChart?.data,
          dataKeys: storeIds,
          colors: storeColors,
        }),
    ...(shouldShowMock
      ? {
          data: MockPieChart.DEFAULT.reportHomeSaleChannelRevenue.data,
          colors: getChannelColors(MockSalesChannels),
          dataKeys: MockSalesChannels,
        }
      : {}),
  };

  return (
    <div data-test-id="report-section">
      <div className="card mb-4 mt-4 rounded-none bg-white lg:mt-0 lg:rounded">
        <HomeFilters {...filters} />
      </div>
      <div>
        {hasSalesChannels && (
          <SummaryCard
            loading={summaryLoading}
            data={reportSummary?.comparisonSummary}
            currency={reportSummary?.currency}
          />
        )}
        <div className="card mb-4 flex flex-wrap lg:grid lg:grid-cols-7">
          <div className="border-gray-1 mb-4 w-full border-r lg:col-span-5 lg:mb-0">
            <DailyChart {...dailyChartProps} />
          </div>
          <div className="w-full lg:col-span-2">
            <SalesChannelPieChart {...pieChartProps} />
          </div>
          <div className="border-gray-1 flex w-full border-t px-4 py-3 leading-8 lg:col-span-7">
            <div className="flex min-w-0 flex-wrap">
              {chartTab === ReportChartTab.CHANNEL &&
                channelList?.map((channel, i) => {
                  return (
                    <div key={i} className="text-4xs mr-2 flex min-w-0 items-center font-semibold">
                      <div
                        style={{
                          background: CHANNEL_COLOR[channel]?.['default'],
                        }}
                        className="mr-1 h-2.5 w-2.5 shrink-0 rounded-full"
                      />
                      <p className="truncate">{channelTranslation(channel)}</p>
                    </div>
                  );
                })}
              {chartTab === ReportChartTab.STORE &&
                storeIds?.map((id, i) => {
                  return (
                    <div key={i} className="text-4xs mr-2 flex min-w-0 items-center font-semibold">
                      <div
                        style={{
                          background: getStoreColors(storeIds)[id],
                        }}
                        className="mr-1 h-2.5 w-2.5 shrink-0 rounded-full"
                      />
                      <p className="truncate">{storeTranslation(id)}</p>
                    </div>
                  );
                })}
            </div>
            <button
              type="button"
              onClick={() =>
                navigate({
                  pathname: ReportSalesChannelRoutePath().list().path,
                  search: createSearchParams({
                    dimensions: ReportDimensions.CHANNELS,
                    ...search,
                  }).toString(),
                })
              }
              className="link-basic text-2xs ml-auto inline-flex shrink-0 items-center pl-6"
            >
              {t('core.page.home.report.table.link.seeMore', { ns: 'core' })}
              <ArrowRightIcon className="ml-2 h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
      <ProductRankingTable
        shouldShowMock={shouldShowMock}
        data={
          shouldShowMock
            ? MockProductRankingTable.DEFAULT.reportHomeTop5Products.data
            : reportProductTable?.data
        }
        loading={productRankingLoading}
      />
    </div>
  );
};
