import { ChatType, MdAccountProduct } from '~anyx/common/graphql';

export interface Workspace {
  accountId?: string;
  storeId?: string;
  storeName?: string;
  accountName?: string;
  accountProduct?: MdAccountProduct;
  crmWorkspace?: CrmWorkspace;
}

interface CrmWorkspace {
  isCrmEnabled: boolean;
  chats: ChatType[];
}

export enum WorkspaceMode {
  // Account and Store are set in workspace (merchant/staff/admin with workspace)
  ON = 'ON',
  // Only Account is set in workspace (merchant without workspace)
  ACCOUNT_ONLY = 'ACCOUNT_ONLY',
  // Nothing is set in workspace (admin and staff without workspace)
  OFF = 'OFF',
}

export type WorkspaceSetModeOptions = {
  autoRedirect?: boolean;
  storeId?: string;
  onChanged?: (mode: WorkspaceMode) => void;
};
