import React from 'react';
import { RouteObject, Navigate } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { MdUserRole } from '~anyx/common/graphql';
import { Restricted, Auth0Permissions, PermissionType } from '~anyx/common/permission';
import { ReportStockValueRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const InventoryTrackerListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.InventoryTrackerListPage }))
);

const InventoryTrackerTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.InventoryTrackerTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'inventory-tracker',
    element: (
      <Auth0Guard>
        <Restricted
          roles={[MdUserRole.ADMIN, MdUserRole.STAFF]}
          permissions={[[Auth0Permissions.CAN_USE_ANALYTICS]]}
          mixConditions={[PermissionType.ROLE, PermissionType.PERMISSION]}
          fallback={<UnauthorizedMessage />}
        >
          <InventoryTrackerTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <InventoryTrackerListPage />,
      },
      {
        index: true,
        element: (
          <Navigate
            to={ReportStockValueRoutePath({ workspaceStoreId: null }).list().path}
            replace={true}
          />
        ),
      },
    ],
  },
];
