import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';

export const AnyxAuthProvider = ({
  children,
  clientId,
  domain,
  authorizationParams,
  skipRedirectCallbackRegex,
}: Auth0ProviderOptions & { skipRedirectCallbackRegex: RegExp[] }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      navigate(appState?.returnTo || window.location.pathname);
    },
    [navigate]
  );

  const shouldSkipRedirectCallback = useMemo(() => {
    return skipRedirectCallbackRegex.some((regex) => regex.test(location.pathname));
  }, [location.pathname, skipRedirectCallbackRegex]);

  return (
    <Auth0Provider
      clientId={clientId}
      domain={domain}
      authorizationParams={authorizationParams}
      onRedirectCallback={onRedirectCallback}
      skipRedirectCallback={shouldSkipRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
