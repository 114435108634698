import { useTranslation } from 'react-i18next';

import { Direction } from '@any-ui-react/table';
import classNames from 'classnames';

import { ProductReviewCriteriaResponseDto } from '~anyx/common/graphql';
import { NumberWithCurrency, NumberWithPercentage, Image, LoadWrapper } from '~anyx/shared/ui';
import { NumberUtils } from '~anyx/shared/utils';

import { RatingStar } from '../rating-star';

import { CompareTableSkeleton } from './CompareTableSkeleton';
import { ShadowProvider, useShadowContext } from './context';

export type ComparisonProduct = {
  brand?: string | null;
  discountedPrice?: string | null;
  originalPrice?: string | null;
  rating?: string | null;
  image?: string | null;
  itemId?: string | null;
  reviewCount?: string | null;
  title?: string | null;
  productUrl?: string | null;
  currency?: string | null;
  criteria?: readonly ProductReviewCriteriaResponseDto[];
};

export const getStickyClassName = (direction: Direction) => {
  const stickyClassName = 'sticky left-0 z-10 bg-white';
  const stickyClassNameRtl = 'sticky right-0 z-10 bg-white';

  return direction === 'ltr' ? stickyClassName : stickyClassNameRtl;
};

const getOpacity = (criteriaList: { criteria: string; value: number }[], criteria: string) => {
  const sortedCriteriaList = criteriaList.sort((a, b) => {
    return b.value - a.value;
  });
  const criteriaRank = sortedCriteriaList.findIndex((item) => item.criteria === criteria);
  const opacities = ['1', '0.8', '0.6', '0.4', '0.2'];
  return criteriaRank !== -1 ? opacities[criteriaRank] : '1';
};

export const StickyShadow = () => {
  const { showShadow, direction } = useShadowContext();

  return (
    <div
      className={classNames('bg-stickyShadow absolute top-0 h-full w-4 -scale-x-100 opacity-40', {
        'opacity-40': showShadow,
        hidden: !showShadow,
        '-right-4 -scale-x-100': direction === 'ltr',
        '-left-4 scale-x-100': direction === 'rtl',
      })}
    />
  );
};

export const TableHeader = ({
  products,
  shouldShowMock,
  label,
}: {
  products: ComparisonProduct[];
  shouldShowMock: boolean;
  label: string;
}) => {
  const { t } = useTranslation('report');
  const { direction } = useShadowContext();
  return (
    <tr>
      <td
        className={classNames(
          getStickyClassName(direction),
          'border-gray-1 min-w-44 border px-4 py-1 font-semibold md:min-w-64',
          {
            'border-l-0': direction === 'ltr',
            'border-r-0': direction === 'rtl',
          }
        )}
      >
        {label}
        <StickyShadow />
      </td>
      {products.map((product, index) => (
        <td
          key={index}
          className="border-gray-1 w-40 border px-2 pb-3 pt-0 align-top last:border-r-0"
        >
          {index === 0 && !shouldShowMock ? (
            <div className="bg-gray-9 -mx-2 mb-2 h-5 text-center font-semibold text-white">
              {t('report.page.product.comparisonTable.column.myProduct', { ns: 'report' })}
            </div>
          ) : (
            <div className="mb-2 h-5" />
          )}
          <Image src={product.image} alt={product.title || ''} className="mx-auto mb-2 h-36 w-36" />
          <div
            className={classNames({
              'w-56': products.length > 4,
              'w-64': products.length === 4,
              'w-72': products.length === 3,
            })}
          >
            <a
              href={product.productUrl || ''}
              className="group-hover:text-link hover:text-link inline-flex font-semibold hover:underline"
              rel="noreferrer"
              target="_blank"
            >
              {product.title}
            </a>
          </div>
        </td>
      ))}
    </tr>
  );
};

export const TableRow = ({
  products,
  label,
  renderCell,
}: {
  products: ComparisonProduct[];
  label: string;
  renderCell: (product: ComparisonProduct) => React.ReactNode;
}) => {
  const { direction } = useShadowContext();

  return (
    <tr>
      <td
        className={classNames(
          getStickyClassName(direction),
          'border-gray-1 h-6 border-b px-4 py-0 font-semibold'
        )}
      >
        {label}

        <StickyShadow />
      </td>
      {products.map((product, sku) => (
        <td key={sku} className="border-gray-1 border px-2 py-0 last:border-r-0">
          {renderCell(product)}
        </td>
      ))}
    </tr>
  );
};

const TableCriteriaRow = ({
  products,
  label,
}: {
  products: ComparisonProduct[];
  label: string;
}) => {
  const { direction } = useShadowContext();
  const { t } = useTranslation('report');
  return (
    <>
      <tr>
        <td
          className={classNames(
            getStickyClassName(direction),
            'border-gray-1 h-6 border-b px-4 py-0 font-semibold'
          )}
        >
          {label}
          <StickyShadow />
        </td>
        {Array.from({ length: products.length }).map((_, index) => (
          <td key={index} className="border-gray-1 border border-b-0 p-0 last:border-r-0">
            <div className="flex">
              <div className="relative h-6 flex-1">
                <div className="bg-blue-6 h-full w-full p-1" />
                <p className="absolute left-2 top-1 font-semibold text-white">
                  {t('report.page.product.comparisonTable.row.positive', { ns: 'report' })}
                </p>
              </div>
              <div className="relative h-6 flex-1">
                <div className="bg-red-4 h-full w-full p-1" />
                <p className="absolute left-2 top-1 font-semibold text-white">
                  {t('report.page.product.comparisonTable.row.negative', { ns: 'report' })}
                </p>
              </div>
            </div>
          </td>
        ))}
      </tr>
      {products?.[0]?.criteria?.map((criteria, criteriaIndex) => (
        <tr key={criteria.criteria}>
          <td
            title={criteria.criteria}
            className={classNames(
              getStickyClassName(direction),
              'border-gray-1 max-w-44 truncate border border-b-0 px-4 py-0 font-semibold',
              {
                'border-l-0': direction === 'ltr',
                'border-r-0': direction === 'rtl',
              }
            )}
          >
            {criteria.criteria}
            <StickyShadow />
          </td>
          {products.map((product, productIndex) => {
            const { positive, negative } = product.criteria?.[criteriaIndex] || {};
            const positiveList = product.criteria?.map((c) => ({
              criteria: c.criteria,
              value: NumberUtils.toNumber(c.positive),
            }));
            const negativeList = product.criteria?.map((c) => ({
              criteria: c.criteria,
              value: NumberUtils.toNumber(c.negative),
            }));
            const positiveOpacity = getOpacity(positiveList || [], criteria.criteria);
            const negativeOpacity = getOpacity(negativeList || [], criteria.criteria);

            return (
              <td
                key={productIndex}
                className="border-gray-1 border border-b-0 p-0 last:border-r-0"
              >
                <div className="flex">
                  <div className="relative h-6 flex-1">
                    <div
                      className="bg-blue-6 h-full w-full p-1"
                      style={{
                        opacity: positiveOpacity,
                        ...(!NumberUtils.isNumber(positive) && {
                          backgroundColor: '#FFF',
                        }),
                      }}
                    />
                    <p className="absolute left-2 top-1">
                      <NumberWithPercentage
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                        amount={positive}
                      />
                    </p>
                  </div>
                  <div className="relative h-6 flex-1">
                    <div
                      className="bg-red-4 h-full w-full p-1"
                      style={{
                        opacity: negativeOpacity,
                        ...(!NumberUtils.isNumber(negative) && {
                          backgroundColor: '#FFF',
                        }),
                      }}
                    />
                    <p className="absolute left-2 top-1">
                      <NumberWithPercentage
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                        amount={negative}
                      />
                    </p>
                  </div>
                </div>
              </td>
            );
          })}
        </tr>
      ))}
    </>
  );
};

const CompareTableContent = ({
  products,
  shouldShowMock,
}: {
  products: ComparisonProduct[];
  shouldShowMock: boolean;
}) => {
  const { t } = useTranslation('report');
  const { handleScroll, direction } = useShadowContext();

  return (
    <div className="w-full overflow-x-auto" onScroll={handleScroll} style={{ direction }}>
      <table className="text-2xs w-full border-collapse text-left">
        <tbody>
          <TableHeader
            products={products}
            label={t('report.page.product.comparisonTable.row.product', { ns: 'report' })}
            shouldShowMock={shouldShowMock}
          />
          <TableRow
            products={products}
            label={t('report.page.product.comparisonTable.row.itemId', { ns: 'report' })}
            renderCell={(product) => product.itemId}
          />
          <TableRow
            products={products}
            label={t('report.page.product.comparisonTable.row.originalPrice', { ns: 'report' })}
            renderCell={(product) => (
              <NumberWithCurrency amount={product.originalPrice} currency={product.currency} />
            )}
          />
          <TableRow
            products={products}
            label={t('report.page.product.comparisonTable.row.discountedPrice', { ns: 'report' })}
            renderCell={(product) => (
              <NumberWithCurrency amount={product.discountedPrice} currency={product.currency} />
            )}
          />
          <TableRow
            products={products}
            label={t('report.page.product.comparisonTable.row.review', { ns: 'report' })}
            renderCell={(product) => (
              <div className="flex items-center">
                <RatingStar averageRating={NumberUtils.toNumber(product.rating)} size="sm" />
                {NumberUtils.isNumber(product.rating) && (
                  <p className="ml-2">
                    {product.rating} ({product.reviewCount})
                  </p>
                )}
              </div>
            )}
          />
          <TableCriteriaRow
            products={products}
            label={t('report.page.product.comparisonTable.row.reviewAnalytics', { ns: 'report' })}
          />
        </tbody>
      </table>
    </div>
  );
};

export const CompareTable = ({
  products,
  direction = 'ltr',
  shouldShowMock,
  loading,
}: {
  products: ComparisonProduct[];
  shouldShowMock: boolean;
  direction?: Direction;
  loading?: boolean;
}) => {
  return (
    <ShadowProvider direction={direction}>
      <LoadWrapper loading={loading} loadingTemplate={<CompareTableSkeleton />}>
        <CompareTableContent products={products} shouldShowMock={shouldShowMock} />
      </LoadWrapper>
    </ShadowProvider>
  );
};
