import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import classNames from 'classnames';
import { ContentState, Editor, EditorState, getDefaultKeyBinding } from 'draft-js';

import { ReportAiRoutePath } from '~anyx/common/routing';
import { useAiChatEventStore } from '~anyx/function/report/hooks';
import { ArrowUpCircleIcon, ClipIcon } from '~anyx/shared/icons';

import { AiResponseSchema, ChatEventRole } from '../../../modules/ai/type';

import './chat-input.css';
import { useLoginAiChat } from './useLoginAiChat';
import { useSendPrompt } from './useSendPrompt';

interface ChatInputProps {
  className?: string;
}

export const ChatInput = ({ className }: ChatInputProps) => {
  const { t } = useTranslation('report');
  const editorRef = useRef<Editor | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { handleLogin } = useLoginAiChat();
  const { handleSendPrompt, loading: generating } = useSendPrompt();
  const updateChatEvents = useAiChatEventStore((state) => state.updateChatEvents);
  const removeLastLoadingChatEvent = useAiChatEventStore(
    (state) => state.removeLastLoadingChatEvent
  );

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const hasText = editorState.getCurrentContent().hasText();

  const clearText = useCallback(
    (params?: { keepFocus?: boolean }) => {
      const { keepFocus = true } = params || {};
      let clearState: EditorState;

      clearState = EditorState.push(editorState, ContentState.createFromText(''), 'remove-range');

      if (keepFocus) {
        clearState = EditorState.moveFocusToEnd(clearState);
      }

      setEditorState(clearState);
    },
    [editorState]
  );

  const onSendMessage = useCallback(
    async (message: string) => {
      updateChatEvents([
        {
          role: ChatEventRole.USER,
          prompt: message,
        },
      ]);

      updateChatEvents([
        {
          role: ChatEventRole.ASSISTANT,
          loading: true,
        },
      ]);

      const response = await handleSendPrompt(message);
      if (AiResponseSchema.safeParse(response).success) {
        removeLastLoadingChatEvent();
        updateChatEvents([
          {
            role: ChatEventRole.ASSISTANT,
            loading: false,
            response: AiResponseSchema.safeParse(response).data,
          },
        ]);
      } else {
        removeLastLoadingChatEvent();
        updateChatEvents([
          {
            role: ChatEventRole.ASSISTANT,
            loading: false,
            error: {
              message: t('report.page.aiChat.error.message'),
              suggestion: {
                message: t('report.page.aiChat.error.suggestion.message'),
                items: [
                  t('report.page.aiChat.error.suggestion.item1'),
                  t('report.page.aiChat.error.suggestion.item2'),
                ],
              },
            },
          },
        ]);
      }
    },
    [handleSendPrompt, removeLastLoadingChatEvent, t, updateChatEvents]
  );

  const sendMessage = useCallback(async () => {
    if (!location.pathname.includes('/ai/chat')) {
      navigate(ReportAiRoutePath().chat().path);
    }

    if (generating) {
      return;
    }

    clearText();
    await onSendMessage?.(editorState.getCurrentContent().getPlainText());
  }, [clearText, editorState, generating, location.pathname, navigate, onSendMessage]);

  const disabledUpload = true;

  useEffect(() => {
    handleLogin();
  });

  return (
    <div
      className={classNames(
        className,
        'fixed bottom-12 left-0 flex w-full flex-col justify-end gap-4 px-8 md:px-16 lg:left-[calc(50%_+_7rem)] lg:max-w-3xl lg:-translate-x-1/2 lg:px-0'
      )}
    >
      <div className="outline-gray-2 relative flex rounded-full bg-white outline outline-1">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className="ai-chat-input text-gray-9 flex max-h-44 grow cursor-text resize-none flex-col justify-center overflow-y-auto rounded-full border-0 px-14 py-4 text-base leading-10 shadow-[0_0_0_8px_rgba(185,192,200,0.2)] placeholder:text-slate-400 focus:border-none focus:outline-none focus:ring-transparent lg:min-h-14"
          onClick={() => editorRef.current?.focus()}
          role="button"
          tabIndex={-1}
        >
          <Editor
            ref={(editor) => {
              editorRef.current = editor;
            }}
            placeholder={t('report.page.aiChat.input.placeholder')}
            stripPastedStyles
            editorState={editorState}
            onChange={(editor) => {
              setEditorState(editor);
            }}
            keyBindingFn={(e) => {
              const key = e.key;
              if (key === 'Enter') {
                return 'send-message';
              }

              return getDefaultKeyBinding(e);
            }}
            handleKeyCommand={(command) => {
              if (command === 'send-message' && hasText) {
                sendMessage();
                return 'handled';
              }

              return 'not-handled';
            }}
          />
        </div>
        <button
          disabled={disabledUpload}
          className={classNames(
            'text-gray-6 absolute left-4 top-1/2 -translate-y-1/2 transition-colors duration-200 ease-in-out',
            { 'cursor-default': disabledUpload }
          )}
        >
          <ClipIcon className="h-6 w-6" />
        </button>
        <button
          disabled={!hasText || generating}
          className={classNames(
            'absolute right-4 top-1/2 -translate-y-1/2 transition-colors duration-200 ease-in-out',
            {
              'text-gray-9 hover:text-gray-7 cursor-pointer':
                editorState.getCurrentContent().hasText() && !generating,
              'text-gray-2 cursor-default': !hasText || generating,
            }
          )}
          onClick={() => sendMessage()}
        >
          <ArrowUpCircleIcon className="h-8 w-8" />
        </button>
      </div>
    </div>
  );
};
