import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import * as Sentry from '@sentry/react';

import { environment } from '~anyx/app-core/env';

import { Environment } from '~anyx/shared/utils';

export const initSentry = () => {
  if ([Environment.STAGING, Environment.PRODUCTION].includes(environment.env)) {
    const version = process.env['NX_PUBLIC_VERSION'] || '';
    Sentry.init({
      dsn: environment.sentry.dns,
      ignoreErrors: [/Loading chunk \d+ failed/, /401/, /FORBIDDEN/],
      debug: environment.enableDebug,
      release: version,
      environment: Environment[environment.env],
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          networkDetailAllowUrls: [
            window.location.origin,
            'https://firebaseremoteconfig.googleapis.com',
            'https://firebase.googleapis.com',
            'https://gateway.stg.app.any-x.com',
            'https://gateway.app.any-x.com',
          ],
          networkRequestHeaders: ['X-Custom-Header'],
          networkResponseHeaders: ['X-Custom-Header'],
        }),
      ],
      tracesSampleRate: Environment.STAGING ? 0.5 : 0.8,
      replaysOnErrorSampleRate: Environment.STAGING ? 0.5 : 1.0,
      replaysSessionSampleRate: 0.5,
    });

    if (version === '') {
      Sentry.captureException(new Error('Encountered Empty Version Name!'));
    }
  } else {
    Sentry.init({});
  }
};

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
