import React from 'react';
import { Outlet, RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { Auth0Permissions, Restricted } from '~anyx/common/permission';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const LazadaCallbackPage = React.lazy(() => import('./lazada'));
const WhatsAppCallbackPage = React.lazy(() => import('./whatsapp'));
const TikTokShopCallbackPage = React.lazy(() => import('./tiktok-shop'));

export const routes: RouteObject[] = [
  {
    path: 'crm/oauth',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_CRM]]}
          fallback={<UnauthorizedMessage />}
        >
          <Outlet />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'lazada/callback',
        element: <LazadaCallbackPage />,
      },
      {
        path: 'whatsapp/callback',
        element: <WhatsAppCallbackPage />,
      },
      {
        path: 'tiktok-shop/callback',
        element: <TikTokShopCallbackPage />,
      },
    ],
  },
];
