import React from 'react';

import { DatasourceType } from '~anyx/common/graphql';

const GoogleAdsEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({
    default: module.GoogleAdsEditPage,
  }))
);

const GoogleAdsAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({
    default: module.GoogleAdsAddPage,
  }))
);

const GoogleAdsCampaignAdSetListPage = React.lazy(() =>
  import('./pages/campaign/campaign-adset-list').then((module) => ({
    default: module.GoogleAdsCampaignAdSetListPage,
  }))
);

const GoogleAdsCampaignAdSetEditPage = React.lazy(() =>
  import('./pages/campaign/campaign-adset-edit').then((module) => ({
    default: module.GoogleAdsCampaignAdSetEditPage,
  }))
);

const GoogleAdsTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.GoogleAdsTemplate }))
);

export const googleAdsRouting = [
  {
    path: DatasourceType.GoogleAds,
    element: <GoogleAdsTemplate />,
    children: [
      {
        index: true,
        element: <GoogleAdsAddPage />,
      },
      {
        path: ':datasourceId',
        children: [
          {
            index: true,
            element: <GoogleAdsEditPage />,
          },
          {
            path: 'campaign/:campaignId/adSet/:adSetMappingCampaignId',
            children: [
              {
                index: true,
                element: <GoogleAdsCampaignAdSetListPage />,
              },
              {
                path: 'set',
                element: <GoogleAdsCampaignAdSetEditPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];
