import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/common/graphql';

const YahooAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.YahooAddPage }))
);

const YahooEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.YahooEditPage }))
);

const YahooTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.YahooTemplate }))
);

export const yahooRouting = [
  {
    path: MarketplaceSaleChannelType.Yahoo,
    element: <YahooTemplate />,
    children: [
      {
        index: true,
        element: <YahooAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            index: true,
            element: <YahooEditPage />,
          },
        ],
      },
    ],
  },
];
