import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type YahooSalesReportTableQueryVariables = Types.Exact<{
  input: Types.YahooSalesReportTableInput;
}>;


export type YahooSalesReportTableQuery = { readonly yahooSalesReportTable: { readonly __typename?: 'YahooSalesReportTablePayloadList', readonly items: ReadonlyArray<{ readonly __typename?: 'YahooSalesReportTablePayload', readonly avgSpendPerCustomer: number, readonly currency: Types.ReportSupportedCurrency, readonly date: any, readonly orderCount: number, readonly totalSales: string, readonly unitSoldCount: number }>, readonly meta: { readonly __typename?: 'ReportListMeta', readonly length: number, readonly limit: number, readonly start: number, readonly total: number }, readonly total: { readonly __typename?: 'YahooSalesReportTotalPayload', readonly avgSpendPerCustomer: number, readonly orderCount: number, readonly totalSales: string, readonly unitSoldCount: number } } };


export const YahooSalesReportTableDocument = gql`
    query YahooSalesReportTable($input: YahooSalesReportTableInput!) {
  yahooSalesReportTable(input: $input) {
    items {
      avgSpendPerCustomer
      currency
      date
      orderCount
      totalSales
      unitSoldCount
    }
    meta {
      length
      limit
      start
      total
    }
    total {
      avgSpendPerCustomer
      orderCount
      totalSales
      unitSoldCount
    }
  }
}
    `;

/**
 * __useYahooSalesReportTableQuery__
 *
 * To run a query within a React component, call `useYahooSalesReportTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useYahooSalesReportTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYahooSalesReportTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useYahooSalesReportTableQuery(baseOptions: Apollo.QueryHookOptions<YahooSalesReportTableQuery, YahooSalesReportTableQueryVariables> & ({ variables: YahooSalesReportTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YahooSalesReportTableQuery, YahooSalesReportTableQueryVariables>(YahooSalesReportTableDocument, options);
      }
export function useYahooSalesReportTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YahooSalesReportTableQuery, YahooSalesReportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YahooSalesReportTableQuery, YahooSalesReportTableQueryVariables>(YahooSalesReportTableDocument, options);
        }
export function useYahooSalesReportTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<YahooSalesReportTableQuery, YahooSalesReportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<YahooSalesReportTableQuery, YahooSalesReportTableQueryVariables>(YahooSalesReportTableDocument, options);
        }
export type YahooSalesReportTableQueryHookResult = ReturnType<typeof useYahooSalesReportTableQuery>;
export type YahooSalesReportTableLazyQueryHookResult = ReturnType<typeof useYahooSalesReportTableLazyQuery>;
export type YahooSalesReportTableSuspenseQueryHookResult = ReturnType<typeof useYahooSalesReportTableSuspenseQuery>;
export type YahooSalesReportTableQueryResult = Apollo.QueryResult<YahooSalesReportTableQuery, YahooSalesReportTableQueryVariables>;
export function refetchYahooSalesReportTableQuery(variables: YahooSalesReportTableQueryVariables) {
      return { query: YahooSalesReportTableDocument, variables: variables }
    }