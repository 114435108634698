import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '@any-ui-react/core';

import { useOnboardingCtx } from '~anyx/common/onboarding';
import { IntegrationRoutePath } from '~anyx/common/routing';
import { useCrmConnectionErrorCount } from '~anyx/integration-crm/shared';
import { AlertBanner } from '~anyx/shared/ui';

export const InactiveChannelBanner = ({ storeId }: { storeId?: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { inactiveSaleChannelCount: mkpCount },
  } = useOnboardingCtx();
  const [isClosed, setIsClosed] = useState(false);

  const crmCount = useCrmCount({ storeId });
  const count = mkpCount + crmCount;

  const closeBanner = () => setIsClosed(true);

  return (
    <>
      {!isClosed && count > 0 && (
        <AlertBanner
          type="warning"
          title={t('core.page.home.banner.title', {
            ns: 'core',
            count,
          })}
          onClose={closeBanner}
          withCloseButton
          classNames={{
            root: 'mb-4 shadow-banner',
          }}
        >
          <>
            <p>
              {t('core.page.home.banner.description', {
                ns: 'core',
                count,
              })}
            </p>
            <Button
              variant="default"
              onClick={() =>
                navigate(IntegrationRoutePath({ storeId, singleStoreMode: false }).list().path)
              }
            >
              {t('shared.action.goTo', {
                ns: 'shared',
                entity: t('shared.entity.integration', { ns: 'shared', count: 2 }),
              })}
            </Button>
          </>
        </AlertBanner>
      )}
    </>
  );
};

function useCrmCount({ storeId }: { storeId?: string }): number {
  return useCrmConnectionErrorCount({
    storeId,
  });
}
