import dayjs from 'dayjs';

import {
  MarketplaceSaleChannelType,
  ReportChartTab,
  ReportDimensions,
  SaleChannelType,
} from '~anyx/common/graphql';
import { CurrenciesEnum, DateUtils, NumberUtils } from '~anyx/shared/utils';

import {
  AnalyticsMetricOption,
  HomeMetricOption,
  ReportMetricMappingKey,
  NumberFormat,
  DDISalesChannelAnalyticsMetricOption,
} from '../config';

export enum ReportDateFormat {
  CHART = 'yyyy-MM-dd',
}

export type ReportChartProps = {
  metricList: Record<
    AnalyticsMetricOption | HomeMetricOption,
    {
      mappingKey: ReportMetricMappingKey;
      numberFormat: NumberFormat;
    }
  >;
  skip?: boolean;
};

export enum StoreChartId {
  OTHER = 'others',
}

export type DefaultMetricObject = Record<string, unknown> & {
  key: string | number;
  name: string;
  numberFormat?: NumberFormat;
  currency?: CurrenciesEnum;
  total?: number;
};

export type MetricData = Array<DefaultMetricObject>;

export const CHANNEL_COLOR: Record<
  MarketplaceSaleChannelType,
  { default: string; byRegion?: string[] }
> = {
  [MarketplaceSaleChannelType.AmazonSellerCentral]: {
    default: '#F69B06',
    byRegion: ['#F69B06', '#F9B951', '#FBCD82', '#FCD79B', '#FCE1B4'],
  },
  [MarketplaceSaleChannelType.Lazada]: {
    default: '#F80CA4',
    byRegion: ['#F80CA4', '#FB6DC8', '#FC9EDB', '#FDB6E4', '#FECEED'],
  },
  [MarketplaceSaleChannelType.Shopee]: {
    default: '#EE4D2D',
    byRegion: ['#EE4D2D', '#F3826C', '#F7A696', '#F8B8AB', '#FCDBD5'],
  },
  [MarketplaceSaleChannelType.Shopify]: { default: '#95BF47' },
  [MarketplaceSaleChannelType.Rakuten]: { default: '#BF0000' },
  [MarketplaceSaleChannelType.Yahoo]: { default: '#FF0033' },
  [MarketplaceSaleChannelType.TikTok]: {
    default: '#0FEDE6',
    byRegion: ['#0FEDE6', '#6FF4F0', '#B7FAF8', '#0FEDE633'],
  },
  [MarketplaceSaleChannelType.Zozotown]: { default: '#000000' },
  [MarketplaceSaleChannelType.Tokopedia]: { default: '#4F9D4D' },
  [MarketplaceSaleChannelType.SuperDelivery]: { default: '#003B91' },
  [MarketplaceSaleChannelType.Qoo10]: { default: '#ED2F3D' },
  [MarketplaceSaleChannelType.Momo]: { default: '#E40080' },
  [MarketplaceSaleChannelType.MakeshopByGmo]: { default: '#2389CA' },
  [MarketplaceSaleChannelType.Stores]: { default: '#FBA7AF' },
  [MarketplaceSaleChannelType.FacebookShop]: { default: '#1877F2' },
  [MarketplaceSaleChannelType.AuPayMarket]: { default: '#EB5505' },
  [MarketplaceSaleChannelType.Magaseek]: { default: '#A74FFF' },
  [MarketplaceSaleChannelType.RakutenFashion]: { default: '#FF7474' },
  [MarketplaceSaleChannelType.Shoplist]: { default: '#00E5BC' },
  [MarketplaceSaleChannelType.Manual]: { default: '#A8B4BF' },
};

export const DEFAULT_COLORS = [
  '#315FCE',
  '#3892E5',
  '#FFD058',
  '#A46CEB',
  '#60E6C5',
  '#C6EF53',
  '#806BFF',
  '#06DDDD',
  '#1ACD61',
  '#93EA47',
  '#A8B4BF',
];

export const getChannelColors = (channels: SaleChannelType[]) => {
  return channels.reduce((result, channel) => {
    return {
      ...result,
      [channel]: CHANNEL_COLOR[channel]?.['default'],
    };
  }, {} as Record<SaleChannelType, string>);
};

export const getStoreColors = (masterDataStoreIds: string[]): Record<string, string> => {
  return masterDataStoreIds.reduce((result, storeId, index) => {
    return {
      ...result,
      [storeId || StoreChartId.OTHER]: DEFAULT_COLORS[index],
    };
  }, {});
};

type DimensionKey = 'yearMonth' | 'yearWeek' | 'dow' | 'channel' | 'day';

export const dimensionList: Record<ReportDimensions, DimensionKey> = {
  [ReportDimensions.MONTH]: 'yearMonth',
  [ReportDimensions.WEEK]: 'yearWeek',
  [ReportDimensions.DOW]: 'dow',
  [ReportDimensions.CHANNELS]: 'channel',
  [ReportDimensions.DAY]: 'day',
};

export enum SaleChannelChartType {
  STACKED_BAR = 'stacked-bar',
  CHANNEL_STACKED_BAR = 'channel-stacked-bar',
  BAR = 'bar',
  LINE = 'line',
}

const lineChartDimensions: ReportDimensions[] = [
  ReportDimensions.DAY,
  ReportDimensions.WEEK,
  ReportDimensions.MONTH,
];

const stackedBarChartDimensions: ReportDimensions[] = [ReportDimensions.DOW];

const channelBarChartDimensions: ReportDimensions[] = [ReportDimensions.CHANNELS];

const lineChartMetrics: AnalyticsMetricOption[] = [
  AnalyticsMetricOption.AVG_ORDER_VALUE,
  AnalyticsMetricOption.GROSS_PROFIT_RATE,
  AnalyticsMetricOption.MARGINAL_PROFIT_RATE,
  AnalyticsMetricOption.ROAS,
  AnalyticsMetricOption.CVR,
];

const stackedBarChartMetrics: (AnalyticsMetricOption | DDISalesChannelAnalyticsMetricOption)[] = [
  AnalyticsMetricOption.GROSS_SALES,
  AnalyticsMetricOption.ORDERS,
  AnalyticsMetricOption.AVG_ORDER_VALUE,
  AnalyticsMetricOption.GROSS_PROFIT,
  AnalyticsMetricOption.GROSS_PROFIT_RATE,
  AnalyticsMetricOption.MARGINAL_PROFIT,
  AnalyticsMetricOption.MARGINAL_PROFIT_RATE,
  DDISalesChannelAnalyticsMetricOption.GROSS_SALES,
  DDISalesChannelAnalyticsMetricOption.NET_SALES,
  DDISalesChannelAnalyticsMetricOption.ORDERS,
];

export const getChartType = ({
  dimension,
  metric,
}: {
  chartTab?: ReportChartTab;
  dimension: ReportDimensions;
  metric: AnalyticsMetricOption | DDISalesChannelAnalyticsMetricOption;
}) => {
  if (channelBarChartDimensions.includes(dimension)) {
    return SaleChannelChartType.CHANNEL_STACKED_BAR;
  }

  if (lineChartDimensions.includes(dimension) && lineChartMetrics.includes(metric)) {
    return SaleChannelChartType.LINE;
  }

  if (stackedBarChartDimensions.includes(dimension) && stackedBarChartMetrics.includes(metric)) {
    return SaleChannelChartType.STACKED_BAR;
  }

  return SaleChannelChartType.STACKED_BAR;
};

export const getTotalMetricValue = (
  metricValueArray: Record<string, unknown>[],
  metricMappingKey: ReportMetricMappingKey
) => {
  return metricValueArray.reduce(
    (total, item) => total + NumberUtils.toNumber(item[metricMappingKey]),
    0
  );
};

export const generateDateRangeArray = (startDate: Date, endDate: Date) => {
  const dateRangeArray = [];
  const currentDate = startDate;

  if (dayjs(startDate).isSame(endDate, 'day')) {
    return [dayjs(startDate).format(DateUtils.FORMATS.YYYYMMDD)];
  }
  while (currentDate <= endDate) {
    dateRangeArray.push(
      DateUtils.formatDateInTimeZone(currentDate, { formatStr: DateUtils.FORMATS.YYYYMMDD })
    );
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateRangeArray;
};

export const calculatePercentage = (numerator: number, denominator: number) => {
  return isFinite(numerator / denominator) ? (numerator / denominator) * 100 : 0;
};
