import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/common/graphql';

const ZozotownAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.ZozotownAddPage }))
);

const ZozotownEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.ZozotownEditPage }))
);

const ZozotownTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ZozotownTemplate }))
);

export const zozotownRouting = [
  {
    path: MarketplaceSaleChannelType.Zozotown,
    element: <ZozotownTemplate />,
    children: [
      {
        index: true,
        element: <ZozotownAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            index: true,
            element: <ZozotownEditPage />,
          },
        ],
      },
    ],
  },
];
