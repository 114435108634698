import { Trans, useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { useUserLanguage } from '~anyx/common/self';
import { LoadWrapper, SkeletonLoader } from '~anyx/shared/ui';
import { DateUtils, LanguageUtils, NumberUtils } from '~anyx/shared/utils';

import { GoogleTranslateWrapper } from '../google-translate-wrapper';
import { RatingComment } from '../rating-comment';

import { useGoogleTranslate, useGradientScroll } from './hooks';

interface CommentListProps {
  reviews: readonly {
    comment: string;
    date: string;
    rating: string;
  }[];
  loading: boolean;
  className?: string;
}

export const CommentList = ({ reviews, loading, className }: CommentListProps) => {
  const { t } = useTranslation('report');
  const { showGradient, scrollContainerRef } = useGradientScroll();
  const { translate, reset, isTranslated } = useGoogleTranslate();
  const language = useUserLanguage();
  return (
    <GoogleTranslateWrapper
      reset={reset}
      translate={translate}
      isTranslated={isTranslated}
      loading={loading}
    >
      <div
        ref={scrollContainerRef}
        // Adding translate class to detect where to translate using google translate script
        className={classNames('translate flex flex-col gap-4 p-4 md:overflow-y-auto', className)}
      >
        <div className="notranslate">
          <div className="text-2xs bg-gray-0 inline-block w-full rounded px-2 py-1 leading-10">
            {isTranslated ? (
              <Trans
                components={{
                  languageText: <span className="font-semibold" />,
                  buttonText: (
                    <button className="text-link hover:underline" onClick={() => reset()} />
                  ),
                }}
                i18nKey={t('report.page.product.originalReview', {
                  language: t('shared.enum.language', {
                    ns: 'shared',
                    language: LanguageUtils.asLanguageTranslationKey(language),
                  }),
                  ns: 'report',
                })}
              />
            ) : (
              <Trans
                components={{
                  languageText: <span className="font-semibold" />,
                  buttonText: (
                    <button className="text-link hover:underline" onClick={() => translate()} />
                  ),
                }}
                i18nKey={t('report.page.product.translateReview', {
                  ns: 'report',
                })}
              />
            )}
          </div>
        </div>
        <LoadWrapper
          loading={loading}
          loadingTemplate={Array.from({ length: 10 }).map((_, i) => (
            <div key={i} className="shadow-card2 flex flex-col gap-4 rounded-lg p-4">
              <div className="flex items-center gap-4">
                <SkeletonLoader loading className="w-[4.375rem]" />
                <SkeletonLoader loading className="w-20" />
              </div>
              <div className="flex flex-col gap-2">
                <SkeletonLoader loading />
                <SkeletonLoader loading className="w-1/2" />
              </div>
            </div>
          ))}
        >
          {reviews.length > 0 ? (
            reviews.map((review, index) => (
              <RatingComment
                key={`${review.date}-${index}`}
                averageRating={NumberUtils.toNumber(review.rating)}
                title={DateUtils.formatDateInTimeZone(review.date, {
                  formatStr: DateUtils.FORMATS.MMDDYYYY,
                })}
                content={review.comment}
              />
            ))
          ) : (
            <p className="text-sm">{t('report.page.product.noReviews', { ns: 'report' })}</p>
          )}
        </LoadWrapper>
        <p className="text-gray-7 text-2xs">
          {t('report.page.product.reviewListingDescription', { ns: 'report' })}
        </p>
        {showGradient && (
          <div className="bg-bottomGradient absolute bottom-0 left-0 right-0 h-24 border-0" />
        )}
      </div>
    </GoogleTranslateWrapper>
  );
};
