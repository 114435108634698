import { useCallback } from 'react';

import { MutationHookOptions, useLazyQuery } from '@apollo/client';

import {
  GetCsvUploadTemplateQuery,
  GetCsvUploadTemplateQueryVariables,
  gql,
} from '~anyx/common/graphql';

const GET_CSV_UPLOAD_TEMPLATE = gql(/* GraphQL */ `
  query GetCsvUploadTemplate(
    $language: Language!
    $resource: Resource!
    $operation: CsvImportOperation!
  ) {
    getCsvUploadTemplate(language: $language, resource: $resource, operation: $operation) {
      columns
      filename
    }
  }
`);

export const useDownloadAnylogiCsvTemplate = ({
  options,
  onFailureNotification,
}: {
  options?: MutationHookOptions<GetCsvUploadTemplateQuery, GetCsvUploadTemplateQueryVariables>;
  onFailureNotification?: (e?: unknown) => void;
}) => {
  const [getTemplateLazyQuery, { loading }] = useLazyQuery(GET_CSV_UPLOAD_TEMPLATE);

  const handleDownloadAnylogiCsvTemplate = useCallback(
    async ({ language, operation, resource }: GetCsvUploadTemplateQueryVariables) => {
      try {
        const result = await getTemplateLazyQuery({
          ...options,
          variables: {
            language,
            operation,
            resource,
          },
        });

        if (!result?.data?.getCsvUploadTemplate) {
          onFailureNotification?.();
          return null;
        }
        return {
          headers: result.data.getCsvUploadTemplate.columns || [],
          name: result?.data?.getCsvUploadTemplate?.filename,
        };
      } catch (e: unknown) {
        onFailureNotification?.(e);
      }
      return null;
    },
    [getTemplateLazyQuery, options, onFailureNotification]
  );

  return { handleDownloadAnylogiCsvTemplate, loading };
};
