import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type YahooSalesReportChartQueryVariables = Types.Exact<{
  input: Types.YahooSalesReportChartInput;
}>;


export type YahooSalesReportChartQuery = { readonly yahooSalesReportChart: { readonly __typename?: 'YahooSalesReportChartPayload', readonly avgSpendPerCustomer: ReadonlyArray<Record<string, any>>, readonly currency: Types.ReportSupportedCurrency, readonly orderCount: ReadonlyArray<Record<string, any>>, readonly totalSales: ReadonlyArray<Record<string, any>>, readonly unitSoldCount: ReadonlyArray<Record<string, any>> } };


export const YahooSalesReportChartDocument = gql`
    query YahooSalesReportChart($input: YahooSalesReportChartInput!) {
  yahooSalesReportChart(input: $input) {
    avgSpendPerCustomer
    currency
    orderCount
    totalSales
    unitSoldCount
  }
}
    `;

/**
 * __useYahooSalesReportChartQuery__
 *
 * To run a query within a React component, call `useYahooSalesReportChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useYahooSalesReportChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYahooSalesReportChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useYahooSalesReportChartQuery(baseOptions: Apollo.QueryHookOptions<YahooSalesReportChartQuery, YahooSalesReportChartQueryVariables> & ({ variables: YahooSalesReportChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YahooSalesReportChartQuery, YahooSalesReportChartQueryVariables>(YahooSalesReportChartDocument, options);
      }
export function useYahooSalesReportChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YahooSalesReportChartQuery, YahooSalesReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YahooSalesReportChartQuery, YahooSalesReportChartQueryVariables>(YahooSalesReportChartDocument, options);
        }
export function useYahooSalesReportChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<YahooSalesReportChartQuery, YahooSalesReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<YahooSalesReportChartQuery, YahooSalesReportChartQueryVariables>(YahooSalesReportChartDocument, options);
        }
export type YahooSalesReportChartQueryHookResult = ReturnType<typeof useYahooSalesReportChartQuery>;
export type YahooSalesReportChartLazyQueryHookResult = ReturnType<typeof useYahooSalesReportChartLazyQuery>;
export type YahooSalesReportChartSuspenseQueryHookResult = ReturnType<typeof useYahooSalesReportChartSuspenseQuery>;
export type YahooSalesReportChartQueryResult = Apollo.QueryResult<YahooSalesReportChartQuery, YahooSalesReportChartQueryVariables>;
export function refetchYahooSalesReportChartQuery(variables: YahooSalesReportChartQueryVariables) {
      return { query: YahooSalesReportChartDocument, variables: variables }
    }