import React from 'react';

import { WmsType } from '~anyx/common/graphql';

const AnylogiAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.AnylogiAddPage }))
);

const AnylogiEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.AnylogiEditPage }))
);

const AnylogiPendingPage = React.lazy(() =>
  import('./pages/pending').then((module) => ({ default: module.AnylogiPendingPage }))
);

const AnylogiRequestPage = React.lazy(() =>
  import('./pages/request').then((module) => ({
    default: module.AnylogiRequestPage,
  }))
);

const AnylogiTemplate = React.lazy(() =>
  import('./templates').then((module) => ({
    default: module.AnylogiTemplate,
  }))
);

export const anylogiIntegrationRoutes = [
  {
    path: WmsType.LOGILESS,
    element: <AnylogiTemplate />,
    children: [
      {
        index: true,
        element: <AnylogiAddPage />,
      },
      {
        path: 'request',
        element: <AnylogiRequestPage />,
      },
      {
        path: 'pending',
        element: <AnylogiPendingPage />,
      },
      {
        path: ':saleChannelId',
        children: [
          {
            index: true,
            element: <AnylogiEditPage />,
          },
        ],
      },
    ],
  },
];
