import { generatePath } from 'react-router';

import { FALLBACK_ROUTE } from './fallbackRoute';

/**
 * Creates a URL path based on the provided pattern and parameters.
 * If the pattern or parameters are invalid, it tries to sanitize the pattern
 * by removing undefined parameters. If errors persist, it falls back to a default route.
 *
 * @param {string} pattern - The URL pattern to be used, which can include placeholders like ':id'.
 * @param {Record<string, unknown>} [params] - The parameters to replace in the URL pattern.
 * @param {string[]} [requiredFields=[]] - List of fields that must be present in the parameters; others are ignored.
 * @param {string} [fallbackRoute=FALLBACK_ROUTE] - The default route to use if the pattern generation fails.
 * @returns {{pattern: string, path: string}} An object containing the original or modified pattern and the generated path.
 */
export const createPath = (
  pattern: string,
  params?: Record<string, unknown>,
  requiredFields: string[] = [],
  fallbackRoute = FALLBACK_ROUTE
) => {
  try {
    return { pattern, path: generatePath(pattern, params) };
  } catch (error) {
    try {
      // Regex pattern to capture text matches like ':parameterId'
      const test = pattern.match(/:.*?\/|(?::[^:\\]+(?!.*:))/g);

      let finalPattern = pattern;
      test?.forEach((value) => {
        // Sanitize value to remove special characters
        if (!requiredFields.includes(value.replace(/[^a-zA-Z ]/g, ''))) {
          finalPattern = finalPattern.replace(value, '');
        }
      });

      finalPattern = finalPattern.replace(/\/$/, '');
      return { pattern: finalPattern, path: generatePath(finalPattern, params) };
    } catch (error) {
      return { pattern: fallbackRoute, path: generatePath(fallbackRoute) };
    }
  }
};
