import React from 'react';
import { RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { Auth0Permissions, Restricted } from '~anyx/common/permission';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const AmazonSalesReportPage = React.lazy(() =>
  import('./pages/amazon').then((module) => ({
    default: module.AmazonSalesReportPage,
  }))
);

const LazadaSalesReportPage = React.lazy(() =>
  import('./pages/lazada').then((module) => ({
    default: module.LazadaSalesReportPage,
  }))
);

const RakutenSalesReportPage = React.lazy(() =>
  import('./pages/rakuten').then((module) => ({
    default: module.RakutenSalesReportPage,
  }))
);

const ShopeeSalesReportPage = React.lazy(() =>
  import('./pages/shopee').then((module) => ({
    default: module.ShopeeSalesReportPage,
  }))
);

const ShopifySalesReportPage = React.lazy(() =>
  import('./pages/shopify').then((module) => ({
    default: module.ShopifySalesReportPage,
  }))
);

const TikTokSalesReportPage = React.lazy(() =>
  import('./pages/tik-tok').then((module) => ({
    default: module.TikTokSalesReportPage,
  }))
);

const TokopediaSalesReportPage = React.lazy(() =>
  import('./pages/tokopedia').then((module) => ({
    default: module.TokopediaSalesReportPage,
  }))
);

const YahooSalesReportPage = React.lazy(() =>
  import('./pages/yahoo').then((module) => ({
    default: module.YahooSalesReportPage,
  }))
);

const SalesChannelAnalyticTemplate = React.lazy(() =>
  import('./templates').then((module) => ({
    default: module.SalesChannelAnalyticTemplate,
  }))
);

export const routes: RouteObject[] = [
  {
    path: 'sales-channel-analytic',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_ANALYTICS]]}
          fallback={<UnauthorizedMessage />}
        >
          <SalesChannelAnalyticTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'amazon',
        element: <AmazonSalesReportPage />,
      },
      {
        path: 'lazada',
        element: <LazadaSalesReportPage />,
      },
      {
        path: 'rakuten',
        element: <RakutenSalesReportPage />,
      },
      {
        path: 'shopee',
        element: <ShopeeSalesReportPage />,
      },
      {
        path: 'shopify',
        element: <ShopifySalesReportPage />,
      },
      {
        path: 'tik-tok',
        element: <TikTokSalesReportPage />,
      },
      {
        path: 'tokopedia',
        element: <TokopediaSalesReportPage />,
      },
      {
        path: 'yahoo',
        element: <YahooSalesReportPage />,
      },
    ],
  },
];
