import { generatePath } from 'react-router';

function createPath(pattern: string, params?: Record<string, unknown>) {
  return { pattern, path: generatePath(pattern, params) };
}

export const workflowPaths = (storeId = '') => {
  return {
    core: () => ({
      ...createPath('/:storeId/workflow', { storeId }),
      list: () => createPath('/:storeId/workflow/list', { storeId }),
      add: () => createPath('/:storeId/workflow/add', { storeId }),
      edit: (workflowId = '') =>
        createPath('/:storeId/workflow/:workflowId', { storeId, workflowId }),
    }),
    auditLog: (workflowId = '') =>
      createPath('/:storeId/workflow/:workflowId/audit-log', {
        storeId,
        workflowId,
      }),
  };
};
