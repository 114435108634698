import React from 'react';
import { RouteObject, Navigate } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { Auth0Permissions, Restricted } from '~anyx/common/permission';
import { FALLBACK_ROUTE, ProductRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const ProductListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.ProductListPage }))
);
const ProductAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.ProductAddPage }))
);
const ProductEditPage = React.lazy(() =>
  import('./pages/edit/product-edit/ProductEditPage').then((module) => ({
    default: module.ProductEditPage,
  }))
);
const ProductVariantEditPage = React.lazy(() =>
  import('./pages/edit/product-variant-edit/ProductVariantEditPage').then((module) => ({
    default: module.ProductVariantEditPage,
  }))
);
const ProductTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ProductTemplate }))
);
const ProductEditTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ProductEditTemplate }))
);
const DDIProductBulkPage = React.lazy(() =>
  import('./pages/bulk').then((module) => ({ default: module.DDIProductBulkPage }))
);
export const routes: RouteObject[] = [
  {
    path: 'product',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_PRODUCT]]}
          fallback={<UnauthorizedMessage />}
        >
          <ProductTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <ProductListPage />,
      },
      {
        path: 'add',
        element: <ProductAddPage />,
      },
      {
        path: 'bulk',
        element: <DDIProductBulkPage />,
      },
      {
        path: ':productId',
        element: <ProductEditTemplate />,
        children: [
          {
            index: true,
            element: <ProductEditPage />,
          },
          {
            path: ':variantId',
            element: <ProductVariantEditPage />,
          },
          { element: <Navigate to={FALLBACK_ROUTE} replace={true} /> },
        ],
      },
      {
        index: true,
        element: <Navigate to={ProductRoutePath().list().path} replace={true} />,
      },
    ],
  },
];
