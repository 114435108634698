import React from 'react';

import { DatasourceType } from '~anyx/common/graphql';

const TikTokEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({
    default: module.TikTokEditPage,
  }))
);

const TikTokAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({
    default: module.TikTokAddPage,
  }))
);

const TikTokCampaignAdSetListPage = React.lazy(() =>
  import('./pages/campaign/campaign-adset-list').then((module) => ({
    default: module.TikTokCampaignAdSetListPage,
  }))
);

const TikTokCampaignAdSetEditPage = React.lazy(() =>
  import('./pages/campaign/campaign-adset-edit').then((module) => ({
    default: module.TikTokCampaignAdSetEditPage,
  }))
);

const TikTokTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.TikTokTemplate }))
);

export const tikTokRouting = [
  {
    path: DatasourceType.TikTokAds,
    element: <TikTokTemplate />,
    children: [
      {
        index: true,
        element: <TikTokAddPage />,
      },
      {
        path: ':datasourceId',
        children: [
          {
            index: true,
            element: <TikTokEditPage />,
          },
          {
            path: 'campaign/:campaignId/adSet/:adSetMappingCampaignId',
            children: [
              {
                index: true,
                element: <TikTokCampaignAdSetListPage />,
              },
              {
                path: 'set',
                element: <TikTokCampaignAdSetEditPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];
