import React from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted, Auth0Permissions } from '~anyx/common/permission';
import { SettingsRoutePath } from '~anyx/common/routing';
import { upsRoutes, dhlRoutes, fedexRoutes, japanPostRoutes } from '~anyx/integrations/couriers';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const SettingsTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.SettingsTemplate }))
);

const SettingsEditTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.SettingsEditTemplate }))
);

const SettingsListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.SettingsListPage }))
);

const SettingsEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.SettingsEditPage }))
);

// Shipper Pages
const ShipperListPage = React.lazy(() =>
  import('../shipper/pages/list').then((module) => ({ default: module.ShipperListPage }))
);
const ShipperEditPage = React.lazy(() =>
  import('../shipper/pages/edit').then((module) => ({ default: module.ShipperEditPage }))
);
const ShipperAddPage = React.lazy(() =>
  import('../shipper/pages/add').then((module) => ({ default: module.ShipperAddPage }))
);

// Couriers Pages
const CouriersListPage = React.lazy(() =>
  import('../couriers/pages/list').then((module) => ({ default: module.CouriersListPage }))
);

const CourierSelectPage = React.lazy(() =>
  import('../couriers/pages/select').then((module) => ({
    default: module.CourierProviderSelectPage,
  }))
);

export const routes: RouteObject[] = [
  {
    path: 'shipping-settings',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_SHIPPING]]}
          fallback={<UnauthorizedMessage />}
        >
          <SettingsTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <SettingsListPage />,
      },
      {
        path: ':accountId',
        element: <SettingsEditTemplate />,
        children: [
          {
            index: true,
            element: <SettingsEditPage />,
          },
          // Shipper
          {
            path: 'shipper/list',
            element: <ShipperListPage />,
          },
          {
            path: 'shipper/:shipperId',
            element: <ShipperEditPage />,
          },
          {
            path: 'shipper/add',
            element: <ShipperAddPage />,
          },
          // Couriers
          {
            path: 'couriers',
            element: <Outlet />,
            children: [
              ...upsRoutes,
              ...dhlRoutes,
              ...fedexRoutes,
              ...japanPostRoutes,
              {
                path: 'select',
                element: <CourierSelectPage />,
              },
              {
                path: 'list',
                element: <CouriersListPage />,
              },
            ],
          },
        ],
      },
      {
        index: true,
        element: (
          <Navigate to={SettingsRoutePath({ workspaceStoreId: null }).list().path} replace={true} />
        ),
      },
    ],
  },
];
