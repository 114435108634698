import { createContext, useCallback, useContext, useMemo } from 'react';

import { z } from 'zod';

import { MdUserRole } from '~anyx/common/graphql';
import { useFeatureFlag } from '~anyx/external/firebase';

import { Permission, PermissionType, Restriction } from '../../models';

type PermissionContextType = {
  isAllowedTo: (permission: Permission[][]) => boolean;
  isRestrictedTo: (restrictions: Restriction[][]) => boolean;
  isUserRole: (roles: MdUserRole[]) => boolean;
};

export interface PermissionCtxProps {
  permissions?: Permission[][];
  restrictions?: Restriction[][];
  roles?: MdUserRole[];
  mixConditions?: PermissionType[];
}

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => {
    throw new Error('isAllowedTo is not initialized properly');
  },
  isRestrictedTo: () => {
    throw new Error('isRestrictedTo is not initialized properly');
  },
  isUserRole: () => {
    throw new Error('isUserRole is not initialized properly');
  },
};

export const PermissionContext = createContext<PermissionContextType>(defaultBehaviour);

export const useValidatePermission = () => {
  const { isAllowedTo, isUserRole, isRestrictedTo } = useContext(PermissionContext);
  const validatePermission = useCallback(
    ({ restrictions, permissions, roles, mixConditions }: PermissionCtxProps) => {
      try {
        const conditions = [];
        // true if not restricted,
        // false if restricted,
        // in order to make check below easier
        conditions.push(restrictions ? !isRestrictedTo(restrictions) : true);
        conditions.push(permissions ? isAllowedTo(permissions) : true);
        conditions.push(roles ? isUserRole(roles) : true);

        const allConditionMet = conditions.every((condition) => condition);

        if (mixConditions) {
          let validCombination = false;
          if (mixConditions?.includes(PermissionType.RESTRICTION)) {
            validCombination = !!(validCombination || conditions[0]);
          }
          if (mixConditions?.includes(PermissionType.PERMISSION)) {
            validCombination = !!(validCombination || conditions[1]);
          }
          if (mixConditions?.includes(PermissionType.ROLE)) {
            validCombination = !!(validCombination || conditions[2]);
          }
          return validCombination || allConditionMet;
        }
        return allConditionMet;
      } catch (error) {
        console.error(`Unable to verify conditions, ${error}`);
        return false;
      }
    },
    [isAllowedTo, isRestrictedTo, isUserRole]
  );

  return { validatePermission };
};

export const usePermissionCtx = (props: PermissionCtxProps) => {
  const { validatePermission } = useValidatePermission();

  const allowed = useMemo(() => {
    return validatePermission(props);
  }, [validatePermission, props]);

  return { allowed };
};

export const useIsAdminOrStaff = (props?: Omit<PermissionCtxProps, 'roles'>) => {
  const { allowed: isAdminOrStaff, ...rest } = usePermissionCtx({
    roles: [MdUserRole.ADMIN, MdUserRole.STAFF],
    ...props,
  });

  return {
    isAdminOrStaff,
    ...rest,
  };
};

export const useIsAdmin = (props?: Omit<PermissionCtxProps, 'roles'>) => {
  const { allowed: isAdmin, ...rest } = usePermissionCtx({
    roles: [MdUserRole.ADMIN],
    ...props,
  });

  return {
    isAdmin,
    ...rest,
  };
};

export const useIsStaff = (props?: Omit<PermissionCtxProps, 'roles'>) => {
  const { allowed: isStaff, ...rest } = usePermissionCtx({
    roles: [MdUserRole.STAFF],
    ...props,
  });

  return {
    isStaff,
    ...rest,
  };
};

export const useIsMerchant = (props?: Omit<PermissionCtxProps, 'roles'>) => {
  const { allowed: isMerchant, ...rest } = usePermissionCtx({
    roles: [MdUserRole.MERCHANT],
    ...props,
  });

  return {
    isMerchant,
    ...rest,
  };
};

// TODO REPORTING: Will replace with backend API
export const rpDdiFlagSchema = z.object({
  allowedAccountIds: z.array(z.string()),
});

export const useIsUnderDDIAccount = (accountIds: readonly string[]) => {
  const rpDDIAccountFlag = useFeatureFlag('rp_ddi_active', { as: 'string' });
  const rpDDIAccountFlagValue = rpDdiFlagSchema.safeParse(
    JSON.parse(rpDDIAccountFlag?.value || '{}')
  );
  const underDDIAccountIds = rpDDIAccountFlagValue.success
    ? rpDDIAccountFlagValue.data?.allowedAccountIds
    : [];

  const isUnderDDIAccount = accountIds.some((id) => underDDIAccountIds.includes(id));
  return {
    isUnderDDIAccount,
  };
};
