import React from 'react';

import { MarketplaceSaleChannelType } from '~anyx/common/graphql';

const RakutenAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.RakutenAddPage }))
);

const RakutenEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.RakutenEditPage }))
);

const RakutenMappingEditPage = React.lazy(() =>
  import('./pages/mapping/set').then((module) => ({
    default: module.RakutenMappingEditPage,
  }))
);

const RakutenTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.RakutenTemplate }))
);

export const rakutenRouting = [
  {
    path: MarketplaceSaleChannelType.Rakuten,
    element: <RakutenTemplate />,
    children: [
      {
        index: true,
        element: <RakutenAddPage />,
      },
      {
        path: ':channelId',
        children: [
          {
            path: 'mapping/edit',
            element: <RakutenMappingEditPage />,
          },
          {
            index: true,
            element: <RakutenEditPage />,
          },
        ],
      },
    ],
  },
];
