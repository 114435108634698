import { MdAccountProduct } from '~anyx/common/graphql';
import { getDomainProduct } from '~anyx/shared/domain-product';
import { ExternalIcon, QuestionCircleIcon } from '~anyx/shared/icons';
import { AnyXLanguage, DocUtils, RouteLinks } from '~anyx/shared/utils';

const getGitBookByProduct = (product: MdAccountProduct) => {
  if (product === MdAccountProduct.ANYCHAT) {
    return DocUtils.getGitbookLink('/crm-customer-management', {
      [AnyXLanguage.JA_JP]: '/crm/chats',
    });
  }

  return DocUtils.getGitbookLink('/');
};

const getHelpLinks = (): RouteLinks[] => {
  const product = getDomainProduct();

  return [
    {
      category: {
        type: 'link',
        name: {
          key: 'shared.button.help',
          options: { ns: 'shared' },
        },
        icon: (props) => <QuestionCircleIcon {...props} />,
        href: getGitBookByProduct(product),
        aside: <ExternalIcon className="stroke-gray-7 h-3.5 w-3.5" />,
      },
    },
  ];
};

export const helperLinks = {
  noWorkspace: getHelpLinks,
  withWorkspace: getHelpLinks,
};
