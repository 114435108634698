import { ApolloClientHelpers } from '~anyx/common/graphql';
import { LanguageUtils } from '~anyx/shared/utils';

export const AccountFunctionApolloFieldPolicies: ApolloClientHelpers.StrictTypedTypePolicies = {
  MdUser: {
    keyFields: ['id'],
    merge(existing, incoming, options) {
      const firstName = options.readField<string | undefined>('firstName', incoming);
      const lastName = options.readField<string | undefined>('lastName', incoming);

      const name = LanguageUtils.localizeName(firstName, lastName);

      return options.mergeObjects(options.mergeObjects(existing, incoming), {
        name,
      });
    },
  },
};
