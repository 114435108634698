import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TokopediaSalesSummaryQueryVariables = Types.Exact<{
  input: Types.TokopediaSalesSummaryInput;
}>;


export type TokopediaSalesSummaryQuery = { readonly tokopediaSalesSummary: { readonly __typename?: 'TokopediaSalesSummaryPayload', readonly currency: Types.ReportSupportedCurrency, readonly orders: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string }, readonly sales: { readonly __typename?: 'NumericValueWithDiffPayload', readonly diff: string, readonly diffPercentage: string, readonly value: string } } };


export const TokopediaSalesSummaryDocument = gql`
    query TokopediaSalesSummary($input: TokopediaSalesSummaryInput!) {
  tokopediaSalesSummary(input: $input) {
    currency
    orders {
      diff
      diffPercentage
      value
    }
    sales {
      diff
      diffPercentage
      value
    }
  }
}
    `;

/**
 * __useTokopediaSalesSummaryQuery__
 *
 * To run a query within a React component, call `useTokopediaSalesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokopediaSalesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokopediaSalesSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTokopediaSalesSummaryQuery(baseOptions: Apollo.QueryHookOptions<TokopediaSalesSummaryQuery, TokopediaSalesSummaryQueryVariables> & ({ variables: TokopediaSalesSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokopediaSalesSummaryQuery, TokopediaSalesSummaryQueryVariables>(TokopediaSalesSummaryDocument, options);
      }
export function useTokopediaSalesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokopediaSalesSummaryQuery, TokopediaSalesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokopediaSalesSummaryQuery, TokopediaSalesSummaryQueryVariables>(TokopediaSalesSummaryDocument, options);
        }
export function useTokopediaSalesSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TokopediaSalesSummaryQuery, TokopediaSalesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TokopediaSalesSummaryQuery, TokopediaSalesSummaryQueryVariables>(TokopediaSalesSummaryDocument, options);
        }
export type TokopediaSalesSummaryQueryHookResult = ReturnType<typeof useTokopediaSalesSummaryQuery>;
export type TokopediaSalesSummaryLazyQueryHookResult = ReturnType<typeof useTokopediaSalesSummaryLazyQuery>;
export type TokopediaSalesSummarySuspenseQueryHookResult = ReturnType<typeof useTokopediaSalesSummarySuspenseQuery>;
export type TokopediaSalesSummaryQueryResult = Apollo.QueryResult<TokopediaSalesSummaryQuery, TokopediaSalesSummaryQueryVariables>;
export function refetchTokopediaSalesSummaryQuery(variables: TokopediaSalesSummaryQueryVariables) {
      return { query: TokopediaSalesSummaryDocument, variables: variables }
    }