import { CrossBorderCourierProvider } from '~anyx/common/graphql';
import { useWorkspaceCtx } from '~anyx/common/workspace';

import { createPath } from '../../../utils';
import { BaseRouteProps } from '../../types/route-type';

/*
 * @param workspaceStoreId set the workspace store ID to the url, use current workspace store ID by default.
 * @returns string
 */
export const CourierRoutePath = ({
  workspaceStoreId = useWorkspaceCtx.getState().workspace?.storeId,
}: BaseRouteProps = {}) => {
  const root = createPath(workspaceStoreId ? '/:workspaceStoreId/couriers' : '/couriers', {
    workspaceStoreId,
  });

  return {
    root: () => root,
    list: () => createPath(`${root.path}/list`),
    select: () => createPath(`${root.path}/select`),
    add: ({ courierType }: { courierType: CrossBorderCourierProvider }) =>
      createPath(`${root.path}/:courierType`, { courierType }, ['courierType']),
    // This route only work in single store view
    // For All Store use SettingsRoutePaths.couriers.edit().path
    edit: ({
      courierType,
      courierId = '',
    }: {
      courierType: CrossBorderCourierProvider;
      courierId: string;
    }) =>
      createPath(
        `${root.path}/:courierType/:courierId`,
        {
          courierType,
          courierId,
        },
        ['courierId', 'courierType']
      ),
  };
};
