import React from 'react';
import { RouteObject } from 'react-router';

import { routes as CallbackRoutes } from './modules/callback/routing';
import { routes as ChatRoutes } from './modules/chat/routing';
import { routes as CustomerRoutes } from './modules/customer/routing';
import { routes as InstagramRoutes } from './modules/instagram/routing';
import { routes as LazadaRoutes } from './modules/lazada/routing';
import { routes as LineRoutes } from './modules/line/routing';
import { routes as MessengerRoutes } from './modules/messenger/routing';
import { routes as ShopeeRoutes } from './modules/shopee/routing';
import { routes as TemplateRoutes } from './modules/template/routing';
import { routes as TiktokShopRoutes } from './modules/tiktok-shop/routing';
import { routes as WelcomeRoutes } from './modules/welcome/routing';
import { routes as WhatsappRoutes } from './modules/whatsapp/routing';

export const CrmAppRoot = React.lazy(() =>
  import('./CrmAppRoot').then((module) => ({ default: module.CrmAppRoot }))
);

export const routes: RouteObject[] = [
  ...CallbackRoutes,
  {
    path: 'crm',
    element: <CrmAppRoot />,
    children: [
      ...WelcomeRoutes,
      ...ChatRoutes(),
      ...CustomerRoutes,
      ...TemplateRoutes,
      ...LineRoutes,
      ...InstagramRoutes,
      ...MessengerRoutes,
      ...WhatsappRoutes,
      ...LazadaRoutes,
      ...ShopeeRoutes,
      ...TiktokShopRoutes,
    ],
  },
];
