import { DatasourceSortByField, DatasourceType } from '~anyx/common/graphql';
import { DEFAULT_PAGE_SIZE } from '~anyx/shared/utils';

import { useDatasourcesListQuery } from '../graphql';
import {
  AdvertisementDatasources,
  isAdvertisementDatasource,
  isAnalyticsDatasource,
} from '../utils';

export const useCheckDatasource = ({ masterDataStoreId = '' }: { masterDataStoreId?: string }) => {
  const { data, loading } = useDatasourcesListQuery({
    skip: !masterDataStoreId,
    variables: {
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      sortBy: {
        descending: true,
        field: DatasourceSortByField.CREATED_AT,
      },
      filter: {
        masterDataStoreId,
      },
    },
  });

  const existingDatasources = (data?.allDatasources?.items || []).map((ds) => ds.type);

  const isSelectDisabled = (type: DatasourceType) => {
    // Analytics channel case
    if (isAnalyticsDatasource(type)) {
      // For GoogleAnalyticsUA and GoogleAnalytics4, user can add only one account within GoogleAnalyticsUA and GoogleAnalytics4.
      // (i.e. If user adds one GoogleAnalytics Account, user cannot add another GoogleAnalyticsUA or GoogleAnalytics4 account)
      return existingDatasources.some((ds) => isAnalyticsDatasource(ds));
    }
    // Advertisement channel case
    if (isAdvertisementDatasource(type)) {
      // User can add only one channel for the same type.
      // (i.e. If user adds one GoogleAds account, user cannot add another GoogleAds account)
      return existingDatasources.includes(type);
    }
    return false;
  };

  return {
    isSelectDisabled,
    isAnalyticsButtonDisabled: existingDatasources.some((ds) => isAnalyticsDatasource(ds)),
    isAdvertisementButtonDisabled: AdvertisementDatasources.every((ds) =>
      existingDatasources.includes(ds)
    ),
    loading,
  };
};
