import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MdStoreChannelsQueryVariables = Types.Exact<{
  mdStoreId: Types.Scalars['Long']['input'];
}>;


export type MdStoreChannelsQuery = { MdStore: { __typename?: 'MdStore', channels: Array<{ __typename?: 'SaleChannel', id: string, type: Types.MarketplaceSaleChannelType }> } };


export const MdStoreChannelsDocument = gql`
    query MdStoreChannels($mdStoreId: Long!) {
  MdStore(id: $mdStoreId) {
    channels {
      id
      type
    }
  }
}
    `;

/**
 * __useMdStoreChannelsQuery__
 *
 * To run a query within a React component, call `useMdStoreChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMdStoreChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMdStoreChannelsQuery({
 *   variables: {
 *      mdStoreId: // value for 'mdStoreId'
 *   },
 * });
 */
export function useMdStoreChannelsQuery(baseOptions: Apollo.QueryHookOptions<MdStoreChannelsQuery, MdStoreChannelsQueryVariables> & ({ variables: MdStoreChannelsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MdStoreChannelsQuery, MdStoreChannelsQueryVariables>(MdStoreChannelsDocument, options);
      }
export function useMdStoreChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MdStoreChannelsQuery, MdStoreChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MdStoreChannelsQuery, MdStoreChannelsQueryVariables>(MdStoreChannelsDocument, options);
        }
export function useMdStoreChannelsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MdStoreChannelsQuery, MdStoreChannelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MdStoreChannelsQuery, MdStoreChannelsQueryVariables>(MdStoreChannelsDocument, options);
        }
export type MdStoreChannelsQueryHookResult = ReturnType<typeof useMdStoreChannelsQuery>;
export type MdStoreChannelsLazyQueryHookResult = ReturnType<typeof useMdStoreChannelsLazyQuery>;
export type MdStoreChannelsSuspenseQueryHookResult = ReturnType<typeof useMdStoreChannelsSuspenseQuery>;
export type MdStoreChannelsQueryResult = Apollo.QueryResult<MdStoreChannelsQuery, MdStoreChannelsQueryVariables>;
export function refetchMdStoreChannelsQuery(variables: MdStoreChannelsQueryVariables) {
      return { query: MdStoreChannelsDocument, variables: variables }
    }