import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { ConnectToFacebookRequestV2Input } from '~anyx/common/graphql';

import { CONNECT_TO_FACEBOOK_V2 } from '../../graphql';

import { PlatformType } from './types';
import { useConnectFBToAnyChatErrors } from './useConnectFBToAnyChatErrors';

export const useConnectFBToAnyChat = (platform: PlatformType) => {
  const [connectToFacebook] = useMutation(CONNECT_TO_FACEBOOK_V2);
  const { throwConnectFBToAnyChatError } = useConnectFBToAnyChatErrors(platform);

  const connectFBToAnyChat = async (input: ConnectToFacebookRequestV2Input) => {
    try {
      const { data, errors } = await connectToFacebook({
        variables: {
          input,
        },
      });

      if (!data?.ConnectToFacebookV2) throw new Error('Empty connection id');
      if (errors) throw errors;

      const { ConnectToFacebookV2 } = data;

      const typeName = ConnectToFacebookV2?.__typename;

      if (
        typeName === 'ConnectToFacebookResponseSuccess' ||
        typeName === 'MessengerNotSupportChatPlugInWithAnonymousUserFacebookError'
      ) {
        return;
      }

      throwConnectFBToAnyChatError({ typeName });
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  };

  return {
    connectFBToAnyChat,
  };
};
