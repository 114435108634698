import { z } from 'zod';

import {
  ReportChartTab,
  ReportDimensions,
  ReportOrderStatus,
  ReportTab,
  SaleChannelReportTableFilterInput,
  SaleChannelType,
} from '~anyx/common/graphql';
import { AdsMetricsType } from '~anyx/common/graphql';
import { HomeMetricOption } from '~anyx/feature/report';
import { DateUtils } from '~anyx/shared/utils';

type Constraints = SaleChannelReportTableFilterInput & {
  metric: HomeMetricOption;
};

export const HomePageFiltersParser = z.object({
  endDate: z.string().catch(DateUtils.toISOString(new Date(), { endOf: 'day' })),
  startDate: z.string().catch(DateUtils.toISOString(new Date(), { startOf: 'day' })),
  dimensions: z.nativeEnum(ReportDimensions).catch(() => ReportDimensions.DAY),
  marketplaces: z.array(z.nativeEnum(SaleChannelType)).catch([]),
  masterDataAccountIds: z.array(z.string()).nullish().catch([]),
  masterDataStoreIds: z.array(z.string()).catch([]),
  orderStatus: z.nativeEnum(ReportOrderStatus).catch(ReportOrderStatus.ORDERED),
  tab: z.nativeEnum(ReportTab).catch(ReportTab.ALL),
  metric: z.nativeEnum(HomeMetricOption).catch(HomeMetricOption.GROSS_SALES),
  adsMetricsType: z.nativeEnum(AdsMetricsType).optional().catch(AdsMetricsType.GROSS),
  chartTab: z.nativeEnum(ReportChartTab).catch(ReportChartTab.CHANNEL),
}) satisfies z.ZodType<Constraints, z.ZodTypeDef, unknown>;

export type HomeFilterInputFilterType = z.infer<typeof HomePageFiltersParser>;
