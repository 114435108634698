import React from 'react';
import { RouteObject } from 'react-router';

const OptInPage = React.lazy(() => import('./pages'));
const OptInConnectMessenger = React.lazy(() => import('./templates'));

export const routes: RouteObject[] = [
  {
    path: 'opt-in',
    element: <OptInConnectMessenger />,
    children: [
      {
        index: true,
        element: <OptInPage />,
      },
    ],
  },
];
