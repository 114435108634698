import * as Types from '~anyx/common/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TikTokSalesReportDetailedReportQueryVariables = Types.Exact<{
  input: Types.TikTokSalesReportDetailedReportInput;
}>;


export type TikTokSalesReportDetailedReportQuery = { readonly tikTokSalesReportDetailedReport: { readonly __typename?: 'TikTokSalesReportDetailedReportPayloadList', readonly items: ReadonlyArray<{ readonly __typename?: 'TikTokSalesReportDetailedReportPayload', readonly currency: Types.ReportSupportedCurrency, readonly date: any, readonly itemsOrdered: string, readonly sales: string, readonly unitsOrdered: string }>, readonly meta: { readonly __typename?: 'ReportListMeta', readonly length: number, readonly limit: number, readonly start: number, readonly total: number }, readonly total: { readonly __typename?: 'TikTokSalesReportDetailedReportTotalPayload', readonly currency: Types.ReportSupportedCurrency, readonly itemsOrdered: string, readonly sales: string, readonly unitsOrdered: string } } };


export const TikTokSalesReportDetailedReportDocument = gql`
    query TikTokSalesReportDetailedReport($input: TikTokSalesReportDetailedReportInput!) {
  tikTokSalesReportDetailedReport(input: $input) {
    items {
      currency
      date
      itemsOrdered
      sales
      unitsOrdered
    }
    meta {
      length
      limit
      start
      total
    }
    total {
      currency
      itemsOrdered
      sales
      unitsOrdered
    }
  }
}
    `;

/**
 * __useTikTokSalesReportDetailedReportQuery__
 *
 * To run a query within a React component, call `useTikTokSalesReportDetailedReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTikTokSalesReportDetailedReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTikTokSalesReportDetailedReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTikTokSalesReportDetailedReportQuery(baseOptions: Apollo.QueryHookOptions<TikTokSalesReportDetailedReportQuery, TikTokSalesReportDetailedReportQueryVariables> & ({ variables: TikTokSalesReportDetailedReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TikTokSalesReportDetailedReportQuery, TikTokSalesReportDetailedReportQueryVariables>(TikTokSalesReportDetailedReportDocument, options);
      }
export function useTikTokSalesReportDetailedReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TikTokSalesReportDetailedReportQuery, TikTokSalesReportDetailedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TikTokSalesReportDetailedReportQuery, TikTokSalesReportDetailedReportQueryVariables>(TikTokSalesReportDetailedReportDocument, options);
        }
export function useTikTokSalesReportDetailedReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TikTokSalesReportDetailedReportQuery, TikTokSalesReportDetailedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TikTokSalesReportDetailedReportQuery, TikTokSalesReportDetailedReportQueryVariables>(TikTokSalesReportDetailedReportDocument, options);
        }
export type TikTokSalesReportDetailedReportQueryHookResult = ReturnType<typeof useTikTokSalesReportDetailedReportQuery>;
export type TikTokSalesReportDetailedReportLazyQueryHookResult = ReturnType<typeof useTikTokSalesReportDetailedReportLazyQuery>;
export type TikTokSalesReportDetailedReportSuspenseQueryHookResult = ReturnType<typeof useTikTokSalesReportDetailedReportSuspenseQuery>;
export type TikTokSalesReportDetailedReportQueryResult = Apollo.QueryResult<TikTokSalesReportDetailedReportQuery, TikTokSalesReportDetailedReportQueryVariables>;
export function refetchTikTokSalesReportDetailedReportQuery(variables: TikTokSalesReportDetailedReportQueryVariables) {
      return { query: TikTokSalesReportDetailedReportDocument, variables: variables }
    }