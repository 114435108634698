import React from 'react';
import { RouteObject } from 'react-router';

const TemplateListPage = React.lazy(() => import('./pages/list'));

export const routes: RouteObject[] = [
  {
    path: 'template',
    element: <TemplateListPage />,
  },
];
