import { gql } from '~anyx/common/graphql';

export const CONNECT_TO_FACEBOOK_V2 = gql(/* GraphQL */ `
  mutation ConnectToFacebookV2($input: ConnectToFacebookRequestV2Input!) {
    ConnectToFacebookV2(input: $input) {
      ... on AccountAlreadyHaveConnection {
        message
      }
      ... on ConnectToFacebookResponseSuccess {
        connectionId
      }
      ... on MessengerNotSupportChatPlugInWithAnonymousUserFacebookError {
        message
      }
      ... on NoInstagramAccountConnectToFacebookError {
        message
      }
      ... on PageHaveConnectedToOtherStore {
        message
        storeId
      }
      ... on UnknownErrorConnectToFacebookError {
        message
      }
    }
  }
`);

export const REFRESH_FACEBOOK_TOKEN_CONNECTION = gql(/* GraphQL */ `
  mutation RefreshFacebookTokenConnection($input: ConnectToFacebookRequestV2Input!) {
    RefreshFacebookTokenConnection(input: $input) {
      ... on NoInstagramAccountRefreshTokenError {
        message
      }
      ... on RefreshFacebookTokenResponseSuccess {
        connectionId
      }
      ... on UnknownErrorRefreshTokenError {
        message
      }
      ... on WrongPageSelectedRefreshTokenError {
        message
        pageId
      }
    }
  }
`);
