import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted, Auth0Permissions } from '~anyx/common/permission';
import { OrderRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const OrderTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.OrderTemplate }))
);
const OrderListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.OrderListPage }))
);
const OrderAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.OrderAddPage }))
);
const OrderEditPage = React.lazy(() =>
  import('./pages/edit').then((module) => ({ default: module.OrderEditPage }))
);

export const routes: RouteObject[] = [
  {
    path: 'order',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_ORDER]]}
          fallback={<UnauthorizedMessage />}
        >
          <OrderTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <OrderListPage />,
      },
      {
        path: 'add',
        element: <OrderAddPage />,
      },
      {
        path: ':orderId',
        element: <OrderEditPage />,
      },
      {
        index: true,
        element: <Navigate to={OrderRoutePath({ workspaceStoreId: null }).list().path} replace />,
      },
    ],
  },
];
